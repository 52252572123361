import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ApplicationService} from '../../service/application/application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Account} from '../../spurado';
import {CommonService} from '../../service/common/common.service';
import {AccountService} from '../../service/account/account.service';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-router',
    templateUrl: './router.component.html',
    styleUrls: ['./router.component.scss']
})
export class RouterComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;
    loggedAccount: Observable<Account>;

    @HostBinding('class') componentCssClass;

    constructor(public applicationService: ApplicationService,
                private commonService: CommonService,
                private configurationService: ConfigurationService,
                private accountService: AccountService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        this.unsubscribeSubject = new Subject<void>();
        this.loggedAccount = applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data;
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(paramMap => {
            if (paramMap.has('orgUuid')) {
                const orgUuid = paramMap.get('orgUuid');
                this.loggedAccount
                .pipe(takeUntil(this.unsubscribeSubject))
                .subscribe((account: Account) => {
                    if (account) {
                        if (account.isAdmin) {
                            this.applicationService.applicationStore.dispatch({
                                type: StoreActionType.PENDING_TASKS_COUNT,
                                argument: orgUuid
                            });
                        }
                        if (account.organisation.configuration && account.organisation.configuration.enableApprob.value) {
                            this.applicationService.applicationStore.dispatch({
                                type: StoreActionType.PENDING_APPROVALS_COUNT,
                                argument: orgUuid
                            });
                        }
                    }
                });
            } else {
                this.router.navigate(['/errors/oops']);
            }
        });
    }

    ngOnDestroy(): void {
        // Resetting the organisation state when destroying the router component
        this.applicationService.applicationStore.dispatchReset([
            {type: StoreActionType.ACCOUNT},
            {type: StoreActionType.PENDING_APPROVALS_COUNT},
            {type: StoreActionType.PENDING_TASKS_COUNT},
            {type: StoreActionType.THEME}
        ]);
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
