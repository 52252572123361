<div class="oops-container">

    <div>
        <img src="assets/images/laptop.svg" alt="Oops">
    </div>

    <div class="oops-content">
        <h1>{{'oops.title' | translate}}</h1>
        <p>{{'oops.not_your_fault' | translate}}</p>
        <p>{{'oops.resource_not_found' | translate}}</p>
        <button mat-flat-button color="primary" (click)="home()">
            <mat-icon style="margin-right: 12px;">home</mat-icon>
            {{'common.actions.back_to_home' | translate}}
        </button>
    </div>

</div>
