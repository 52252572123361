import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReportingService} from '../../service/reporting/reporting.service';
import {Subject} from 'rxjs';
import {Account} from '../../spurado';
import {takeUntil} from 'rxjs/operators';
import {AccountService} from '../../service/account/account.service';
import {FormControl, FormGroup} from '@angular/forms';

// eslint-disable-next-line no-duplicate-imports
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import {Router} from '@angular/router';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';
import {CheckboxElement} from '../../spurado-extended';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {DownloadDialogComponent} from '../download-dialog/download-dialog.component';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'spurado-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
    private subject: Subject<void>;
    loggedAccount: Account;

    reportTypes: string[];
    filterByDepartment: boolean = false;
    departments: CheckboxElement[] = [];
    reportPeriods: string[] = ['DAILY', 'WEEKLY', 'WEEK_MONTHLY', 'MONTHLY'];
    dateFormGroup: FormGroup;
    reportStatuses: string[] = ['OPEN', 'PENDING_APPROVAL', 'VALIDATED'];
    reportColumns: string[] = ['TASK', 'USER', 'STATUS', 'LINE'];

    selectedReportType: string;
    selectedReportPeriod: string;
    selectedReportFrom: Date;
    selectedReportTo: Date;
    selectedReportStatuses: string[];
    selectedReportColumns: string[];

    datesAreOK: boolean;

    //@formatter:off
    constructor(private applicationService: ApplicationService,
                private accountService: AccountService,
                private datePipe: DatePipe,
                private matDialog: MatDialog,
                private reportingService: ReportingService,
                private router: Router) {
        //@formatter:on
        this.subject = new Subject<void>();
        this.dateFormGroup = new FormGroup({
            dateFrom: new FormControl<Date>(moment().subtract(1, 'months').toDate()),
            dateTo: new FormControl<Date>(moment().toDate())
        });

        this.checkIfDatesAreOk();
    }

    ngOnInit() {
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.subject))
        .subscribe(account => {
            if (!account.organisation.configuration.enableReports.value) {
                this.router.navigate(['/errors/oops']);
            }
            this.loggedAccount = account;
            this.reportingService.getMyReportTypes(this.loggedAccount.organisation.uuid).subscribe(types => {
                this.reportTypes = types.sort((a, b) => a.toString().localeCompare(b.toString()));
                this.selectedReportType = this.reportTypes[0];
                this.selectedReportPeriod = this.reportPeriods[0];
                this.selectedReportStatuses = this.reportStatuses;
                this.selectedReportColumns = this.reportColumns;
            });
            if (this.loggedAccount.organisation.uuid === 'icity') {
                this.reportingService.getDepartments(this.loggedAccount.organisation.uuid).subscribe(departments => {
                    if (departments) {
                        for (let dept of departments) {
                            let deptCheckbox: CheckboxElement = {
                                uuid: dept.name,
                                value: dept.name,
                                completed: true,
                                subElements: []
                            };
                            for (let division of dept.divisions) {
                                deptCheckbox.subElements.push({
                                    uuid: division,
                                    value: division,
                                    completed: true
                                });
                            }
                            this.departments.push(deptCheckbox);
                        }
                    }
                });
            }
        });
    }

    checkIfDatesAreOk() {
        this.selectedReportFrom = moment(this.dateFormGroup.value.dateFrom).toDate();
        this.selectedReportTo = moment(this.dateFormGroup.value.dateTo).toDate();
        this.datesAreOK = this.selectedReportFrom.getTime() <= this.selectedReportTo.getTime();
    }

    updateStatus(attr: string, event) {
        if (event.checked) {
            this.selectedReportStatuses.push(attr);
        } else {
            this.selectedReportStatuses = this.selectedReportStatuses.filter(value => {
                return value !== attr;
            });
        }
    }

    updateColumn(attr: string, event) {
        if (event.checked) {
            this.selectedReportColumns.push(attr);
        } else {
            this.selectedReportColumns = this.selectedReportColumns.filter(value => {
                return value !== attr;
            });
        }
    }

    generateReport() {
        this.selectedReportFrom = this.dateFormGroup.value.dateFrom;
        this.selectedReportTo = this.dateFormGroup.value.dateTo;
        let selectedDepartments: string[];
        let selectedDivision: string[];
        if (this.canFilterByDepartment && this.filterByDepartment) {
            selectedDepartments = [];
            selectedDivision = [];
            for (let dept of this.departments) {
                const departmentHasDivisionsAndAllChecked = dept.subElements && dept.subElements.length > 0 && this.allDivisionCompleteForDepartment(dept.uuid);
                const departmentHasNoDivisionButIsChecked = (!dept.subElements || dept.subElements.length === 0) && dept.completed;
                if (departmentHasDivisionsAndAllChecked || departmentHasNoDivisionButIsChecked) {
                    selectedDepartments.push(dept.value);
                }
                if (this.someDivisionCompleteForDepartment(dept.uuid)) {
                    for (let div of dept.subElements) {
                        if (div.completed) {
                            selectedDivision.push(div.value);
                        }
                    }
                }
            }
        }
        const url = this.reportingService.buildReportURL(this.loggedAccount.organisation.uuid,
            this.selectedReportType, this.selectedReportPeriod, this.selectedReportFrom, this.selectedReportTo,
            this.selectedReportStatuses, this.selectedReportColumns, selectedDepartments, selectedDivision);

        const filename = `spurado-report-${this.datePipe.transform(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`;

        this.matDialog.open<DownloadDialogComponent>(DownloadDialogComponent, {
            data: {url: url, filename: filename},
            disableClose: true
        });
    }

    get departmentSelection(): CheckboxElement[] {
        for (let department of this.departments) {
            department.subElements.sort((a, b) => {
                return a.uuid.localeCompare(b.uuid);
            });
        }
        return Array.from(this.departments).sort(
            (a, b) => {
                return a.uuid.localeCompare(b.uuid);
            }
        );
    }

    allDivisionCompleteForDepartment(departmentId: string): boolean {
        const department = this.departments.find(dpt => dpt.uuid === departmentId);
        if (!department || department.subElements == null) {
            return false;
        }
        return department.subElements.every(e => e.completed);
    }

    someDivisionCompleteForDepartment(departmentId: string): boolean {
        const department = this.departments.find(dpt => dpt.uuid === departmentId);
        if (!department || department.subElements == null) {
            return false;
        }
        return department.subElements.filter(e => e.completed).length > 0 && !this.allDivisionCompleteForDepartment(departmentId);
    }

    setAllDivisionForDepartment(completed: boolean, departmentId: string) {
        const department = this.departments.find(dpt => dpt.uuid === departmentId);
        department.completed = completed;
        if (!department || department.subElements == null) {
            return;
        }
        department.subElements.forEach(weekSelection => {
            weekSelection.completed = completed;
        });
    }

    get canFilterByDepartment(): boolean {
        return this.selectedReportType !== 'USER' && this.isICITY;
    }

    get isICITY(): boolean {
        return this.loggedAccount.organisation.uuid === 'icity';
    }

    ngOnDestroy() {
        this.subject.next();
        this.subject.unsubscribe();
    }
}
