import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Approval, ApprovalStatusUpdate, LineStatus, LineStatusUpdate, SearchApprovals} from '../../spurado';
import {DatePipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ApprovalService {

    private apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient, private datePipe: DatePipe) {
    }

    getNumberOfApprovals(orgUuid: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + `${orgUuid}/approvals/count`);
    }

    getMyPendingApprovals(orgUuid: string): Observable<Array<Approval>> {
        return this.httpClient.get<Array<Approval>>(this.apiUrl + `${orgUuid}/approvals/mine`);
    }

    searchPendingApprovals(orgUuid: string, searchApprovals?: SearchApprovals): Observable<Array<Approval>> {
        return this.httpClient.post<Array<Approval>>(this.apiUrl + `${orgUuid}/approvals/search`, searchApprovals);
    }

    approveOrRejectLine(orgUuid: string, timesheetUuid: string, lineSequence: string, lineStatusUpdate: LineStatusUpdate): Observable<any> {
        let approvals: Approval[] = [];
        let approval = new Approval();
        approval.timesheetUuid = timesheetUuid;
        approval.timesheetLineSequence = lineSequence;
        approval.timesheetLineVersion = lineStatusUpdate.version;
        approvals.push(approval);
        return this.updateStatusApproval(orgUuid, lineStatusUpdate.status, approvals, lineStatusUpdate.comment);
    }

    updateStatusApproval(orgUuid: string, newStatus: LineStatus, approvals: Approval[], refuseComment?: string): Observable<any> {
        let body = new ApprovalStatusUpdate();
        body.newStatus = newStatus;
        body.approvals = approvals;
        body.refuseComment = refuseComment;
        return this.httpClient.put(this.apiUrl + `${orgUuid}/approvals/status`, body);
    }
}
