import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {authConfig} from './oidc/auth.config';
import {CommonService} from './service/common/common.service';
import {BreakpointService} from './service/breakpoint/breakpoint.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from './service/snackbar/snackbar.service';
import {Criticity} from './spurado-extended';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Account, ApplicationDetails, Theme, User} from './spurado';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {ApplicationService} from './service/application/application.service';
import {takeUntil} from 'rxjs/operators';
import {StoreActionType} from './app.store';

@Component({
    selector: 'spurado-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;

    conditionsAndCookiesAccepted = false;
    applicationDetails: Observable<ApplicationDetails>;
    loggedUser: Observable<User>;
    loggedAccount: Observable<Account>;
    currentTheme: string;

    @HostBinding('class') componentCssClass;

    constructor(public oauthService: OAuthService,
                public applicationService: ApplicationService,
                public commonService: CommonService,
                public breakpointService: BreakpointService,
                public translate: TranslateService,
                private activatedRoute: ActivatedRoute,
                private snackbarService: SnackbarService,
                private overlayContainer: OverlayContainer,
                private router: Router,
    ) {
        this.unsubscribeSubject = new Subject<void>();
        translate.addLangs(['fr', 'nl']);
        translate.setDefaultLang('fr');

        this.applicationDetails = applicationService.applicationStore.listen<ApplicationDetails>(StoreActionType.APPLICATION_DETAILS).data;
        this.loggedUser = applicationService.applicationStore.listen<User>(StoreActionType.USER).data;
        this.loggedAccount = applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data;
    }

    ngOnInit() {
        this.applicationService.applicationStore.listen<Theme>(StoreActionType.THEME).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((theme: Theme) => {
            this.setTheme(theme.toLowerCase() + '-theme');
        });

        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((account: Account) => {
            if (account && account.organisation) {
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.THEME,
                    argument: account.organisation.configuration.selectedTheme.value
                });
            }
        });

        const lastAccepted = window.localStorage.getItem('conditionsCookiesConsentDate');
        if (lastAccepted) {
            this.conditionsAndCookiesAccepted = true;
        }
        if (this.conditionsAndCookiesAccepted) {
            this.configure();
        }


        this.oauthService.events
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
                this.snackbarService.openSnackBarWithAction('common.error.session', Criticity.ERROR, 'common.actions.reconnect', () => this.oauthService.logOut());
            }
        });
    }

    closeCookieConsentPanel() {
        this.conditionsAndCookiesAccepted = true;
        window.localStorage.setItem('conditionsCookiesConsentDate', Date.now().toString());
        this.configure();
    }

    private configure() {
        this.oauthService.setStorage(sessionStorage);
        this.oauthService.configure(authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService
        .loadDiscoveryDocumentAndLogin({
            state: location.pathname
        })
        .then(() => {
            this.applicationService.applicationStore.dispatch({
                type: StoreActionType.USER,
            });

            const redirectUrl = decodeURIComponent(this.oauthService.state);
            if (redirectUrl?.replace('/', '').trim()) {
                this.router.navigateByUrl(redirectUrl);
            } else {
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.APPLICATION_DETAILS,
                });
            }
        });
    }

    private setTheme(theme) {
        const container = this.overlayContainer.getContainerElement();
        const classList = container.classList;
        if (classList && classList.replace) {
            if (classList.contains(this.currentTheme)) {
                classList.replace(this.currentTheme, theme);
            } else {
                classList.add(theme);
            }
        } else {
            //IE9 and IE9+ with no support of classList.replace
            const classes = container.className.split(' ');
            const i = classes.indexOf(this.currentTheme);
            if (i >= 0) {
                classes.splice(i, 1);
            }
            classes.push(theme);
            container.className = classes.join(' ');
        }

        this.componentCssClass = theme;
        this.currentTheme = theme;
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
