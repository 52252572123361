import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {Account, AccountRef, Client, Program, Project,} from '../../../spurado';
import {FormControl, Validators} from '@angular/forms';
import {ApplicationService} from '../../../service/application/application.service';
import {AccountService} from '../../../service/account/account.service';
import {StoreActionType} from '../../../app.store';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {FilterElement, FilterType, SaveFilters} from '../../../spurado-extended';
import {ClientService} from '../../../service/client/client.service';
import {ProgramService} from '../../../service/program/program.service';
import {TaskService} from '../../../service/task/task.service';

@Component({
    selector: 'spurado-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {

    @Input() elementCardSelect: FilterElement[];
    @Input() generatedFilter: FilterElement;
    @Output()
    elementSelect = new EventEmitter<FilterElement[]>();

    private unsubscribeSubject: Subject<void>;
    private readonly desiredTimeInMinutes = 20;

    title: string;

    filterType: typeof FilterType = FilterType;

    searchType: FilterType;

    // collection
    accounts: AccountRef[] = [];
    tasks: Project[] = [];
    customers: Client[] = [];
    programs: Program[] = [];
    filterElements: FilterElement[] = [];

    // search and selected
    searchFormControl: FormControl;
    typeFormControl: FormControl;
    dateFrom: FormControl;
    dateTo: FormControl;
    loggedAccount: Account;

    constructor(private applicationService: ApplicationService,
                private accountService: AccountService,
                private clientService: ClientService,
                private programService: ProgramService,
                private taskService: TaskService) {

        this.unsubscribeSubject = new Subject<void>();
        this.searchFormControl = new FormControl({
            value: '',
            disabled: this.searchType === undefined
        }, [Validators.required, Validators.minLength(3)]);
        this.dateFrom = new FormControl(Date, Validators.required);
        this.dateTo = new FormControl(Date, Validators.required);
        this.typeFormControl = new FormControl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.generatedFilter) {
            this.addOrNotInList(this.generatedFilter);
        }
    }

    ngOnInit(): void {
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
                const savedFilter = JSON.parse(localStorage.getItem('lastFilter_' + this.loggedAccount.user.uuid)) as SaveFilters;
                if (
                    savedFilter &&
                    savedFilter.accountUuid === this.loggedAccount.uuid &&
                    savedFilter.orgUuid === this.loggedAccount.organisation.uuid
                ) {
                    if ((new Date()).getTime() < savedFilter.expiryAt) {
                        this.filterElements = savedFilter.filterElements;
                    } else {
                        localStorage.removeItem('lastFilter_' + this.loggedAccount.user.uuid);
                    }
                }
                this.elementSelect.emit(this.filterElements);
            }
        });

        this.searchFormControl.valueChanges
        .pipe(debounceTime(1000))
        .subscribe(() => {
            if (this.searchFormControl.valid) {
                this.getDataAccordingToFilter();
            }
        });
    }

    buildElement(name?: string, uuid?: string, dateFrom?: Date, dateTo?: Date) {
        const element = new FilterElement();
        element.type = this.searchType;
        if (name && uuid) {
            element.name = name;
            element.uuid = uuid;
        }

        if (dateFrom != null && dateTo != null) {
            element.from = dateFrom;
            element.to = dateTo;
            this.searchType = undefined;
            this.title = '';
            this.typeFormControl.reset();
            this.searchFormControl.disable();
        }

        this.addOrNotInList(element);
        this.searchFormControl.reset();
    }

    private getDataAccordingToFilter() {
        switch (this.searchType) {
            case FilterType.ACCOUNT:
                this.accountService.getAllAccounts(this.loggedAccount.organisation.uuid, undefined, undefined, this.searchFormControl.value)
                .subscribe(accounts => this.accounts = this.filterList(accounts, FilterType.ACCOUNT));
                break;

            case FilterType.TASK:
                this.taskService.getTasksForTimesheet(this.loggedAccount.organisation.uuid, null, true, this.searchFormControl.value)
                .subscribe(tasks => this.tasks = this.filterList(tasks, FilterType.TASK), () => this.tasks = []);
                break;

            case FilterType.CUSTOMER:
                this.clientService.getAllClients(this.loggedAccount.organisation.uuid, this.searchFormControl.value)
                .subscribe(customers => this.customers = this.filterList(customers, FilterType.CUSTOMER), () => this.customers = []);
                break;

            case FilterType.PROGRAM:
                this.programService.getAllPrograms(this.loggedAccount.organisation.uuid, this.searchFormControl.value)
                .subscribe((programs) => this.programs = this.filterList(programs, FilterType.PROGRAM), () => this.programs = []);
                break;
            default:
                this.accounts = [];
                this.customers = [];
                this.tasks = [];
                this.programs = [];
        }
    }

    private filterList(list: Array<any>, filterType: FilterType): Array<any> {
        const alreadySelected = this.filterElements.filter(f => f.type === filterType).map(f => f.uuid);
        return list.filter(l => alreadySelected.indexOf(l.uuid) === -1);
    }

    namesType(): Array<string> {
        const alreadyDateSelected = this.filterElements.filter(f => f.type === FilterType.DATE).map(f => f.type);
        return Object.values(FilterType).filter(o => alreadyDateSelected.indexOf(o) === -1);
    }

    getSearchType(type: string) {
        this.searchType = undefined;

        if (FilterType.TASK.includes(type)) {
            this.searchType = FilterType.TASK;
        }
        if (FilterType.ACCOUNT.includes(type)) {
            this.searchType = FilterType.ACCOUNT;
        }
        if (FilterType.CUSTOMER.includes(type)) {
            this.searchType = FilterType.CUSTOMER;
        }
        if (FilterType.PROGRAM.includes(type)) {
            this.searchType = FilterType.PROGRAM;
        }
        if (FilterType.DATE.includes(type)) {
            this.searchType = FilterType.DATE;
        }

        this.creatTitle(this.searchType);
        this.searchFormControl.enable();
        this.searchFormControl.reset();
    }

    private creatTitle(type: FilterType) {
        switch (type) {
            case FilterType.ACCOUNT:
                this.title = 'approval_filter.title.user';
                break;
            case FilterType.TASK:
                this.title = 'approval_filter.title.task';
                break;
            case FilterType.CUSTOMER:
                this.title = 'approval_filter.title.customer';
                break;
            case FilterType.PROGRAM:
                this.title = 'approval_filter.title.program';
                break;
            case FilterType.DATE:
                this.title = 'approval_filter.title.date';
        }
    }

    private addOrNotInList(element: FilterElement) {
        let existed;
        if (element.name != null) {
            existed = this.filterElements.filter(sa => sa.uuid === element.uuid);
            if (existed.length === 0) {
                this.filterElements.splice(0, 0, element);
                localStorage.setItem('lastFilter_' + this.loggedAccount.user.uuid, JSON.stringify(this.createSaveFilter(this.desiredTimeInMinutes)));
                this.elementSelect.emit(this.filterElements);
            }
        } else {
            this.filterElements.forEach(item => {
                if (item.type === element.type) {
                    item.from = element.from;
                    item.to = element.to;
                }
            });
            this.filterElements.splice(0, 0, element);
            localStorage.setItem('lastFilter_' + this.loggedAccount.user.uuid, JSON.stringify(this.createSaveFilter(this.desiredTimeInMinutes)));
            this.elementSelect.emit(this.filterElements);
        }


        this.accounts = [];
        this.customers = [];
        this.tasks = [];
        this.programs = [];
    }

    deleteElementInList(elementToDelete: FilterElement) {
        if (elementToDelete.from !== undefined && elementToDelete.to !== undefined) {
            this.dateFrom.reset();
            this.dateTo.reset();
        }
        this.filterElements.splice(
            this.filterElements.indexOf(
                this.filterElements.filter(
                    sa => sa.uuid === elementToDelete.uuid
                )[0]
            ), 1
        );

        localStorage.setItem('lastFilter_' + this.loggedAccount.user.uuid, JSON.stringify(this.createSaveFilter(this.desiredTimeInMinutes)));
        this.elementSelect.emit(this.filterElements);
    }

    resetAllFilter() {
        this.filterElements = [];
        this.searchType = undefined;
        this.typeFormControl.reset();
        this.searchFormControl.reset();
        this.searchFormControl.disable();
        this.title = '';
        localStorage.removeItem('lastFilter_' + this.loggedAccount.user.uuid);
        this.elementSelect.emit(this.filterElements);
    }

    private createSaveFilter(minute: number): SaveFilters {
        const saveFilters = new SaveFilters(minute);
        saveFilters.orgUuid = this.loggedAccount.organisation.uuid;
        saveFilters.accountUuid = this.loggedAccount.uuid;
        saveFilters.filterElements = this.filterElements;
        return saveFilters;
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
