<mat-label>{{'timesheet.validation.no_empty_comment_on_line.title' | translate}}</mat-label>
<div class="timesheet-validation-check">
    <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
    <span *ngIf="!loading && (!timesheetValidation.emptyCommentIssues || timesheetValidation.emptyCommentIssues.length === 0)">
            <ul>
                <li><mat-icon color="primary">verified</mat-icon><span class="timesheet-validation-line">{{'timesheet.validation.conform' | translate}}</span></li>
            </ul>
        </span>
    <span *ngIf="!loading && timesheetValidation.emptyCommentIssues && timesheetValidation.emptyCommentIssues.length > 0">
            <ul *ngFor="let issue of timesheetValidation.emptyCommentIssues">
                <li><mat-icon color="warn">report</mat-icon><span class="timesheet-validation-line">{{'timesheet.validation.no_empty_comment_on_line.message' | translate:{taskName: issue.taskName} }}</span></li>
            </ul>
        </span>
</div>

<mat-label>{{'timesheet.validation.daily_hours.title' | translate}}</mat-label>
<div class="timesheet-validation-check">
    <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
    <span *ngIf="!loading && (!timesheetValidation.hoursInDayIssues || timesheetValidation.hoursInDayIssues.length === 0)">
            <ul>
                <li><mat-icon color="primary">verified</mat-icon><span class="timesheet-validation-line">{{'timesheet.validation.conform' | translate}}</span></li>
            </ul>
        </span>
    <span *ngIf="!loading && timesheetValidation.hoursInDayIssues && timesheetValidation.hoursInDayIssues.length > 0">
            <ul *ngFor="let issue of timesheetValidation.hoursInDayIssues">
                <li><mat-icon color="warn">report</mat-icon>
                    <span class="timesheet-validation-line"
                          [innerHTML]="'timesheet.validation.daily_hours.message' | translate: {date: (issue.date | date: 'dd/MM/yyyy'), actual: issue.actual, expected: issue.expected}"></span></li>
            </ul>
        </span>
</div>

<mat-label>{{'timesheet.validation.weekly_hours.title' | translate}}</mat-label>
<div class="timesheet-validation-check">
    <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
    <span *ngIf="!loading && !timesheetValidation.hoursInWeekIssues">
            <ul>
                <li><mat-icon color="primary">verified</mat-icon><span class="timesheet-validation-line">{{'timesheet.validation.conform' | translate}}</span></li>
            </ul>
        </span>
    <span *ngIf="!loading && timesheetValidation.hoursInWeekIssues">
            <ul>
                <li><mat-icon color="warn">report</mat-icon>
                    <span class="timesheet-validation-line"
                          [innerHTML]="'timesheet.validation.weekly_hours.message' | translate: {actual: timesheetValidation.hoursInWeekIssues.actual, expected: timesheetValidation.hoursInWeekIssues.expected}"></span></li>
            </ul>
        </span>
</div>
