import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'spurado-flip-smartphone',
    templateUrl: './flip-smartphone.component.html',
    styleUrls: ['./flip-smartphone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlipSmartphoneComponent {
}
