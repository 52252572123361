import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Account, AccountRef, SystemGroup, UserGroup} from '../../../../../spurado';
import {FormBuilder, FormControl, NgForm, Validators} from '@angular/forms';
import {UserGroupService} from '../../../../../service/user-group/user-group.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SnackbarService} from '../../../../../service/snackbar/snackbar.service';
import {AccountService} from '../../../../../service/account/account.service';

@Component({
    selector: 'spurado-group-dialog',
    templateUrl: './group-dialog.component.html',
    styleUrls: ['./group-dialog.component.scss']
})
export class GroupDialogComponent implements OnInit {

    readonly: Boolean = false;

    filterFormControl = new FormControl('');
    filteredAccounts: Observable<{ account: AccountRef, member: boolean }[]>;
    accountsMemberOfGroup: AccountRef[];    // TODO-SHOULD : Remove if not used
    accountsNotMemberOfGroup: AccountRef[]; // TODO-SHOULD : Remove if not used
    allAccounts: { account: AccountRef, member: boolean }[];

    @ViewChild('groupForm', {static: true}) userForm: NgForm;
    groupName: FormControl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { userGroup?: UserGroup, loggedAccount: Account },
                public userGroupService: UserGroupService,
                public accountService: AccountService,
                public dialogRef: MatDialogRef<GroupDialogComponent>,
                private formBuilder: FormBuilder,
                private snackbarService: SnackbarService) {
        this.allAccounts = [];
        this.groupName = new FormControl<string>('', [Validators.required, Validators.maxLength(255)]);
    }

    ngOnInit(): void {
        this.readonly = this.data.userGroup && !this.data.userGroup.editable;
        this.accountService.getAllAccounts(this.data.loggedAccount.organisation.uuid, this.data.userGroup?.uuid, false).subscribe(
            accounts => {
                this.accountsNotMemberOfGroup = accounts;
                this.allAccounts = this.allAccounts.concat(accounts.map(a => ({
                    account: a,
                    member: false
                })));
                this.accountService.getAllAccounts(this.data.loggedAccount.organisation.uuid, this.data.userGroup?.uuid, true).subscribe(
                    a => {
                        this.accountsMemberOfGroup = a;
                        this.allAccounts = a.map(acc => ({
                            account: acc,
                            member: true
                        })).concat(this.allAccounts);
                        this.filteredAccounts = this.filterFormControl.valueChanges.pipe(startWith(''), map((input: string | null) => this.filter(input)));
                    },
                    error => this.snackbarService.openErrorSnackBar(error)
                );
            },
            error => this.snackbarService.openErrorSnackBar(error)
        );
    }

    onNoClick() {
        this.dialogRef.close();
    }

    createGroup() {
        this.dialogRef.close(this.groupName.value);
    }

    isAccountRemovalForbidden(account: Account): boolean {
        if (this.data.loggedAccount.user.email && this.data.userGroup.name && SystemGroup.SUPER_ADMIN === this.data.userGroup.name) {
            return this.data.loggedAccount.user.email === account.user.email;
        }
        return false;
    }

    updateMembership(accountMembership: AccountMembership, isMember: boolean) {
        isMember ? this.addMember(accountMembership) : this.removeMember(accountMembership);
    }

    addMember(accountMembership: AccountMembership) {
        this.userGroupService.addMemberToGroup(this.data.loggedAccount.organisation.uuid, this.data.userGroup, accountMembership.account).subscribe(() => {
                this.filterFormControl.setValue('');
                accountMembership.member = true;
            },
            error => this.snackbarService.openErrorSnackBar(error)
        );
    }

    removeMember(accountMembership: AccountMembership) {
        this.userGroupService.removeMemberFromGroup(this.data.loggedAccount.organisation.uuid, this.data.userGroup, accountMembership.account).subscribe(() => {
                this.filterFormControl.setValue('');
                accountMembership.member = false;
            },
            error => this.snackbarService.openErrorSnackBar(error)
        );
    }

    private filter(value: string | null): { account: AccountRef, member: boolean }[] {
        return value !== '' ? this.allAccounts.filter(element => {
            return element.account.user.email.toLowerCase().includes(value.toLowerCase())
                || element.account.user.displayName.toLowerCase().includes(value.toLowerCase());
        }) : this.allAccounts;
    }

}

export class AccountMembership {
    account: AccountRef;
    member: boolean;
}
