import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Task, TimesheetDay, TimesheetLine, TimesheetLineAuthorization} from '../../../../spurado';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TimesheetService} from '../../../../service/timesheet/timesheet.service';
import {DatePipe} from '@angular/common';
import {CommonService} from '../../../../service/common/common.service';
import {BaseTimesheetTableComponent} from '../base-timesheet-table.component';
import {SnackbarService} from '../../../../service/snackbar/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {HolidayService} from '../../../../service/holiday/holiday.service';
import {TimesheetCommonService} from '../../../../service/timesheet/timesheet-common-service/timesheet-common.service';
import {ApprovalService} from '../../../../service/approval/approval.service';
import {BreakpointService} from '../../../../service/breakpoint/breakpoint.service';
import {ClientService} from '../../../../service/client/client.service';
import {TaskService} from '../../../../service/task/task.service';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationService} from '../../../../service/application/application.service';

@Component({
    selector: 'spurado-mobile-timesheet-table',
    templateUrl: './mobile-timesheet-table.component.html',
    styleUrls: ['./mobile-timesheet-table.component.scss']
})
export class MobileTimesheetTableComponent extends BaseTimesheetTableComponent implements OnInit {

    @ViewChild('taskInput') taskInput: ElementRef<HTMLInputElement>;

    selectedLines: TimesheetLine[];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    locale: string;
    step: number;
    dataPerDay: Map<number, { line: TimesheetLine, task: Task, day: TimesheetDay }[]>;

    constructor(applicationService: ApplicationService,
                private taskService: TaskService,
                projectDialog: MatDialog,
                holidayService: HolidayService,
                timesheetService: TimesheetService,
                approvalService: ApprovalService,
                public timesheetCommonService: TimesheetCommonService,
                commonService: CommonService,
                breakpointService: BreakpointService,
                snackbarService: SnackbarService,
                clientService: ClientService,
                translateService: TranslateService,
                clientDialog: MatDialog,
                datePipe: DatePipe,
                submissionConfirmationDialog: MatDialog,
                commentDialog: MatDialog,
                holidayDialog: MatDialog) {
        super(applicationService,
            timesheetService,
            timesheetCommonService,
            approvalService,
            holidayService,
            commonService,
            breakpointService,
            snackbarService,
            clientService,
            translateService,
            clientDialog,
            submissionConfirmationDialog,
            commentDialog,
            holidayDialog,
            projectDialog);
        this.step = 0;
        this.dataPerDay = new Map<number, { line: TimesheetLine, task: Task, day: TimesheetDay }[]>();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedLines = this.timesheet.timesheetLines;
        this.buildDaysList(this.timesheet);
        this.buildDataForDays();
    }

    openCommentBoxAllowed(line: TimesheetLine): boolean {
        const canView = line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.VIEW_COMMENT) !== -1;
        const canEdit = line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.EDIT_COMMENT) !== -1;
        return canView || canEdit;
    }

    isLineRemovable(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.DELETE) !== -1;
    }

    isLineDuplicable(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.DUPLICATE) !== -1;
    }

    openClientModalAllowed(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.EDIT_CLIENT) !== -1;
    }

    removeLine(line: TimesheetLine) {
        this.timesheetService.deleteLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, this.mode, line.sequence)
        .catch(error => {
                this.snackbarService.openErrorSnackBar(error);
            }
        );
    }

    setStep(step: number) {
        this.step = step;
    }

    prevStep() {
        this.step = this.step - 1;
    }

    nextStep() {
        this.step = 1 + (this.step % this.days.length);
    }

    private buildDataForDays(): void {
        this.timesheet.timesheetLines.forEach(line => {
            line.timesheetDays.forEach(timesheetDay => {
                if (this.dataPerDay.has(timesheetDay.number)) {
                    this.dataPerDay.get(timesheetDay.number).push({
                        line,
                        task: line.task,
                        day: timesheetDay
                    });
                } else {
                    this.dataPerDay.set(timesheetDay.number, [{
                        line,
                        task: line.task,
                        day: timesheetDay
                    }]);
                }
            });
        });
    }
}
