import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationDetails, InfoMessage } from '../../spurado';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private apiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    public static getBooleanValueFromStorage(key: string): boolean {
        const value = localStorage.getItem(key);
        return value ? value.toLowerCase() === 'true' : false;
    }

    getApplicationDetails(): Observable<ApplicationDetails> {
        return this.httpClient.get<ApplicationDetails>(this.apiUrl + `commons/version`);
    }

    getMessageInfos(orgUuid: string): Observable<Array<InfoMessage>> {
        return this.httpClient.get<Array<InfoMessage>>(this.apiUrl + `${orgUuid}/commons/messages`);
    }

    handleServerError(error): boolean {
        if (error.status === 401) {
            console.log('Error 401 ... Let\'s wait for the user to be connected !');
            return false; // Not necessary to redirect
        } else if (error.status === 403) {
            console.error('The server knows who you are and knows that you are not ' +
                'authorized to access this resource. Let\'s go to \'oops\' !');
        } else {
            console.error('Argh, server error ! (' + error.status + '). Let\'s go \'oops\' !');
        }
        return true; // Redirect to oops !
    }
}
