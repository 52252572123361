import {BehaviorSubject, Observable} from 'rxjs';

export class AppStore {
    private store: Map<StoreActionType, AppStore.ApplicationStoreElement<any>>;

    constructor() {
        this.store = new Map<StoreActionType, AppStore.ApplicationStoreElement<any>>();
    }

    addStoreElement<T>(storeAction: StoreAction, initialValue: T, updateMethod: (param?: any) => Observable<T>): AppStore {
        this.store.set(storeAction.type, new AppStore.ApplicationStoreElement<T>(initialValue, updateMethod));

        return this;
    }

    getSnapshot(storeActionType: StoreActionType): AppStore.ApplicationStoreElement<any> {
        return this.store.get(storeActionType).lastValue;
    }

    listen<T>(storeActionType: StoreActionType): AppStore.ApplicationStoreElement<T> {
        return this.store.get(storeActionType);
    }

    dispatch(storeAction: StoreAction) {
        console.log('dispatching action : ', storeAction);
        this.store.get(storeAction.type).updateData(storeAction.argument);
    }

    dispatchReset(storeActions: StoreAction[]) {
        for (const storeAction of storeActions) {
            console.log('dispatching initial value for action : ', storeAction);
            this.store.get(storeAction.type).reset();
        }
    }
}

export module AppStore {
    export class ApplicationStoreElement<T> {
        private readonly initialValue: T;
        private data$: BehaviorSubject<T>;
        private loading$: boolean;
        private updateMethod: (param?: any) => Observable<T>;
        public data: Observable<T>;
        public lastValue: T;

        constructor(initialValue: T, updateMethod: (param?: any) => Observable<T>) {
            this.data$ = new BehaviorSubject<T>(initialValue);
            this.data = this.data$.asObservable();
            this.lastValue = this.data$.value;
            this.updateMethod = updateMethod;
            this.initialValue = initialValue;
            this.loading$ = false;
        }

        get loading() {
            return this.loading$;
        }

        reset() {
            this.loading$ = true;
            this.data$.next(this.initialValue);
            this.loading$ = false;
        }

        updateData(inputParam?: any) {
            this.loading$ = true;
            this.updateMethod(inputParam)
            .toPromise()
            .then((result: T) => {
                this.data$.next(result);
            })
            .catch(reason => {
                // TODO change this
                console.error(reason);
            })
            .finally(() => {
                this.loading$ = false;
            });
        }
    }
}

export class StoreAction {
    public type: StoreActionType;
    public argument?: any;
}

export enum StoreActionType {
    ACCOUNT = 'ACCOUNT',
    USER = 'USER',
    THEME = 'THEME',
    APPLICATION_DETAILS = 'APPLICATION_DETAILS',
    PENDING_APPROVALS_COUNT = 'PENDING_APPROVALS_COUNT',
    PENDING_TASKS_COUNT = 'PENDING_TASKS_COUNT',
}
