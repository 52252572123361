import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'spurado-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent {

    @Input()
    direction: 'vertical' | 'horizontal' = 'vertical';
}
