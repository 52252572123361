import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Client, ClientRef, TimesheetLine} from '../../../spurado';
import {Observable} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RequireMatch} from '../../common/requireMatch';
import {map, startWith} from 'rxjs/operators';

type ClientDialogData = {
    line: TimesheetLine,
    clients: Client[]
};

@Component({
    selector: 'spurado-timesheet-client',
    templateUrl: './timesheet-client.component.html',
    styleUrls: ['./timesheet-client.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetClientComponent {

    line: TimesheetLine;
    autocompleteClientList: Client[];
    filteredClients: Observable<Client[]>;
    clientFormControl: FormControl;

    constructor(
        private dialogRef: MatDialogRef<TimesheetClientComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ClientDialogData,
    ) {

        this.line = data.line;
        this.autocompleteClientList = data.clients;

        this.clientFormControl = new FormControl<ClientRef>(this.line.client, [Validators.required, RequireMatch]);

        this.filteredClients = this.clientFormControl.valueChanges
        .pipe(
            startWith(this.line.client?.name ?? ''),
            map(value => this.filterClients(value))
        );
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    selectClient() {
        this.copyDataForReturn();
        this.dialogRef.close(this.getFormValue());
    }

    selectNoClient() {
        this.line.client = new Client();
        this.dialogRef.close(this.getFormValue());
    }

    copyDataForReturn() {
        this.line.client = this.clientFormControl.value;
    }

    getFormValue(): TimesheetLine {
        return this.line;
    }

    clientToString(client?: Client): string | undefined {
        return client?.name;
    }

    private filterClients(value): Client[] {
        if (typeof value !== 'string') {
            return [];
        }
        const filterValue = value.toLowerCase();
        return this.autocompleteClientList.filter(option => option.name.toLowerCase().includes(filterValue));
    }
}
