import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './component/home/home.component';
import {OopsComponent} from './component/oops/oops.component';
import {ApproveComponent} from './component/approve/approve.component';
import {TimesheetApprobationComponent} from './component/timesheet-approbation/timesheet-approbation.component';
import {OrganisationComponent} from './component/organisation/organisation.component';
import {AccountComponent} from './component/account/account.component';
import {RouterComponent} from './component/router/router.component';
import {ReportComponent} from './component/report/report.component';
import {TimesheetPdfGenerationComponent} from './component/timesheet-pdf-generation/timesheet-pdf-generation.component';
import {ImpersonateComponent} from './component/account/impersonate/impersonate.component';
import {SecurityGuard} from './strategy/security-guard.service';

const routes: Routes = [
    {path: '', pathMatch: 'prefix', component: OrganisationComponent},
    {path: 'index.html', component: OrganisationComponent},
    {path: 'errors/oops', component: OopsComponent},
    {
        path: ':orgUuid',
        component: RouterComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'account', component: AccountComponent},
            {path: 'account/impersonate', component: ImpersonateComponent},
            {path: 'approval', component: ApproveComponent},
            {path: 'reporting', component: ReportComponent},
            {path: 'timesheet2pdf', component: TimesheetPdfGenerationComponent},
            {path: 'approval/timesheet', component: TimesheetApprobationComponent},
            {
                path: 'admin',
                loadChildren: () => import('./component/admin/admin.module').then(a => a.AdminModule),
                canLoad: [SecurityGuard]
            },
            {path: '**', redirectTo: 'errors/oops'} // Should be the last one
        ]
    },
    {path: '**', redirectTo: 'errors/oops'} // Should be the last one
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
