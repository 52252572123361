import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TimesheetValidation} from '../../../spurado';

@Component({
    selector: 'spurado-timesheet-validation',
    templateUrl: './timesheet-validation.component.html',
    styleUrls: ['./timesheet-validation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetValidationComponent {

    @Input()
    loading: boolean;
    @Input()
    timesheetValidation: TimesheetValidation;
}
