import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ICityDepartment} from '../../spurado';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    private apiUrl: string = environment.apiUrl;

    constructor(private datePipe: DatePipe, private httpClient: HttpClient) {
    }

    getMyReportTypes(orgUuid: string): Observable<Array<string>> {
        return this.httpClient.get<Array<string>>(this.apiUrl + `${orgUuid}/reporting/types`);
    }

    buildReportURL(orgUuid: string, reportType: string, reportPeriod: string, fromDate: Date, toDate: Date, statuses: string[], columns: string[], departments: string[], divisions: string[]): string {
        const fromDateStr: string = this.datePipe.transform(fromDate, 'dd/MM/yyyy');
        const toDateStr: string = this.datePipe.transform(toDate, 'dd/MM/yyyy');
        let url = this.apiUrl + `${orgUuid}/reporting?fromDate=${fromDateStr}&toDate=${toDateStr}`;
        if (reportType) {
            url += `&type=${reportType}`;
        }
        if (reportPeriod) {
            url += `&period=${reportPeriod}`;
        }
        if (statuses && statuses.toString() && statuses.toString().trim().length > 0) {
            url += `&statuses=${statuses.toString().trim()}`;
        }
        if (columns && columns.toString() && columns.toString().trim().length > 0) {
            url += `&splitBy=${columns.toString().trim()}`;
        }
        if (departments) {
            url += `&departments=` + encodeURIComponent(departments.toString().trim());
        }
        if (divisions) {
            url += `&divisions=` + encodeURIComponent(divisions.toString().trim());
        }
        url += `&format=CSV`;
        return url;
    }

    buildPDFTimesheetURL(orgUuid: string, accountUuid: string, month: Date, locale: string, color?: string): string {
        return this.apiUrl + `${orgUuid}/reporting/timesheet2pdf?month=${this.datePipe.transform(month, 'dd/MM/yyyy')}&accountUuid=${accountUuid}&locale=${locale}${color ? `&mainColor=${encodeURIComponent(color)}` : ''}`;
    }

    getDepartments(orgUuid: string): Observable<Array<ICityDepartment>> {
        return this.httpClient.get<Array<ICityDepartment>>(this.apiUrl + `${orgUuid}/integration/departments`);
    }
}
