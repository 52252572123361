<ng-container *ngIf="!loadingTimesheet">
    <spurado-week-navigator [disabled]="initialWeek?.weekNumber === timesheet?.weekNumber"
                            [isLoading]="loadingTimesheet"
                            [displayedTimesheet]="timesheet"
                            [displayWeekend]="displayWeekend"
                            [currentWeek]="timesheet?.weekNumber"
                            [week]="timesheet?.week"
                            (initialLoading)="getTimesheetForCurrentWeek()"
                            (clickNext)="getTimesheetForNextWeek()"
                            (clickPrevious)="getTimesheetForPrevioustWeek()">
    </spurado-week-navigator>

    <spurado-timesheet-status [timesheet]="timesheet" [loggedAccount]="loggedAccount" [mode]="mode"
                              (refresh)="refreshDataForSelectedWeek()"></spurado-timesheet-status>

    <spurado-timesheet-table *ngIf="(breakpointService.shouldDisplayDesktopLayout() | async)"
                             [mode]="mode"
                             [timesheet]="timesheet"
                             [loggedAccount]="loggedAccount"
                             [timesheetUnderRefresh]="loadingTimesheet"
                             [displayWeekend]="displayWeekend"
                             (displayWeekendChange)="updateDisplayWeekend($event)">
    </spurado-timesheet-table>

    <spurado-mobile-timesheet-table
        *ngIf="(breakpointService.shouldDisplayDesktopLayout() | async) === false"
        [mode]="mode"
        [timesheetUnderRefresh]="loadingTimesheet"
        [timesheet]="timesheet"
        [loggedAccount]="loggedAccount"
        [displayWeekend]="displayWeekend"
        (displayWeekendChange)="updateDisplayWeekend($event)">
    </spurado-mobile-timesheet-table>
</ng-container>
<ng-container *ngIf="loadingTimesheet">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
