import {Pipe, PipeTransform} from '@angular/core';
import {AccountRef} from '../../../spurado';

@Pipe({
    name: 'sortAccountsByUserName'
})
export class SortAccountsByUserNamePipe implements PipeTransform {

    transform(value: Array<AccountRef>, ...args: any[]): Array<AccountRef> {
        if (value) {
            value.sort((a: AccountRef, b: AccountRef) => {
                if (!a.user || !b.user) {
                    return 0;
                }

                if (!a.user.displayName || !b.user.displayName) {
                    return 0;
                }

                return a.user.displayName.localeCompare(b.user.displayName);
            });
        }
        return value;
    }

}
