import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationDetails, Organisation, User} from '../../spurado';
import {UserService} from '../../service/user/user.service';
import {OrganisationService} from '../../service/organisation/organisation.service';
import {Router} from '@angular/router';
import {AccountService} from '../../service/account/account.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {organisationNameValidator, OrganisationValidatorDirective} from './organisation-validator';
import {mergeMap, Observable, shareReplay, Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-organisation',
    templateUrl: './organisation.component.html',
    styleUrls: ['./organisation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationComponent implements OnInit, OnDestroy {

    private readonly unsubscribeSubject: Subject<void>;

    organisationFormGroup: FormGroup;

    loggedUser$: Observable<User>;
    isUniqueOrganisation$: Observable<boolean>;
    organisations$: Observable<Organisation[]>;

    constructor(private accountService: AccountService,
                public applicationService: ApplicationService,
                private router: Router,
                private organisationService: OrganisationService,
                private userService: UserService) {
        this.unsubscribeSubject = new Subject<void>();
        this.loggedUser$ = applicationService.applicationStore.listen<User>(StoreActionType.USER).data;
        this.organisationFormGroup = new FormGroup<any>({
            organisationName: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
            organisationUrl: new FormControl<string>('', [Validators.required, Validators.maxLength(255)], organisationNameValidator(organisationService))
        });
    }

    ngOnInit(): void {
        const applicationDetails$ = this.applicationService.applicationStore.listen<ApplicationDetails>(StoreActionType.APPLICATION_DETAILS).data
        .pipe(
            takeUntil(this.unsubscribeSubject),
            shareReplay(),
        );

        this.isUniqueOrganisation$ = applicationDetails$.pipe(
            map(details => details?.uniqueOrganisationUuid),
            tap(uniqueOrganisationUuid => {
                if (!!uniqueOrganisationUuid) {
                    this.goToOrganisation(uniqueOrganisationUuid);
                }
            }),
            map(uniqueOrganisationUuid => !!uniqueOrganisationUuid)
        );

        this.organisations$ = applicationDetails$.pipe(
            mergeMap(() => this.applicationService.applicationStore.listen<User>(StoreActionType.USER).data),
            filter(user => user && this.isValidUser()),
            mergeMap(() => this.organisationService.getMyOrganisations()),
            map(organisations => {
                const spuradoLastOrgUuidUsed = window.localStorage.getItem('spuradoLastOrgUuidUsed');
                if (spuradoLastOrgUuidUsed && organisations?.map(o => o.uuid).indexOf(spuradoLastOrgUuidUsed) != -1) {
                    this.router.navigate(['/' + spuradoLastOrgUuidUsed]);
                }

                return organisations;
            })
        );

        this.organisationFormGroup.controls.organisationName.valueChanges.subscribe(
            value => this.organisationFormGroup.controls.organisationUrl.setValue(this.generateUuid(value))
        );
    }

    goToOrganisation(orgUuid: string): void {
        window.localStorage.setItem('spuradoLastOrgUuidUsed', orgUuid);
        this.router.navigate(['/' + orgUuid]);
    }

    isValidUser(): boolean {
        return this.userService.isValidUser();
    }

    generateUuid(name: string): string {
        return OrganisationValidatorDirective.stripAccents(name).toLowerCase().replace(/[^a-z0-9]+/g, '-');
    }

    createOrganisation(): void {
        this.organisationService.createOrganisation(this.getOrganisationName(), this.getOrganisationUuid()).subscribe(response => {
            this.router.navigate(['/' + this.getOrganisationUuid()]);
        });
    }

    getOrganisationName() {
        return this.organisationFormGroup.value.organisationName;
    }

    getOrganisationUuid() {
        return this.organisationFormGroup.value.organisationUrl;
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
