<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'users.email' | translate}}</mat-label>
        <input (keyup.enter)="emailFormControl.valid && addUser()" [formControl]="emailFormControl" name="email"
               type="email"
               matInput/>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" [disabled]="!emailFormControl.valid"
            [mat-dialog-close]="emailFormControl.value">{{'common.actions.add' | translate}}</button>
    <button mat-flat-button color="warn" (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
