import {Pipe, PipeTransform} from '@angular/core';
import {AccountDelegation} from "../../../../spurado";

@Pipe({
    name: 'delegationPipe'
})
export class DelegationPipe implements PipeTransform {

    transform(value: AccountDelegation[], filter: string): AccountDelegation[] {
        if (!filter) {
            return value;
        }
        const filters = filter.split(/\s+/).map(filter => filter.toLowerCase());
        return value.filter(
            accountDelegation => this.matchFilters(accountDelegation.delegateeDisplayName, filters) || this.matchFilters(accountDelegation.delegateeEmail, filters));
    }

    private matchFilters(attribute: string, filters: string[]): boolean {
        attribute = attribute.toLowerCase();
        return filters.every(filter => attribute.includes(filter));
    }

}
