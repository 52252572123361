import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SmartTableAction, SmartTableColumnDef} from './smart-table-column-def';
import {BreakpointService} from '../../../service/breakpoint/breakpoint.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'spurado-smart-table',
    templateUrl: './smart-table.component.html',
    styleUrls: ['./smart-table.component.scss']
})

export class SmartTableComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    set tableDataSource(dataSource: MatTableDataSource<any>) {
        this._tableDataSource = dataSource;
        if (this._tableDataSource) {
            this._tableDataSource.filter = this.filterValue;
        }
    }

    get tableDataSource(): MatTableDataSource<any> {
        return this._tableDataSource;
    }

    _tableDataSource: MatTableDataSource<any>;
    @Input()
    columnsDefinition: SmartTableColumnDef[];
    @Input()
    pageSizeOptions: number[];
    @Input()
    filterEnabled: boolean;
    @Input()
    addEnabled: boolean;
    @Input()
    toggleEnabled: boolean;
    @Input()
    readonly: boolean;
    @Input()
    toggleChecked: boolean;
    @Input()
    toggleDisabled: boolean;
    @Input()
    toggleLabel: string;
    @Input()
    exportEnable: boolean;

    @Output()
    add = new EventEmitter<void>();
    @Output()
    toggleChange = new EventEmitter<void>();

    filteredColumnsDefinition: SmartTableColumnDef[];
    columnsToDisplay: string[];
    smartTableAction = SmartTableAction;

    /**
     * MatPaginator initialization. Has to be defined as a setter in order to take the latest value, when
     * properly injected from the HTML template
     * @param smartTablePaginator   the MatPaginator component received from the template
     */
    @ViewChild('tablePaginator', {static: false}) set paginator(smartTablePaginator: MatPaginator) {
        if (smartTablePaginator) {
            this.tableDataSource.paginator = smartTablePaginator;
        }
    }

    /**
     * MatSort initialization. Has to be defined as a setter in order to take the latest value, when
     * properly injected from the HTML template
     * @param tableSort   the MatSort component received from the template
     */
    @ViewChild('tableSort', {static: false}) set tableSort(tableSort: MatSort) {
        if (tableSort) {
            this.tableDataSource.sort = tableSort;
        }
    }

    private filterValue: string;

    /**
     * Defines the default values, if not specified in the parent component.
     *      the filter is not shown by default
     *      readonly flag is 'false' by default
     *      page size options are set to [10, 25, 50, 100]
     */
    constructor(public breakpointService: BreakpointService) {
        this.filterEnabled = false;
        this.addEnabled = true;
        this.toggleEnabled = false;
        this.readonly = false;
        this.toggleDisabled = false;
        this.toggleLabel = '';
        this.pageSizeOptions = [10, 25, 50, 100];
        this.exportEnable = true;
    }

    ngOnInit(): void {
        this.buildColumns();
    }

    sanitizeContent(content: any): string {
        if (typeof content !== 'string') {
            return content;
        }
        
        return content
        .replace(/(?<!\/)\/(?!\/)/g, '/<wbr/>')
        .replace(/(?<!\.)\.(?!\.)/g, '<wbr/>.');
    }

    applyFilter(event: Event) {
        this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.tableDataSource.filter = this.filterValue;
    }

    exportToExcel() {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tableDataSource.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SpuradoData');

        /* save to file */
        XLSX.writeFile(wb, 'spurado-data.xlsx');
    }

    private buildColumns() {
        this.breakpointService.shouldDisplayDesktopLayout().subscribe(value => {
            if (value) {
                this.filteredColumnsDefinition = this.columnsDefinition;
            } else {
                this.filteredColumnsDefinition = this.columnsDefinition.filter(def => {
                    return (def.hiddable ? !def.hiddable : true);
                });
            }
            this.columnsToDisplay = this.filteredColumnsDefinition.map(colDef => colDef.datasourceAttribute);
        });
    }
}
