import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {Action, LineStatus, TimesheetDay, TimesheetLine, WeekDay} from '../../../../spurado';
import {ConfirmationComponent} from '../../../common/confirmation/confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import {TimesheetService} from '../../../../service/timesheet/timesheet.service';
import {SnackbarService} from '../../../../service/snackbar/snackbar.service';
import {DatePipe, KeyValue} from '@angular/common';
import {CommonService} from '../../../../service/common/common.service';
import {BreakpointService} from '../../../../service/breakpoint/breakpoint.service';
import {HolidayService} from '../../../../service/holiday/holiday.service';
import {BaseTimesheetTableComponent} from '../base-timesheet-table.component';
import {TimesheetCommonService} from '../../../../service/timesheet/timesheet-common-service/timesheet-common.service';
import {ApprovalService} from '../../../../service/approval/approval.service';
import {ClientService} from '../../../../service/client/client.service';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationService} from '../../../../service/application/application.service';
import {StoreActionType} from '../../../../app.store';

@Component({
    selector: 'spurado-timesheet-table',
    templateUrl: './timesheet-table.component.html',
    styleUrls: ['./timesheet-table.component.scss']
})
export class TimesheetTableComponent extends BaseTimesheetTableComponent implements OnInit, AfterViewInit {

    actionMode = Action;
    public focusEventEmitter: EventEmitter<boolean>;

    constructor(
        applicationService: ApplicationService,
        breakpointService: BreakpointService,
        projectDialog: MatDialog,
        timesheetService: TimesheetService,
        approvalService: ApprovalService,
        timesheetCommonService: TimesheetCommonService,
        commonService: CommonService,
        snackbarService: SnackbarService,
        clientService: ClientService,
        clientDialog: MatDialog,
        datePipe: DatePipe,
        holidayService: HolidayService,
        translateService: TranslateService,
        holidayDialog: MatDialog,
        commentDialog: MatDialog,
        submissionConfirmationDialog: MatDialog) {
        super(applicationService,
            timesheetService,
            timesheetCommonService,
            approvalService,
            holidayService,
            commonService,
            breakpointService,
            snackbarService,
            clientService,
            translateService,
            clientDialog,
            submissionConfirmationDialog,
            commentDialog,
            holidayDialog,
            projectDialog);
        this.focusEventEmitter = new EventEmitter<boolean>(true);
    }

    deleteLineConfirmationModal(timesheetLine: TimesheetLine): void {
        const atLeastOneHourEncoded = timesheetLine.timesheetDays &&
            timesheetLine.timesheetDays.filter((t) => (t.actualDuration && t.actualDuration > 0)).length > 0;

        if (atLeastOneHourEncoded) {
            const submissionConfirmationRef = this.submissionConfirmationDialog.open(ConfirmationComponent, {
                disableClose: true,
                autoFocus: true
            });

            const subscription = submissionConfirmationRef.afterClosed().subscribe(result => {
                if (result) {
                    this.deleteLine(timesheetLine);
                }
                subscription.unsubscribe();
            });
        } else {
            this.deleteLine(timesheetLine);
        }

    }

    private deleteLine(timesheetLine: TimesheetLine): void {

        this.timesheetService.deleteLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, this.mode, timesheetLine.sequence)
        .then(() => {
            this.lineActionChange.emit({
                timesheetUuid: this.timesheet.uuid,
                line: timesheetLine,
                action: Action.DELETE
            });
            if (timesheetLine.status !== LineStatus.OPEN) {
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.PENDING_APPROVALS_COUNT,
                    argument: this.loggedAccount.organisation.uuid
                });
            }
        })
        .catch(() => {
                this.snackbarService.openErrorSnackBar();
            }
        );
    }

    approveOrRejectLine(timesheetUuid: string, line: TimesheetLine, action: Action) {
        this.lineActionChange.emit({timesheetUuid, line, action});
    }

    compareNumbers = (a: KeyValue<number, number>, b: KeyValue<number, number>): number => {
        return a.key < b.key ? -1 : a.key === b.key ? 0 : 1;
    }

    getLastApprobatorOfLine(line: TimesheetLine) {
        if (line && line.lastApprobator && line.lastApprobator.displayName) {
            return line.lastApprobator.displayName;
        }
        return '';
    }

    isPendingApproval(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.PENDING_APPROVAL:
                return true;
            case LineStatus.OPEN:
            case LineStatus.VALIDATED:
                return false;
        }
    }

    isValidated(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.VALIDATED:
                return true;
            case LineStatus.OPEN:
            case LineStatus.PENDING_APPROVAL:
                return false;
        }
    }

    getCellClassForDay(line: TimesheetLine, day: TimesheetDay) {
        const dayTypeStyle = this.getCellClassForDayType(day);
        const lineStatusStyle = this.getCellClassForLineStatus(line);
        const dayStatusStyle = this.getCellClassForDayStatus(day);

        return dayTypeStyle + ' ' + lineStatusStyle + ' ' + dayStatusStyle;
    }

    private getCellClassForLineStatus(line: TimesheetLine) {
        switch (line.status) {
            case LineStatus.OPEN:
                return '';
            case LineStatus.PENDING_APPROVAL:
                return 'pending-approval';
            case LineStatus.VALIDATED:
                return 'validated';
        }
    }

    private getCellClassForDayType(day: TimesheetDay): string {
        const weekDay: WeekDay = this.timesheet.week[day.number];

        return weekDay.weekDay ? 'week' : 'week-end';
    }

    private getCellClassForDayStatus(day: TimesheetDay) {
        const weekDay: WeekDay = this.timesheet.week[day.number];
        if (weekDay.legalHoliday) {
            return 'legal-holiday';
        } else if (weekDay.personalHoliday) {
            return 'personal-holiday';
        } else {
            return '';
        }
    }

    getClassesForLine(line: TimesheetLine): string {
        let result = 'table-row';
        if (this.highlightedLineSequence && this.highlightedLineSequence === line.sequence) {
            result += ' highlightedTask';
        }
        return result;
    }

    ngAfterViewInit(): void {
        this.focusEventEmitter.emit(true);
    }


}
