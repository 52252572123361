<div id="submitter">
    <mat-icon color="primary">account_circle</mat-icon>
    {{timesheet.account.user.displayName}}
</div>
<mat-accordion class="mobile-accordion-style">
    <mat-expansion-panel hideToggle *ngFor="let line of timesheet.timesheetLines, index as index" [expanded]="(highlightedLineSequence && highlightedLineSequence === line.sequence) || (!highlightedLineSequence && index === 0)">
        <mat-expansion-panel-header [ngClass]="getClassesForLine(line)">
            <mat-panel-title>
                {{line.task.name}}<span *ngIf="line.client">&nbsp;({{line.client.name}})</span>
            </mat-panel-title>
            <mat-panel-description style="user-select: none;">
                            <span *ngIf="timesheetCommonService.isPendingApproval(line.status)">
                                <mat-icon color="primary">find_in_page</mat-icon>
                                <span class="status-helper">{{'timesheet.line.statuses.pending_approval' | translate}}</span>
                            </span>
                <span *ngIf="timesheetCommonService.isValidated(line.status)">
                                <mat-icon color="primary">check_circle</mat-icon>
                                <span class="status-helper">{{'timesheet.line.statuses.validated' | translate}}</span>
                            </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div id="grid-wrapper">
            <div id="grid-container">
                <div class="grid-header">{{'timesheet.day.name' | translate}}</div>
                <div class="grid-header">{{'timesheet.day.hour' | translate}}</div>
                <ng-container
                    *ngFor="let day of getMobileData(timesheet.uuid, line.sequence) | keyvalue: compareKeys">
                    <div class="grid-column-1">
                        <div>
                            <span>{{day.key.number}}</span>
                            <span>{{day.key.date | date: 'dd/MM'}}</span>
                        </div>
                        <ng-container *ngIf="day.key.personalHoliday || day.key.legalHoliday">
                            <div *ngIf="day.key.personalHoliday && day.key.personalHoliday.uuid"
                                 [ngClass]="day.key.personalHoliday || day.key.legalHoliday ? 'sliding-container' : ''">
                                <span id="personal-holiday-chip" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                        {{'timesheet.day.holiday.personal' | translate}}
                                    <mat-icon class="personal-holiday-duration-icon" *ngIf="day.key.personalHoliday.duration === 'FD'">hourglass_full</mat-icon>
                                    <mat-icon class="personal-holiday-duration-icon" *ngIf="day.key.personalHoliday.duration === 'AM'">hourglass_bottom</mat-icon>
                                    <mat-icon class="personal-holiday-duration-icon" *ngIf="day.key.personalHoliday.duration === 'PM'">hourglass_top</mat-icon>
                                </span>
                            </div>
                            <!-- Legal -->
                            <div *ngIf="day.key.legalHoliday"
                                 [ngClass]="day.key.personalHoliday || day.key.legalHoliday ? 'sliding-container' : ''">
                                <span>{{'timesheet.day.holiday.legal' | translate}}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div style="text-align: center;">{{day.value}}</div>
                </ng-container>
            </div>
            <div id="grid-actions">
                <button mat-menu-item (click)="openCommentModal(line)"
                        *ngIf="actionButtonAvailable(line, timesheetLineAuthorization.VIEW_COMMENT) | async">
                    <mat-icon>speaker_notes</mat-icon>
                    <span>{{'timesheet.line.actions.comment_line' | translate}}</span>
                </button>
                <button mat-menu-item
                        (click)="approveLine(timesheet.uuid, line, actionMode.APPROVE)"
                        *ngIf="actionButtonAvailable(line, timesheetLineAuthorization.APPROVE) | async">
                    <mat-icon color="primary">done_outline</mat-icon>
                    <span>{{'timesheet.line.actions.approve_line' | translate}}</span>
                </button>
                <button mat-menu-item
                        (click)="rejectLine(timesheet.uuid, line, actionMode.REJECT)"
                        *ngIf="actionButtonAvailable(line, timesheetLineAuthorization.REJECT) | async">
                    <mat-icon color="warn">pan_tool</mat-icon>
                    <span>{{'timesheet.line.actions.reject_line' | translate}}</span>
                </button>
            </div>
        </div>
        <ng-container>
            <div class="action-buttons">
                <div>
                    <button *ngIf="loggedAccount.organisation.configuration.enableSubmitChecks.value" mat-raised-button color="primary" (click)="openTimesheetValidationModal()">
                        <mat-icon style="margin-right: 10px;">read_more</mat-icon>
                        <span>{{'timesheet.validation.title' | translate}}</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>
