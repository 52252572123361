<div class="search">
    <div class="filter">
        <mat-form-field class="transparent">
            <mat-label>{{'approval_filter.title.filter_options' | translate}}</mat-label>
            <mat-select [formControl]="typeFormControl">
                <mat-option *ngFor="let type of namesType()" [value]="type.valueOf"
                            (click)="getSearchType(type)">{{type | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="search-input transparent"
                        *ngIf="searchType === undefined || searchType !== filterType.DATE">
            <mat-label>{{title | translate}}</mat-label>
            <input type="text"
                   placeholder="{{searchType === undefined?('approval_filter.title.info' | translate) : ''}}"
                   matInput
                   [formControl]="searchFormControl"
                   (keyup.enter)="searchFormControl.valid"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of
                searchType === filterType.TASK? tasks:
                searchType === filterType.CUSTOMER ? customers:
                searchType === filterType.ACCOUNT ? accounts:
                searchType === filterType.PROGRAM ? programs : null;" [value]="item.name"
                            (onSelectionChange)="buildElement(searchType === filterType.ACCOUNT? item.user.displayName : item.name, item.uuid)"
                            matTooltip="{{searchType === filterType.ACCOUNT ? item.user.displayName : item.name}}">
                    {{searchType === filterType.ACCOUNT ? item.user.displayName : item.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="search-input"
                        *ngIf="searchType === filterType.DATE && searchType !== undefined">
            <mat-label>{{title | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="{{'approval_filter.title.from' | translate}}"
                       [formControl]="dateFrom">
                <input matEndDate placeholder="{{'approval_filter.title.to' | translate}}"
                       [formControl]="dateTo"
                       (dateChange)="buildElement(null, null, dateFrom.value, dateTo.value)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div id="result">
        <mat-chip-listbox>
            <mat-chip
                *ngFor="let approvalFilterElement of filterElements"
                [ngClass]="approvalFilterElement.type === filterType.ACCOUNT? 'primary-700':
                  approvalFilterElement.type === filterType.CUSTOMER? 'primary-200':
                  approvalFilterElement.type === filterType.TASK? 'accent-700':
                  approvalFilterElement.type === filterType.PROGRAM? 'accent-200':
                  approvalFilterElement.type === filterType.DATE? 'warn-700': 'warn-200'">
                {{approvalFilterElement.type | translate}} | {{approvalFilterElement.type
            === filterType.DATE ? (approvalFilterElement.from |date) + ' / '
                + (approvalFilterElement.to | date) : approvalFilterElement.name}}
                <mat-icon matChipRemove (click)="deleteElementInList(approvalFilterElement)">
                    cancel
                </mat-icon>
            </mat-chip>
        </mat-chip-listbox>
    </div>

    <button (click)="resetAllFilter()"
            *ngIf="filterElements.length > 0">
        <mat-icon>delete_forever</mat-icon>
    </button>
</div>


