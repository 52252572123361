import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'spurado-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent {

    emailFormControl: FormControl;

    constructor(public dialogRef: MatDialogRef<UserDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {}
    ) {
        this.emailFormControl = new FormControl<string>('', [Validators.required, Validators.email, Validators.maxLength(255)]);
    }

    addUser(): void {
        this.dialogRef.close(this.getUserEmail);
    }

    onNoClick() {
        this.dialogRef.close();
    }

    get getUserEmail(): string {
        return this.emailFormControl.value;
    }
}
