import {Component, OnInit} from '@angular/core';
import {SmartTableAction, SmartTableColumnDef} from '../../common/smart-table/smart-table-column-def';
import {mergeMap, Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {AccountDelegationService} from '../../../service/account-delegation/account-delegation.service';
import {StoreActionType} from '../../../app.store';
import {Router} from '@angular/router';
import {UserService} from '../../../service/user/user.service';
import {ApplicationService} from '../../../service/application/application.service';
import {map, tap} from 'rxjs/operators';
import {Account, AccountDelegation} from '../../../spurado';
import {AccountDelegationData} from '../account-delegation-data';

@Component({
    selector: 'spurado-impersonate',
    templateUrl: './impersonate.component.html',
    styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {
    dataSource$: Observable<MatTableDataSource<AccountDelegationData>>;

    readonly columnsDefinition: SmartTableColumnDef[] = [
        {
            displayName: 'users.name',
            datasourceAttribute: 'delegatorDisplayName',
            sortable: true,
        },
        {
            displayName: 'users.email',
            datasourceAttribute: 'delegatorEmail',
            sortable: true,
        },
        {
            displayName: 'account.delegation.valid_from',
            datasourceAttribute: 'validFrom',
            sortable: true,
        },
        {
            displayName: 'account.delegation.valid_to',
            datasourceAttribute: 'validTo',
            sortable: true,
        },
        {
            displayName: 'account.delegation.pending_approbations',
            datasourceAttribute: 'pendingApprovals',
            sortable: true,

        },
        {
            displayName: '',
            datasourceAttribute: 'impersonate',
            action: SmartTableAction.OTHER,
            actionIcon: 'supervisor_account',
            callbackFn: (row) => this.impersonate(row)
        }
    ];

    private loggedAccount: Account;

    constructor(
        private accountDelegationService: AccountDelegationService,
        private applicationService: ApplicationService,
        private router: Router,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.refreshData();
    }

    private refreshData() {
        this.dataSource$ = this.applicationService.applicationStore.listen(StoreActionType.ACCOUNT)
        .data
        .pipe(
            tap(account => this.loggedAccount = account),
            mergeMap((account: Account) => this.accountDelegationService.getDelegators(account.organisation.uuid)),
            map(accountDelegations => {
                const accountDelegationData = this.prepareAccountDelegationData(accountDelegations);
                return new MatTableDataSource<AccountDelegationData>(accountDelegationData);
            })
        );
    }

    private impersonate(accountDelegationData: AccountDelegationData) {
        const orgUuid = this.loggedAccount.organisation.uuid;
        const newRoute = '/' + orgUuid;
        this.router.navigate([newRoute]).then(() => {
            this.userService.impersonateAccount(accountDelegationData.delegatorUuid, this.loggedAccount.organisation.uuid).subscribe(
                () => {
                    this.applicationService.applicationStore.dispatch({type: StoreActionType.USER});
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.ACCOUNT,
                        argument: orgUuid
                    });
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.PENDING_APPROVALS_COUNT,
                        argument: orgUuid
                    });
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.PENDING_TASKS_COUNT,
                        argument: orgUuid
                    });
                });
        });
    }

    private prepareAccountDelegationData(accountDelegations: AccountDelegation[]): AccountDelegationData[] {
        return accountDelegations.map(accountDelegation => new AccountDelegationData(accountDelegation));
    }
}
