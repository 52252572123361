import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Client, Program} from '../../spurado';

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    private apiUrl: string = environment.apiUrl;
    private readonly programPath: string = '/programs';

    constructor(private httpClient: HttpClient) {
    }

    getAllPrograms(orgUuid: string, filter?: string): Observable<Array<Program>> {
        let requestParams: HttpParams = new HttpParams();
        if (filter && filter.length >= 3) {
            requestParams = requestParams.append('filter', filter);
            return this.httpClient.get<Array<Program>>(this.apiUrl + `${orgUuid}${this.programPath}`, {params: requestParams});
        }
        return this.httpClient.get<Array<Program>>(this.apiUrl + `${orgUuid}${this.programPath}`);
    }

    createProgram(orgUuid: string, program: Program): Observable<any> {
        return this.httpClient.post<Client>(this.apiUrl + `${orgUuid}${this.programPath}`, program);
    }

    updateProgramName(orgUuid: string, program: Program): Observable<any> {
        return this.httpClient.put<Client>(this.apiUrl + `${orgUuid}${this.programPath}/${program.uuid}/name`, program.name);
    }

    updateProgramSponsor(orgUuid: string, program: Program): Observable<any> {
        return this.httpClient.put<Client>(this.apiUrl + `${orgUuid}${this.programPath}/${program.uuid}/sponsor`, program.sponsor.uuid);
    }

}
