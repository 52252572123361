<ng-container *ngIf="!cardOrList else notActive">
    <div id="card-grid">
        <mat-card *ngFor="let approval of approvals">
            <mat-card-header>
                <mat-card-title>
                    <div class="hover">{{approval.userDisplayName}}<span class="icon arrow" (click)="createdFilterTile(approval, filterType.ACCOUNT)"></span></div>
                    <button (click)="approve(approval)" color="primary" mat-icon-button>
                        <mat-icon>thumb_up</mat-icon>
                    </button>
                </mat-card-title>
                <mat-card-subtitle>
                    <p class="hover" >{{approval.taskName}}<span class="icon arrow" (click)="createdFilterTile(approval, filterType.TASK)"></span></p>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="date-hour">
                    <span><mat-icon>calendar_month</mat-icon> <p>{{approval.timesheetWeekAndMonth}}</p></span>
                    <span><mat-icon>timelapse</mat-icon> <p>{{approval.totalDuration}}</p></span>
                </div>
                <p class="attribute">{{'tasks.clients.name' | translate}} :</p>
                <div [ngClass]="approval.clientName !== '-'? 'hover': '' ">{{approval.clientName}}<span class="icon arrow" *ngIf="approval.clientName !== '-'" (click)="createdFilterTile(approval, filterType.CUSTOMER)" ></span></div>
                <p class="attribute">{{'tasks.projects.programs.name' | translate}} :</p>
                <div [ngClass]="approval.programName !== '-'? 'hover': ''">{{approval.programName}}<span class="icon arrow" *ngIf="approval.programName !== '-'" (click)="createdFilterTile(approval, filterType.PROGRAM)" ></span></div>
            </mat-card-content>
            <mat-card-actions>
                <button color="primary"
                        (click)="goToTimesheet(approval)"
                        mat-stroked-button>{{'approval_filter.title.detail_button' | translate}}
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</ng-container>

<ng-template #notActive>
    <div class="table-container" *ngIf="approvals.length > 0">
        <spurado-smart-table [columnsDefinition]="smartTableApproveColumnDef"
                             [tableDataSource]="dataSource"
                             [addEnabled]="false"
                             [exportEnable]="false"
                             [filterEnabled]="false">
        </spurado-smart-table>
    </div>
</ng-template>
