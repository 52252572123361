<div class="chip-wrapper"
     [ngClass]="'chip-color-'+color"
     matRipple
     [matRippleDisabled]="!ripples">
    <div class="chip">
<!--         [class.selected]="selected">-->
        <ng-content select="div[chipText]"></ng-content>
        <ng-container *ngIf="removable">
            <span #customMatIcon>
                <ng-content (click)="action($event)" select="mat-icon"></ng-content>
            </span>
            <ng-container *ngIf="!customMatIcon.innerHTML.trim()">
                <mat-icon (click)="action($event)" inline="true">cancel</mat-icon>
            </ng-container>
        </ng-container>
    </div>
    <div class="chip-progress chip-{{progress}}"></div>
</div>

