import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {

    constructor(private breakpointObserver: BreakpointObserver) {
    }

    minWidth1300Layout(): Observable<boolean> {
        return this.breakpointObserver.observe(['(min-width: 1300px)']).pipe(map(data => {
            return data.matches;
        }));
    }

    shouldDisplayDesktopLayout(): Observable<boolean> {
        return this.breakpointObserver.observe(['(min-width: 980px)']).pipe(map(data => {
            return data.matches;
        }));
    }

    shouldDisplayAdminTables(): Observable<boolean> {
        return this.breakpointObserver.observe(['(min-width: 600px)']).pipe(map(data => {
            return data.matches;
        }));
    }

    shouldDisplaySomething(): Observable<boolean> {
        return this.breakpointObserver.observe(['(min-width: 360px)']).pipe(map(data => {
            return data.matches;
        }));
    }

}
