import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import {Account, AccountRef, SystemGroup} from '../../spurado';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(private httpClient: HttpClient) {
    }

    apiUrl: string = environment.apiUrl;

    isImpersonated(account: Account) {
        if (account) {
            return account.impersonated;
        } else {
            return false;
        }
    }

    isUserAdmin(account: Account): boolean {
        if (account) {
            return account.isAdmin;
        }
        return false;
    }

    isUserImpersonator(account: Account): boolean {
        if (account) {
            return account.groups.filter(g => g.name === SystemGroup.SUPER_IMPERSONATOR).length > 0;
        }
        return false;
    }

    accountToString(account?: Account): string | undefined {
        if (account) {
            let name = '';
            if (account.user.displayName) {
                name += account.user.displayName + ' ';
            }
            return name;
        }
        return undefined;
    }

    getAccount(orgUuid: string): Observable<Account> {
        return orgUuid ? this.httpClient.get<Account>(this.apiUrl + `${orgUuid}/accounts/me`) : of(undefined);
    }

    getAllAccounts(orgUuid: string, groupUuid?: string, isMemberOfGroup?: boolean, filter?: string): Observable<Array<AccountRef>> {
        let requestParams: HttpParams = new HttpParams();
        if (groupUuid) {
            requestParams = requestParams.append('notMemberOfGroup', groupUuid);
        }
        if (isMemberOfGroup !== undefined && isMemberOfGroup !== null) {
            requestParams = requestParams.append('isMemberOfGroup', String(isMemberOfGroup));
        }

        if (filter && filter.length >= 3) {
            requestParams = requestParams.append('filter', filter)
        }

        if (groupUuid || (isMemberOfGroup !== undefined && isMemberOfGroup !== null) || filter && filter.length >= 0) {
            return this.httpClient.get<Array<AccountRef>>(this.apiUrl + `${orgUuid}/accounts`, {params: requestParams});
        } else {
            return this.httpClient.get<Array<AccountRef>>(this.apiUrl + `${orgUuid}/accounts`);
        }
    }

    getManagedAccounts(orgUuid: string): Observable<Array<AccountRef>> {
        return this.httpClient.get<Array<AccountRef>>(this.apiUrl + `${orgUuid}/accounts/managed`);
    }

    getAllDetailedAccounts(orgUuid: string): Observable<Array<Account>> {
        return this.httpClient.get<Array<AccountRef>>(this.apiUrl + `${orgUuid}/accounts/details`);
    }

    createAccount(email: string, orgUuid: string): Observable<any> {
        const requestParams: HttpParams = new HttpParams().set('email', email);
        return this.httpClient.post(this.apiUrl + `${orgUuid}/accounts`, null, {params: requestParams});
    }

    deleteAccount(accountUuid: string, orgUuid: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + `${orgUuid}/accounts/${accountUuid}`);
    }

    getNumberOfActiveAccounts(orgUuid: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + `${orgUuid}/accounts/active/count`);
    }

    //Deprecated
    getOneTimeToken(orgUuid: string): Observable<string> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/accounts/oneTimeToken`, null, {responseType: 'text'});
    }

}
