<div class="daily-pointing-container">
    <ng-container *ngIf="!disabled else disabledBtn">
        <ng-container *ngIf="badge else noWorkToday">
            <mat-icon [ngClass]="iconStyleClass" class="daily-pointing-icon-active" [matBadge]="badge" matBadgeColor="primary" color="accent" (click)="next()">work</mat-icon>
        </ng-container>
        <ng-template #noWorkToday>
            <mat-icon [ngClass]="iconStyleClass" class="daily-pointing-icon-active" color="accent" (click)="next()">work_off</mat-icon>
        </ng-template>
    </ng-container>
    <ng-template #disabledBtn>
        <mat-icon [ngClass]="iconStyleClass" [matBadge]="badge" matBadgeColor="primary" color="accent">lock</mat-icon>
    </ng-template>
</div>
