<div class="spurado-main-title">
    <h1 class="mat-headline">{{'timesheet.approving.title' | translate}} </h1>
</div>

<div class="component-content" id="approbation-content">
    <spurado-filter (elementSelect)="searchBasedOn($event)" [generatedFilter]="generatedFilter"></spurado-filter>

    <div id="right-panel">
        <ng-container *ngIf=" !loading && allApprovals.length > 0 ">
            <div class="grouped-action">
                <span
                    [innerHTML]="'timesheet.approving.grouped_approve.text_approve' | translate: {count: allApprovals.length}"></span>,
                <span
                    [innerHTML]="'timesheet.approving.grouped_approve.text_total_duration' | translate: {count: approvalsDuration}"></span>
                <button mat-mini-fab
                        color="primary"
                        (click)="groupedActionApproveLines()">
                    <mat-icon>thumb_up</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="allApprovals.length > 0">
            <div class="result-action">
                <mat-button-toggle-group *ngIf="breakPointService.minWidth1300Layout() | async"
                                         [formControl]="layoutCardOrListMode">
                    <mat-button-toggle [value]="approvalLayout.CARD">
                        <mat-icon *ngIf="layoutCardOrListMode.value === approvalLayout.CARD"
                                  color="primary">view_module
                        </mat-icon>
                        <mat-icon *ngIf="layoutCardOrListMode.value !== approvalLayout.CARD">
                            view_module
                        </mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="approvalLayout.LIST">
                        <mat-icon *ngIf="layoutCardOrListMode.value === approvalLayout.LIST"
                                  color="primary">reorder
                        </mat-icon>
                        <mat-icon *ngIf="layoutCardOrListMode.value !== approvalLayout.LIST">
                            reorder
                        </mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </ng-container>

        <ng-container *ngIf="!loading && allApprovals.length > 0">
            <spurado-approval-card
                [approvals]="allApprovals"
                [cardOrList]="layoutCardOrListMode.value"
                (approveLine)="approveLine($event)"
                (goToDetails)="goToTimesheet($event)"
                (generateFilter)="generateFilter($event)"
            >
            </spurado-approval-card>
        </ng-container>

        <ng-template [ngIf]="loading">
            <div class="loader-content">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>

    <ng-container *ngIf="!loading && allApprovals.length === 0">
        <div class="spurado-no-action-panel">
            <h1>{{'timesheet.approving.nothing_to_do' | translate}}</h1>
            <mat-icon color="primary" class="material-icons-outlined">thumb_up_alt</mat-icon>
        </div>
    </ng-container>
</div>

