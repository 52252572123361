import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackbarComponent} from '../../component/security/snackbar/error/error-snackbar.component';
import {Criticity} from '../../spurado-extended';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    constructor(private snackBar: MatSnackBar,
                private translateService: TranslateService) {
    }

    public openGenericSnackBar(messageKey: string, criticity: Criticity, interpolateParams?: any) {
        this.translateService.get(messageKey, interpolateParams).subscribe(
            translation => {
                this.snackBar.open(translation, null, {
                    duration: criticity === Criticity.INFO ? 1500 : 5000,
                    panelClass: criticity,
                    horizontalPosition: 'end'
                });
            }
        );
    }

    public openSnackBarWithAction(messageKey: string, criticity: Criticity, actionButtonNameKey: string, action: () => void, interpolateParams?: any) {
        this.translateService.get([messageKey, actionButtonNameKey], interpolateParams).subscribe(
            translation => {
                this.snackBar
                .open(translation[messageKey], translation[actionButtonNameKey], {
                    duration: 0,
                    panelClass: criticity,
                })
                .onAction()
                .subscribe(() => {
                        action();
                    }
                );
            });
    }

    public openErrorSnackBar(error?) {
        let message = 'GENERIC_FAILURE';
        if (error && error.error !== undefined && error.error.message !== undefined) {
            // VNU: Pour le traduire, il faut juste que message=DATA_UPDATED_IN_OTHER_SESSION et prévoir, dans le fichier fr.json, une clef errors.code.DATA_UPDATED_IN_OTHER_SESSION qui dit ce que c'est !
            message = error.error.message;
        }
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            data: {message},
            duration: 7500,
            panelClass: Criticity.ERROR
        });
    }
}
