import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {Download} from '../../service/download/download';
import {DownloadService} from '../../service/download/download-service.service';

@Component({
    selector: 'spurado-download-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['./download-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadDialogComponent implements OnInit {

    download$: Observable<Download>;

    constructor(public dialogRef: MatDialogRef<DownloadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: { url, filename },
                private downloadService: DownloadService) {
    }

    ngOnInit(): void {
        this.download$ = this.downloadService.download(this.data.url, this.data.filename);
    }

    close() {
        this.dialogRef.close();
    }
}
