<mat-dialog-content *ngIf="!(data.userGroup)">
    <mat-form-field>
        <mat-label>{{'users.groups.name' | translate}}</mat-label>
        <input [formControl]="groupName"
               (keyup.enter)="groupName.valid && createGroup()"
               name="groupName"
               type="text"
               matInput/>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!(data.userGroup)">
    <button mat-flat-button color="primary" [disabled]="!groupName.valid"
            [mat-dialog-close]="groupName.value">{{'common.actions.add' | translate}}</button>
    <button mat-flat-button color="warn" (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>

<mat-dialog-content style="display: flex; flex-direction: column; height: 100%;" *ngIf="data.userGroup">
    <div>
        <h4 *ngIf="data.userGroup.systemGroup">{{'users.groups.system.' + data.userGroup.name | translate}}</h4>
        <h4 *ngIf="!data.userGroup.systemGroup">{{data.userGroup.name}}</h4>
    </div>

    <mat-form-field style="width: 75%;">
        <input type="text"
               matInput
               placeholder="{{'users.groups.dialog.membership.filter' | translate}}"
               [formControl]="filterFormControl">
    </mat-form-field>

    <div>
        <div>{{'users.groups.members' | translate}} :</div>
        <mat-list class="container spurado-scroller">
            <ng-container *ngIf="allAccounts && allAccounts.length > 0; else noMembership">
                <ng-container *ngIf="filteredAccounts && (filteredAccounts | async).length > 0; else noMatchFound">
                    <mat-list-item *ngFor="let element of filteredAccounts | async">
                        <div class="list-item-element">
                            <div *ngIf="!this.readonly || element.member">
                                <span *ngIf="element.account.user.displayName">{{ element.account.user.displayName}}&nbsp;(</span>
                                <span>{{element.account.user.email}}</span>
                                <span *ngIf="element.account.user.displayName">)</span>
                            </div>
                            <div>
                                <mat-checkbox color="primary"
                                              *ngIf="!this.readonly"
                                              [checked]="element.member"
                                              (change)="updateMembership(element, $event.checked)"
                                              [disabled]="isAccountRemovalForbidden(element.account)">
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
                <ng-template #noMatchFound>
                    <div class="empty-msg">
                        {{'users.groups.dialog.membership.no_match_found' | translate}}
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #noMembership>
                <div class="empty-msg">
                    {{'users.groups.dialog.membership.no_membership' | translate}}
                </div>
            </ng-template>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.userGroup">
    <button mat-flat-button
            color="primary"
            cdkFocusInitial
            [mat-dialog-close]="undefined">{{'common.actions.close' | translate}}</button>
</mat-dialog-actions>
