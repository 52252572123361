<h1 mat-dialog-title >{{'timesheet.comment.title' | translate}}</h1>
<mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Commentaire</mat-label>
        <textarea autofocus #input maxlength="500" matInput [formControl]="commentFormControl"></textarea>
        <mat-hint align="end">{{input.value?.length || 0}}/500</mat-hint>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="commentFormControl.pristine || commentFormControl.invalid" [mat-dialog-close]="commentFormControl.value">{{'common.actions.save' | translate}}</button>
    <button mat-raised-button color="warn" (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
