import {Pipe, PipeTransform} from '@angular/core';
import {UserGroup} from '../../../spurado';

@Pipe({
    name: 'sortGroupByName'
})
export class SortGroupByNamePipe implements PipeTransform {

    transform(value: Array<UserGroup>, ...args: any[]): Array<UserGroup> {
        value.sort((a: UserGroup, b: UserGroup) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });
        return value;
    }

}
