import {AccountDelegation} from '../../spurado';
import * as moment from 'moment/moment';

export class AccountDelegationData {
    uuid: string;
    delegateeDisplayName: string;
    delegateeEmail: string;
    delegatorDisplayName: string;
    delegatorEmail: string;
    validFrom: string;
    validTo: string;
    pendingApprovals?: number;
    delegatorUuid?: string;

    constructor(accountDelegation: AccountDelegation) {
        this.uuid = accountDelegation.uuid;
        this.delegateeDisplayName = accountDelegation.delegateeDisplayName;
        this.delegateeEmail = accountDelegation.delegateeEmail;
        this.delegatorDisplayName = accountDelegation.delegatorDisplayName;
        this.delegatorEmail = accountDelegation.delegatorEmail;
        this.validFrom = accountDelegation.validFrom? moment(accountDelegation.validFrom).format('DD/MM/YYYY') : undefined;
        this.validTo = accountDelegation.validTo ? moment(accountDelegation.validTo).format('DD/MM/YYYY') : undefined;
        this.pendingApprovals = accountDelegation.pendingApprovals;
        this.delegatorUuid = accountDelegation.delegatorUuid;
    }
}
