<div *ngIf="mode === actionMode.ADD; else edit">
    <h1 mat-dialog-title>{{'timesheet.day.holiday.add' | translate}}</h1>
</div>
<ng-template #edit>
    <h1 mat-dialog-title>{{'timesheet.day.holiday.edit' | translate}}</h1>
</ng-template>

<mat-dialog-content>
    <div class="notification-error encoded-hours-warning" *ngIf="shouldDisplayWarningMessage()">
        <mat-icon>warning</mat-icon>
        <div>{{'timesheet.day.holiday.edit_warning' | translate}}</div>
    </div>
    <p>{{'timesheet.day.holiday.text' | translate}}</p>

    <div class="checkbox-group">
        <mat-checkbox class="example-margin" [(ngModel)]="am" (change)="updateDayStatus()" >{{'timesheet.day.AM' | translate}}</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="pm" (change)="updateDayStatus()">{{'timesheet.day.PM' | translate}}</mat-checkbox>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" [disabled]="shouldDisableConfirmButton()" [mat-dialog-close]="personalHoliday">{{'common.actions.save' | translate}}</button>
    <button mat-flat-button color="warn" (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
