<ng-container *ngIf="!isLoading">
    <div class="week-selector-container">
        <div id="week-title">
            <span>{{'timesheet.week.title' | translate}}</span>
            <span>&nbsp;{{'timesheet.week.from' | translate}}&nbsp;{{firstDayNumber}}</span>
            <span *ngIf="!isOneDayWeek">&nbsp;{{'timesheet.week.to' | translate}}&nbsp;{{lastDayNumber}}</span>
            <span>&nbsp;{{'timesheet.months.' + (displayedTimesheet.month) | translate}}&nbsp;{{week[(displayWeekend ? 7 : 5)].date | date: 'yyyy'}}</span>
        </div>
        <div class="week-selector">
            <button mat-icon-button color="primary" [disabled]="isLoading"
                    (click)="loadPreviousWeek($event)">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="reset-week">
                <button mat-stroked-button color="primary" [disabled]="disabled"
                        (click)="loadCurrentWeek($event)">
                    {{'timesheet.week.initial' | translate}}
                </button>
            </div>
            <button mat-icon-button color="primary" [disabled]="isLoading"
                    (click)="loadNextWeek($event)">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
