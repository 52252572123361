import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'spurado-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {

    constructor(private dialogRef: MatDialogRef<ConfirmationComponent>) {
    }

    onNoClick() {
        this.dialogRef.close(false);
    }
}
