import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TimesheetService} from '../../service/timesheet/timesheet.service';
import {Account, Timesheet, TimesheetMode} from '../../spurado';
import {SnackbarService} from '../../service/snackbar/snackbar.service';
import {CommonService} from '../../service/common/common.service';
import {DatePipe} from '@angular/common';
import {AccountService} from '../../service/account/account.service';
import {BreakpointService} from '../../service/breakpoint/breakpoint.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-timesheet',
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit, OnDestroy {

    @Input()
    mode: TimesheetMode;

    private unsubscribeSubject: Subject<void>;

    loggedAccount: Account;
    timesheet: Timesheet;
    initialWeek: Timesheet;
    loadingTimesheet = true;
    displayWeekend: boolean;

    constructor(private accountService: AccountService,
                private applicationService: ApplicationService,
                private commonService: CommonService,
                private timesheetService: TimesheetService,
                private datePipe: DatePipe,
                private snackbarService: SnackbarService,
                public breakpointService: BreakpointService) {
        this.unsubscribeSubject = new Subject<void>();
        this.displayWeekend = CommonService.getBooleanValueFromStorage('displayWeekEnd');
    }

    ngOnInit() {
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
                this.getTimesheetForCurrentWeek();
            }
        });

        this.timesheetService.timesheetUnderRefresh
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(reloading => {
            this.loadingTimesheet = reloading;
        });

        this.timesheetService.refresh
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(() => this.refreshDataForSelectedWeek());
    }

    getTimesheetForCurrentWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimeSheetForCurrentWeek(this.loggedAccount.organisation.uuid).subscribe(
            response => {
                this.timesheet = this.initialWeek = response;
                this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            }
        );
    }

    getTimesheetForNextWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.nextWeek.year, this.timesheet.nextWeek.month, this.loggedAccount.uuid, this.mode, this.timesheet.nextWeek.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    getTimesheetForPrevioustWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.previousWeek.year, this.timesheet.previousWeek.month, this.loggedAccount.uuid, this.mode, this.timesheet.previousWeek.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    refreshDataForSelectedWeek() {
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.year, this.timesheet.month, this.loggedAccount.uuid, this.mode, this.timesheet.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    updateDisplayWeekend(displayWeekend: boolean) {
        localStorage.setItem('displayWeekEnd', displayWeekend.toString());
        this.displayWeekend = displayWeekend;
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
