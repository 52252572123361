<h1 mat-dialog-title>{{ 'timesheet.line.select_new' | translate }}</h1>

<ng-container *ngIf="loading">
    <div class="loader-container">
        <div class="loader-content">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-container>

<mat-dialog-content *ngIf="!loading">
    <ng-container
        *ngIf="originalTaskList && originalTaskList.length > 0; else noTask"
    >
        <div id="actions">
            <mat-form-field id="search-field">
                <mat-label>{{ 'timesheet.line.search_label' | translate }}</mat-label>
                <input matInput [formControl]="searchFieldFormControl"/>
                <button
                    *ngIf="searchFieldFormControl.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchFieldFormControl.reset()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div id="action-buttons">
                <button
                    mat-icon-button
                    color="accent"
                    (click)="createEntriesFromTemplate()"
                    [disabled]="numberOfFavoriteTasks === 0"
                    *ngIf="
                        data.timesheet.enableCopyTasksFromPreviousWeek &&
                        numberOfFavoriteTasks !== 0
                    "
                >
                    <mat-icon
                        matTooltip="{{ 'timesheet.modal.task.actions.select_favorites' | translate }}"
                    >
                        {{ favoritesSelected ? 'star' : 'star_border' }}
                    </mat-icon>
                </button>
                <button
                    mat-icon-button
                    color="accent"
                    (click)="deselectAll()"
                    [disabled]="selectedTasks.size === 0"
                >
                    <mat-icon
                        matTooltip="{{ 'timesheet.modal.task.actions.clear_selection' | translate }}"
                    >
                        clear
                    </mat-icon>
                </button>
            </div>
        </div>
        <div style="width: 100%">
            <div id="project-list" class="container">
                <ng-container
                    *ngIf="filteredTaskList?.length > 0; else noMatchFound"
                >
                    <mat-grid-list
                        class="task-tiles"
                        [cols]="gridCols"
                        rowHeight="160"
                        gutterSize="10"
                    >
                        <mat-grid-tile
                            class="task-tile"
                            [class.selected]="element.selected"
                            [class.favorite]="element.task.favorite"
                            *ngFor="let element of filteredTaskList"
                            (click)="updateSelectedItems(element)"
                        >
                            <mat-grid-tile-header class="task-tile-header">
                                <span [matTooltip]="element.task.name"
                                      [matTooltipShowDelay]="500">{{ element.task.name }}</span>
                                <mat-icon
                                    *ngIf="element.task.favorite"
                                    color="accent"
                                    [inline]="true"
                                >
                                    star
                                </mat-icon>
                            </mat-grid-tile-header>

                            <mat-grid-tile-footer class="task-tile-footer">
                                <ng-container
                                    *ngIf="
                                        element.task.code?.length > 0 &&
                                        element.task.code !== '-'
                                    "
                                >
                                    <mat-icon [inline]="true">qr_code_2</mat-icon>
                                    <span>{{ element.task.code }}</span>
                                </ng-container>
                                <ng-container *ngIf="element.task.client">
                                    <mat-icon [inline]="true">work_outlined</mat-icon>
                                    <span [matTooltip]="element.task.client.name"
                                          [matTooltipShowDelay]="500">{{ element.task.client.name }}</span>
                                </ng-container>
                            </mat-grid-tile-footer>
                        </mat-grid-tile>
                    </mat-grid-list>
                </ng-container>
                <ng-template #noMatchFound>
                    <div id="no-match-found">
                        {{ 'timesheet.modal.task.no_match' | translate }}
                    </div>
                </ng-template>
            </div>
            <div id="selected-tasks">
                <p *ngIf="timesheetMode.USER === data.mode">
                    <mat-checkbox
                        [checked]="showAllProjects"
                        (change)="updateShowAllCheckbox($event)"
                        color="primary"
                    >{{
                        'timesheet.modal.task.show_all' | translate
                        }}</mat-checkbox
                    >
                </p>
                <p
                    [innerHTML]="'timesheet.modal.task.selected' | translate : { selectedTasks: selectedTasks.size }"
                ></p>
                <spurado-chip-list [direction]="'horizontal'">
                    <spurado-chip
                        *ngFor="let task of selectedTasks"
                        [selected]="true"
                        color="{{ task.favorite ? 'accent' : 'primary' }}"
                    >
                        <div chipText>
                            {{ task.name }}
                            <ng-container *ngIf="task.client">
                                <span class="small-clients-name"
                                >&nbsp;({{ task.client.name }})</span
                                >
                            </ng-container>
                        </div>
                    </spurado-chip>
                </spurado-chip-list>
            </div>
        </div>
    </ng-container>

    <ng-template #noTask>
        <span>{{ 'timesheet.line.no_task' | translate }}</span>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions *ngIf="originalTaskList && originalTaskList.length > 0">
    <button
        mat-flat-button
        color="primary"
        [disabled]="selectedTasks.size === 0"
        [mat-dialog-close]="selectedTasks"
    >
        {{ 'common.actions.save' | translate }}
    </button>
    <button mat-flat-button color="warn" (click)="onNoClick()">
        {{ 'common.actions.cancel' | translate }}
    </button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="!originalTaskList || originalTaskList.length <= 0">
    <button mat-flat-button color="primary" (click)="onNoClick()">
        {{ 'common.actions.close' | translate }}
    </button>
</mat-dialog-actions>
