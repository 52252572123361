<div class="main-and-only-container">
    <div class="main-and-only-card">
        <mat-card>
            <mat-card-content>
                <div class="container">
                    <mat-label>{{'reporting.type.title' | translate}}</mat-label>
                    <mat-radio-group aria-labelledby="Report type"
                                     class="display-column"
                                     [(ngModel)]="selectedReportType">
                        <mat-radio-button class="radio-select"
                                          *ngFor="let reportType of reportTypes" [value]="reportType"
                                          color="primary">
                            <span matTooltip="{{'reporting.type.' + reportType | translate}}" class="short-text">
                                {{'reporting.type.' + reportType | translate}}
                            </span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <ng-container *ngIf="canFilterByDepartment">
                    <div class="container filterDtpDivision">
                        <mat-checkbox color="primary" [(ngModel)]="filterByDepartment"></mat-checkbox>
                        <mat-label>{{'reporting.filter_by_dept_division.title' | translate}}</mat-label>
                        <ng-container *ngIf="filterByDepartment">
                            <div class="department-selection" *ngFor="let department of departmentSelection">
                            <span>
                                <mat-checkbox class="division-selection"
                                              [checked]="allDivisionCompleteForDepartment(department.uuid)"
                                              [indeterminate]="someDivisionCompleteForDepartment(department.uuid)"
                                              (change)="setAllDivisionForDepartment($event.checked, department.uuid)"
                                              color="primary">
                                    {{department.value}}
                                </mat-checkbox>
                            </span>
                                <span>
                                <ul>
                                    <li *ngFor="let division of department.subElements">
                                        <mat-checkbox [(ngModel)]="division.completed" color="primary">
                                            {{division.value}}
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="container">
                    <mat-label>{{'reporting.period.title' | translate}}</mat-label>
                    <mat-radio-group aria-labelledby="Report period"
                                     class="display-column"
                                     [(ngModel)]="selectedReportPeriod">
                        <mat-radio-button class="radio-select"
                                          *ngFor="let reportPeriod of reportPeriods" [value]="reportPeriod"
                                          color="primary">
                            <span matTooltip="{{'reporting.period.' + reportPeriod | translate}}" class="short-text">
                                {{'reporting.period.' + reportPeriod | translate}}
                            </span>

                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="container">
                    <form [formGroup]="dateFormGroup">
                        <mat-form-field color="primary" appearance="fill" style="margin-right: 10px;">
                            <mat-label>{{'reporting.dates.from' | translate}}</mat-label>
                            <input matInput
                                   [matDatepicker]="dpFrom"
                                   formControlName="dateFrom"
                                   [max]="dateFormGroup.value.dateTo"
                                   (dateChange)="checkIfDatesAreOk()">
                            <mat-datepicker-toggle matSuffix [for]="dpFrom"></mat-datepicker-toggle>
                            <mat-datepicker #dpFrom></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field color="primary" appearance="fill">
                            <mat-label>{{'reporting.dates.to' | translate}}</mat-label>
                            <input matInput
                                   [matDatepicker]="dpTo"
                                   formControlName="dateTo"
                                   [min]="dateFormGroup.value.dateFrom"
                                   (dateChange)="checkIfDatesAreOk()">
                            <mat-datepicker-toggle matSuffix [for]="dpTo"></mat-datepicker-toggle>
                            <mat-datepicker #dpTo></mat-datepicker>
                        </mat-form-field>
                        <div class="notification-error" *ngIf="!datesAreOK">{{'reporting.dates.wrong' | translate}}</div>
                    </form >
                </div>

                <div class="container">
                    <mat-label>{{'reporting.statuses.title' | translate}}</mat-label>
                    <div class="display-column">
                        <ng-container *ngFor="let status of reportStatuses">
                            <mat-checkbox color="primary" checked="true" class="radio-select" (change)="updateStatus(status, $event)">
                                {{'reporting.statuses.' + status | translate}}
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </div>

                <div class="container">
                    <mat-label>{{'reporting.columns.title' | translate}}</mat-label>
                    <div class="display-column">
                        <ng-container *ngFor="let column of reportColumns">
                            <mat-checkbox color="primary" checked="true" class="radio-select" (change)="updateColumn(column, $event)">
                                {{'reporting.columns.' + column | translate}}
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </div>

                <div class="generate-container">
                    <button mat-raised-button color="primary" (click)="generateReport()" [disabled]="!datesAreOK">
                        <mat-icon style="margin-right: 10px;">article</mat-icon>
                        <span>{{'reporting.generate' | translate}}</span>
                    </button>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>
