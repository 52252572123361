<footer>
    <div class="footer-left"></div>
    <div class="footer-center" *ngIf="applicationDetails$ | async as applicationDetails">
        <div class="version">
            <span>{{ 'app.name' | translate}} - {{ 'app.version' | translate}} {{applicationDetails.version}}</span>
        </div>
        <div id="copyright">
            &copy; Konekto - {{'app.credits' | translate}}
        </div>
    </div>
    <div class="footer-right">
        <button mat-button class="color-contrast" [matMenuTriggerFor]="langMenu">
            <mat-icon>language</mat-icon>
            {{currentLang}}
        </button>
        <mat-menu #langMenu="matMenu" class="lang-menu">
            <button mat-menu-item *ngFor="let lang of availableLangs" (click)="useLang(lang)">
                <mat-icon class="material-icons-outlined"
                          [color]="lang === currentLang ? 'primary' : 'accent'">translate
                </mat-icon>
                <span>{{ 'i18n.lang.' + lang | translate}}</span>
            </button>
        </mat-menu>
    </div>
</footer>
