<div class="main-and-only-container">
    <div class="main-and-only-card">
        <mat-card>
            <mat-card-content>
                <div class="container">
                    <ng-container *ngIf="!loading else loadingPanel">
                        <mat-radio-group aria-labelledby="Report type"
                                         class="display-column"
                                         [(ngModel)]="otherAccount">
                            <mat-radio-button class="radio-select" [value]="false" color="primary">
                                {{'timesheet2pdf.type.me' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="radio-select" [value]="true" color="primary" *ngIf="otherAvailableAccounts && otherAvailableAccounts.length > 0">
                                {{'timesheet2pdf.type.other' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </div>

                <div id="selectionList" class="container" *ngIf="!loading && otherAvailableAccounts && otherAvailableAccounts.length > 0 && otherAccount">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'common.filter' | translate}}</mat-label>
                        <input matInput [(ngModel)]="accountFilter" (keyup)="applyAccountFilter()">
                    </mat-form-field>
                    <mat-radio-group aria-labelledby="Account"
                                     class="display-column"
                                     [(ngModel)]="selectedAccount">
                        <mat-radio-button *ngFor="let account of filteredOtherAvailableAccounts" class="radio-select" [value]="account" color="primary">
                            {{account.user.displayName}} ({{account.user.email}})
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="container spurado-month-report">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'reporting.monthly.generator.month_selection' | translate}}</mat-label>
                        <input matInput [matDatepicker]="dp" [formControl]="date" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event)"
                                        (monthSelected)="chosenMonthHandler($event, dp)">
                        </mat-datepicker>
                        <mat-error *ngIf="date.invalid">{{'timesheet2pdf.unavailable_for_month' | translate}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="container">
                    <ng-container *ngIf="!loading && !isWrongTypeParam && translateService.currentLang !== undefined">
                        <span>{{'timesheet2pdf.generate.intro' | translate}} :</span>
                        <ul>
                            <li>
                                <ng-container *ngIf="(otherAccount === false || !selectedAccount) else generationForOther">
                                    <span [innerHTML]="'timesheet2pdf.generate.me' | translate"></span>&nbsp;
                                </ng-container>
                                <ng-template #generationForOther>
                                    <span [innerHTML]="'timesheet2pdf.generate.other' | translate:{a: selectedAccount.user.displayName}"></span>&nbsp;
                                </ng-template>
                            </li>
                            <li>
                                <span [innerHTML]="'timesheet2pdf.generate.month' | translate:{m: readableMonth}"></span>&nbsp;
                            </li>
                            <li>
                                <span><strong>{{ 'i18n.' + translateService.currentLang + '.' + locale | translate}}</strong></span>
                                <mat-icon class="small-icon-button-in-text" color="primary" (click)="changePDFLocale()">language</mat-icon>
                            </li>
                        </ul>
                    </ng-container>
                    <div class="generate-container">
                        <button mat-raised-button color="primary" (click)="generatePDFTimesheet()" [disabled]="loading || date.invalid || isWrongTypeParam">
                            <mat-icon style="margin-right: 10px;">picture_as_pdf</mat-icon>
                            <span>{{'timesheet2pdf.generate.action' | translate}}</span>
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <!-- UTILS -->
        <div style="display: none;">
            <div #primary class="primary-color"></div>
            <div #secondary class="accent-color"></div>
        </div>
    </div>
</div>

<ng-template #loadingPanel>
    <div style="display: flex; justify-content: center; width: 100%;">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
