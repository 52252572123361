import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'spurado-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {

    @Input()
    removable: boolean;
    @Input()
    ripples: boolean;
    @Input()
    selected: boolean;
    @Input()
    color: ThemePalette;
    @Input()
    progress: '50-start' | '50-end' | '100';
    @Output()
    remove: EventEmitter<any>;

    constructor() {
        this.removable = false;
        this.ripples = false;
        this.selected = false;
        this.remove = new EventEmitter<any>();
        this.color = 'primary';
    }

    action(event: Event) {
        event.stopPropagation();
        this.remove.emit();
    }

}
