import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import localeBe from '@angular/common/locales/fr-BE';
import localeNl from '@angular/common/locales/nl-BE';
import localeBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlExtra from '@angular/common/locales/extra/nl-BE';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {HomeComponent} from './component/home/home.component';
import {HeaderComponent} from './component/header/header.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {ErrorSnackbarComponent} from './component/security/snackbar/error/error-snackbar.component';
import {OopsComponent} from './component/oops/oops.component';
import {FooterComponent} from './component/footer/footer.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {environment} from '../environments/environment';
import {SpuradoPaginatorIntlService} from './service/i18n/spurado-paginator-intl.service';
import {ImpersonateComponent} from './component/impersonate/impersonate.component';
import {RejectionDialogComponent} from './component/timesheet-approbation/dialog/rejection-dialog/rejection-dialog.component';
import {RouterComponent} from './component/router/router.component';
import {ImpersonalOAuthService} from './service/impersonal-oauth/impersonal-oauth.service';
import {authConfig} from './oidc/auth.config';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {getSaver, SAVER} from './service/download/saver.provider';
import {DATE_DAY_FORMAT} from './spurado-extended';
import {MatButtonModule} from '@angular/material/button';
import {SpuradoCommonModule} from './component/common/spurado-common.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AppInitService} from './strategy/app-init-service.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {OrganisationValidatorDirective} from './component/organisation/organisation-validator';
import {MatDatepickerModule} from '@angular/material/datepicker';

registerLocaleData(localeBe, 'fr', localeBeExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

const excludedPaths = ['commons/version', authConfig.issuer.match(/https?:\/\/[^\/]*\//g)[0]];

export function customUrlValidation(url: string): boolean {
    for (const path of excludedPaths) {
        if (url.indexOf(path) !== -1) {
            return false; // We don't need the token on this call
        }
    }
    return true; // We need the token on this call
}

export class DynamicLocaleId extends String {
    locale = 'fr-BE';

    toString() {
        return this.locale;
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    if (environment.i18nUrl && environment.i18nUrl !== '') {
        return new TranslateHttpLoader(http, environment.i18nUrl, '.json?noCache=' + new Date().getTime());
    } else {
        return new TranslateHttpLoader(http);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        ImpersonateComponent,
        RejectionDialogComponent,
        RouterComponent,
        HomeComponent,
        HeaderComponent,
        ErrorSnackbarComponent,
        OopsComponent,
        OrganisationValidatorDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        MatIconModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['/api/', 'http://localhost:8080/api/'],
                sendAccessToken: true,
                customUrlValidation
            }
        }),
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,

            },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatMenuModule,
        MatBottomSheetModule,
        MatDividerModule,
        MatBadgeModule,
        MatTooltipModule,
        SpuradoCommonModule,
        MatDialogModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatCardModule,
        MatDatepickerModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [AppInitService]
        },
        DatePipe,
        {provide: LOCALE_ID, useClass: DynamicLocaleId},
        {provide: MatPaginatorIntl, useClass: SpuradoPaginatorIntlService},
        {provide: OAuthService, useClass: ImpersonalOAuthService},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [
                MAT_DATE_LOCALE,
                MAT_MOMENT_DATE_ADAPTER_OPTIONS
            ]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_DAY_FORMAT},
        {provide: SAVER, useFactory: getSaver}
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}

export function initializeApp(appInitService: AppInitService): () => Promise<void> {
    return () => appInitService.init();
}
