import {Injectable} from '@angular/core';
import {LineStatus} from '../../../spurado';

@Injectable({
    providedIn: 'root'
})
export class TimesheetCommonService {

    constructor() {
    }

    isPendingApproval(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.PENDING_APPROVAL:
                return true;
            case LineStatus.OPEN:
            case LineStatus.VALIDATED:
                return false;
        }
    }

    isValidated(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.VALIDATED:
                return true;
            case LineStatus.OPEN:
            case LineStatus.PENDING_APPROVAL:
                return false;
        }
    }
}
