<div class="spurado-table-header">
    <div class="spurado-action-header">
        <mat-form-field>
            <mat-label>{{'smart_table.filter' | translate}}</mat-label>
            <input matInput [formControl]="filter">
        </mat-form-field>
        <mat-slide-toggle
            color="primary"
            [checked]="false"
            [disabled]="false"
            [labelPosition]="'before'"
            (toggleChange)="toggleChange.emit()">
        </mat-slide-toggle>
        <button mat-mini-fab
                color="primary"
                [disabled]="false"
                (click)="add.emit()">
            <mat-icon style="vertical-align: 0;">add</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="accountDelegations.length; else noDelegation" class="tiles-container">
    <mat-card class="tile" [class.inactive]="!isActive(accountDelegation)"
         *ngFor="let accountDelegation of (accountDelegations | delegationPipe:(filter.value))">
        <header>
            <p class="delegatee-name">{{accountDelegation.delegateeDisplayName}}</p>
            <p class="delegatee-email">{{accountDelegation.delegateeEmail}}</p>
        </header>

        <div>
            <p>
                <span>{{'account.delegation.valid_from' | translate}} : </span>
                <span class="date">{{accountDelegation.validFrom | date}}</span>
            </p>
            <p>
                <span>{{'account.delegation.valid_to' | translate}} : </span>
                <span class="date">{{accountDelegation.validTo | date}}</span>
            </p>

            <footer>
                <mat-button-toggle (click)="update.emit(accountDelegation)">
                    <mat-icon color="primary">
                        edit
                    </mat-icon>
                </mat-button-toggle>

                <mat-button-toggle (click)="delete.emit(accountDelegation)">
                    <mat-icon color="warn">
                        delete
                    </mat-icon>
                </mat-button-toggle>
            </footer>
        </div>
    </mat-card>
</div>

<ng-template #noDelegation>
    <p class="no-delegation">{{'account.delegation.list_empty' | translate}}</p>
</ng-template>
