<h1 mat-dialog-title>{{ 'users.dialog.membership.title' | translate }}</h1>
<mat-dialog-content>
    <mat-form-field style="width:100%">
        <input type="text" matInput placeholder="{{ 'users.dialog.membership.filter.placeholder' | translate }}"
               [matAutocomplete]="autocomplete" [formControl]="autocompleteFormControl">
        <mat-autocomplete #autocomplete=matAutocomplete (optionSelected)="addMembership($event.option.value)">
            <mat-option *ngFor="let group of filteredGroups" [value]="group">
                <ng-container
                    *ngIf="group.systemGroup; else regularGroup">{{ 'users.groups.system.' + group.name | translate }}</ng-container>
                <ng-template #regularGroup>{{ group.name | translate }}</ng-template>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div id="information-text" class="mat-body-1"
         [innerHTML]="'users.dialog.membership.information.text' | translate: {count: data.account.groups.length, username: data.account.user.displayName}">
    </div>
    <div class="container mat-elevation-z1" *ngIf="currentMembership.length > 0">
        <spurado-chip-list [direction]="'horizontal'">
            <spurado-chip *ngFor="let group of currentMembership"
                          color="{{group.editable ? (group.systemGroup ? 'accent' : 'primary') : 'warn'}}"
                          [removable]="true"
                          [selected]="true">
                <div chipText>
                    <ng-container
                        *ngIf="group.systemGroup; else regularGroup">{{ 'users.groups.system.' + group.name | translate }}</ng-container>
                    <ng-template #regularGroup>{{ group.name | translate }}</ng-template>
                </div>
                <mat-icon *ngIf="group.editable" [inline]="true" (click)="deleteGroup(group)">delete</mat-icon>
            </spurado-chip>
        </spurado-chip-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button cdkFocusInitial color="primary"
            (click)="onNoClick()">{{ 'common.actions.close' | translate }}</button>
</mat-dialog-actions>
