<div id="page-container" *ngIf="!conditionsAndCookiesAccepted || ((applicationDetails && loggedUser) | async) else loading">
    <section id="cookie" *ngIf="!conditionsAndCookiesAccepted">
        <div class="cookie-consent">
            <p><img src="assets/images/icon/cookie.svg" alt="Cookie" class="cookie"/></p>
            <p><span>En poursuivant votre navigation sur ce site, vous acceptez nos <a
                href="https://spurado.be/legal/conditions-generales" target="_blank">conditions générales</a> d'utilisation.</span>
            </p>
            <p><span>Par ailleurs, vous acceptez l’utilisation de cookies ou autres traceurs pour réaliser des statistiques de visites.</span>
            </p>
            <p>
                <button mat-raised-button color="accent" (click)="closeCookieConsentPanel()">
                    <mat-icon style="margin-right: 10px;">done</mat-icon>
                    <span>{{'common.actions.accept' | translate}}</span>
                </button>
                &nbsp;
                <a href="https://spurado.be" target="_self">
                    <button mat-raised-button color="accent">
                        <mat-icon style="margin-right: 10px;">clear</mat-icon>
                        <span>{{'common.actions.refuse' | translate}}</span>
                    </button>
                </a>
            </p>
        </div>
    </section>
    <section id="header" *ngIf="!(!!(applicationDetails | async)?.uniqueOrganisationUuid) || (loggedAccount | async)">
        <spurado-header class="mat-elevation-z6"></spurado-header>
    </section>
    <div id="content-wrap" *ngIf="conditionsAndCookiesAccepted">
        <section style="height: 100%;">
            <router-outlet></router-outlet>
        </section>
        <spurado-message-info *ngIf="(breakpointService.shouldDisplayDesktopLayout() | async)"></spurado-message-info>
    </div>
    <footer id="footer" *ngIf="!(!!(applicationDetails | async)?.uniqueOrganisationUuid) || (loggedAccount | async)">
        <spurado-footer></spurado-footer>
    </footer>
</div>
<ng-template #loading>
    <div class="full-screen-loader">
        <div class="loader-content">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-template>
