<h1 mat-dialog-title *ngIf="!readonly">{{'timesheet.comment.title' | translate}}</h1>
<mat-dialog-content>
    <mat-form-field style="width: 100%;" *ngIf="!readonly">
        <textarea autofocus #input [maxLength]="maxCommentLength" matInput [formControl]="commentFormControl"></textarea>
        <mat-label>{{'timesheet.comment.title' | translate}}</mat-label>
        <mat-hint align="end">{{input.value?.length || 0}}/{{maxCommentLength}}</mat-hint>
    </mat-form-field>
    <div class="readonly-comment-container" *ngIf="readonly">
        <ng-container *ngIf="initialComment?.length > 0; else noComment">
            <h2>
                <mat-icon color="primary" inline>speaker_notes</mat-icon>
                {{'timesheet.comment.title' | translate}}
            </h2>

            <span class="readonly-comment-text">{{initialComment}}</span>
        </ng-container>
        <ng-template #noComment>
            <h2>
                <mat-icon color="primary" inline>speaker_notes_off</mat-icon>
                {{'timesheet.comment.no_comment' | translate}}
            </h2>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" *ngIf="readonly" (click)="onNoClick()">{{'common.actions.close' | translate}}</button>
    <button mat-raised-button color="primary" *ngIf="!readonly" [disabled]="commentFormControl.pristine || commentFormControl.invalid" [mat-dialog-close]="commentFormControl.value">{{'common.actions.save' | translate}}</button>
    <button mat-raised-button color="warn" *ngIf="!readonly" (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
