<mat-dialog-content>
    <form (keyup.enter)="clientFormControl.valid && selectClient()" id="client-form">
        <mat-form-field class="full-width">
            <input type="text"
                   placeholder="{{'tasks.projects.client' | translate}}"
                   aria-label="Client selection"
                   matInput
                   required
                   [formControl]="clientFormControl"
                   [matAutocomplete]="autoclient">
            <mat-autocomplete #autoclient="matAutocomplete" [displayWith]="clientToString">
                <mat-option *ngFor="let client of filteredClients | async" [value]="client">
                    {{client.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" [disabled]="!clientFormControl.valid || clientFormControl.pristine"
            [mat-dialog-close]="getFormValue()" (click)="copyDataForReturn()">
        {{'common.actions.save' | translate}}
    </button>
    <button mat-flat-button color="primary" (click)="selectNoClient()">{{'tasks.clients.no_client' | translate}}</button>
    <button mat-flat-button color="warn" (click)="onNoClick()" cdkFocusInitial>{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
