import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Account, UserGroup} from '../../../../../../spurado';
import {UserGroupService} from '../../../../../../service/user-group/user-group.service';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {SnackbarService} from '../../../../../../service/snackbar/snackbar.service';

@Component({
    selector: 'spurado-user-membership',
    templateUrl: './user-membership.component.html',
    styleUrls: ['./user-membership.component.scss']
})
export class UserMembershipComponent implements OnInit {

    availableGroups: UserGroup[];
    currentMembership: UserGroup[];
    filteredGroups: UserGroup[];
    membershipUpdated: boolean;

    autocompleteFormControl: FormControl;

    constructor(public dialogRef: MatDialogRef<UserMembershipComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { account: Account },
                public userGroupService: UserGroupService,
                public snackbarService: SnackbarService) {
        this.autocompleteFormControl = new FormControl<string>('');
        this.membershipUpdated = false;
    }

    ngOnInit(): void {
        this.userGroupService.getAvailableGroups(this.data.account.organisation.uuid, this.data.account.uuid).subscribe(
            response => {
                this.availableGroups = response.filter(group => {
                    return group.editable;
                });
                this.autocompleteFormControl.valueChanges.pipe(
                    startWith(''),
                    map(userInput => this.filter(userInput))
                ).subscribe(filteredGroup => {
                    this.filteredGroups = filteredGroup;
                });
            }
        );
        this.currentMembership = this.data.account.groups;
    }

    addMembership(userGroup: UserGroup) {
        this.autocompleteFormControl.setValue('');
        this.userGroupService.addMemberToGroup(this.data.account.organisation.uuid, userGroup, this.data.account).subscribe({
            next: () => {
                this.currentMembership.push(userGroup);
                this.availableGroups.splice(this.availableGroups.findIndex(group => group.uuid === userGroup.uuid), 1);
                this.filteredGroups = this.availableGroups;
                this.membershipUpdated = true;
            },
            error: error => this.snackbarService.openErrorSnackBar(error)
        });
    }

    deleteGroup(userGroup: UserGroup) {
        this.userGroupService.removeMemberFromGroup(this.data.account.organisation.uuid, userGroup, this.data.account).subscribe({
            next: () => {
                this.availableGroups.push(userGroup);
                this.filteredGroups = this.availableGroups;
                this.currentMembership.splice(this.currentMembership.findIndex(group => group.uuid === userGroup.uuid), 1);
                this.membershipUpdated = true;
            },
            error: error => this.snackbarService.openErrorSnackBar(error)
        });
    }

    onNoClick() {
        this.dialogRef.close(this.membershipUpdated);
    }

    private filter(groupName: string): UserGroup[] {
        return this.availableGroups.filter(group => group.name.toLowerCase().includes(groupName));
    }
}
