<div class="account" *ngIf="loggedAccount$ | async as account">
    <mat-card class="profile">
        <mat-card-header>
            <mat-card-title class="name">{{account.user.displayName}}</mat-card-title>
            <mat-card-subtitle>{{account.user.email}}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div *ngIf="account.groups?.length">
                <p class="header">{{'account.groups.name' | translate}}</p>
                <p class="section-body value" *ngFor="let group of account.groups | sortGroupByName">
                    {{group.name}}
                </p>
            </div>

            <div *ngIf="account.attributes?.length">
                <p class="header">{{'account.attributes.name' | translate}}</p>
                <p class="section-body" *ngFor="let attribute of account.attributes | sortAttributesByKey" >
                    <span>{{'account.attributes.enum.' + attribute.key | translate}} : </span>
                    <span class="value">{{attribute.value}}</span>
                </p>
            </div>
        </mat-card-content>
    </mat-card>
    <spurado-delegation *ngIf="account.organisation.configuration.enableDelegation.value" class="delegation-container"></spurado-delegation>
</div>
