export  class DateUtils {
    private today= new Date();

    isTodayOrBefore(date: Date): boolean{
        return this.today.getFullYear() >= date.getFullYear() &&
            this.today.getMonth() >= date.getMonth() && this.today.getDate() >= date.getDate();
    }

    isTodayOrAfter(date: Date): boolean{
        return this.today.getFullYear() <= date.getFullYear() &&
            this.today.getMonth() <= date.getMonth() && this.today.getDate() <= date.getDate();
    }
}
