import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonService} from '../../../service/common/common.service';
import {Account, InfoMessage} from '../../../spurado';
import {AccountService} from '../../../service/account/account.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {BottomSheetMessageComponent} from './bottom-sheet-message/bottom-sheet-message.component';
import {ApplicationService} from '../../../service/application/application.service';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {StoreActionType} from '../../../app.store';

@Component({
    selector: 'spurado-message-info',
    templateUrl: './message-info.component.html',
    styleUrls: ['./message-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInfoComponent {
    
    messages$ = this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
    .pipe(switchMap(account => account ? this.commonService.getMessageInfos(account.organisation.uuid) : of([])));


    constructor(private applicationService: ApplicationService,
                private accountService: AccountService,
                private commonService: CommonService,
                private bottomSheet: MatBottomSheet) {
    }

    openBottomSheet(messages: InfoMessage[]): void {
        this.bottomSheet.open(BottomSheetMessageComponent, {data: {messages}});
    }
}
