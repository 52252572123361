import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Configuration, WeekSummary} from '../../../spurado';

@Component({
    selector: 'spurado-timesheet-summary-dialog',
    templateUrl: './timesheet-summary-dialog.component.html',
    styleUrls: ['./timesheet-summary-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetSummaryDialogComponent {

    totalInHours: number = 0;
    totalInDays: number = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { summaries: WeekSummary[], configuration: Configuration },
        private dialogRef: MatDialogRef<TimesheetSummaryDialogComponent>
    ) {
        if (this.data?.summaries?.length > 0) {
            this.totalInHours = this.data.summaries.reduce((sum, current) => sum + current.weekSummary, 0);
            this.totalInDays = this.getDays(this.totalInHours);
        }
    }

    getDays(hours: number) {
        return hours / this.data.configuration.workingHoursPerDay.value;
    }

    onNoClick() {
        this.dialogRef.close(false);
    }

    get displayHours(): boolean {
        return !this.displayDays;
    }

    get displayDays(): boolean {
        return this.data.configuration.dailyPointing.value;
    }
}
