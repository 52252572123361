<h1 mat-dialog-title>{{'account.delegation.title' | translate}}</h1>

<mat-dialog-content>
    <form [formGroup]="formGroup">
        <mat-form-field>
            <input type="email"
                   placeholder="{{'account.delegation.search_label' | translate}}"
                   matInput
                   formControlName="delegatee"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="accountToString">
                <mat-option *ngFor="let account of (accounts$ | async) ;" [value]="account">
                    {{account.displayName}} ({{account.email}})
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'account.delegation.validity' | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [min]="today">
                <input matStartDate placeholder="{{'account.delegation.valid_from' | translate}}"
                       formControlName="validFrom">
                <input matEndDate placeholder="{{'account.delegation.valid_to' | translate}}"
                       formControlName="validTo">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary"
            [disabled]="isFormEmptyOrInvalid()"
            [mat-dialog-close]="formValue">{{'account.delegation.delegate' | translate}}</button>
    <button mat-raised-button color="warn"
            (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
