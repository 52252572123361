<h1 mat-dialog-title>{{'impersonate.target' | translate}}</h1>
<mat-dialog-content>
    <div class="impersonate-container">
        <mat-form-field style="width: 100%">
            <input type="email"
                   placeholder="{{'common.actions.search' | translate}}"
                   aria-label="User to impersonate"
                   matInput
                   [formControl]="impersonateFormControl"
                   (keyup.enter)="impersonateFormControl.valid"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="accountToString">
                <mat-option *ngFor="let account of (accounts$ | async)" [value]="account">
                    {{account.user.displayName}} ({{account.user.email}})
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary"
            [disabled]="impersonateFormControl.pristine || impersonateFormControl.invalid"
            [mat-dialog-close]="impersonateFormControl.value">{{'common.actions.login' | translate}}</button>
    <button mat-raised-button color="warn"
            (click)="onNoClick()">{{'common.actions.cancel' | translate}}</button>
</mat-dialog-actions>
