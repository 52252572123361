import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {OrganisationService} from '../../service/organisation/organisation.service';
import {Directive} from '@angular/core';
import {catchError, map} from 'rxjs/operators';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[organisationExists] [formControlName], [organisationExists] [formControl], [organisationExists] [ngModel]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: OrganisationValidatorDirective,
        multi: true
    }]

})
export class OrganisationValidatorDirective implements AsyncValidator {

    constructor(public organisationService: OrganisationService) {
    }

    public static stripAccents(value: string): string {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    registerOnValidatorChange(fn: () => void): void {
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;
    validate(control: AbstractControl): ValidationErrors | null;
    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> | ValidationErrors | null {
        const newOrgUuid = OrganisationValidatorDirective.stripAccents(control.value).toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return this.organisationService.isUuidAvailable(newOrgUuid).pipe(
            map(isAvailable => (isAvailable ? null : {uniqueName: true})),
            catchError(() => of(null)));
    }
}

export function organisationNameValidator(organisationService: OrganisationService) {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        const newOrgUuid = OrganisationValidatorDirective.stripAccents(control.value).toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return organisationService.isUuidAvailable(newOrgUuid).pipe(map(isAvailable => {
            return isAvailable ? null : {available: false};
        }));
    };
}
