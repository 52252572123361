<div [spuradoFocus]="focusEventEmitter" id="timesheet-desktop-table" class="timesheet-table">
    <ng-container *ngIf="days">
        <div class="table-row">
            <div class="first-column-header">
                <div>{{'tasks.title' | translate}}</div>
            </div>
            <div class="content-header" [ngClass]="'table-content-' + days.length">
                <ng-container *ngFor="let day of days;index as index">
                    <div style="position: relative;" class="text"
                         [ngClass]="holidayEncodingAllowed ? 'editable' : ''"
                         (click)="(!day.personalHoliday && days[index].editable && openHolidayModal(day, action.ADD))">
                        <ng-container
                            *ngIf="breakpointService.minWidth1300Layout() | async; else fullDate">
                            <div
                                class="day-name">{{ day.date | date: 'EEEE': '' : translateService.currentLang }}</div>
                        </ng-container>
                        <ng-template #fullDate>
                            <div
                                class="day-name">{{ day.date | date: 'E': '' : translateService.currentLang }}</div>
                        </ng-template>
                        <div class="day-date">{{ day.date | date: 'dd/MM' }}</div>
                        <ng-container *ngIf="!(day.legalHoliday === false && !day.personalHoliday)">
                            <div style="width: 100%;"
                                 *ngIf="day.personalHoliday && day.personalHoliday.uuid">
                                <spurado-chip color="accent"
                                              [removable]="days[index].editable && holidayEncodingAllowed"
                                              [selected]="!days[index].editable"
                                              [ripples]="holidayEncodingAllowed"
                                              [progress]="day.personalHoliday.duration === 'FD' ? '100' : day.personalHoliday.duration === 'AM' ? '50-start' : '50-end'"
                                              (click)="holidayEncodingAllowed && days[index].editable && openHolidayModal(day, action.EDIT)"
                                              (remove)="remove(day.personalHoliday)">
                                    <div
                                        chipText>{{'timesheet.day.holiday.personal' | translate}}</div>
                                </spurado-chip>
                            </div>
                            <div style="width: 100%" *ngIf="day.legalHoliday">
                                <spurado-chip color="accent"
                                              [removable]="false"
                                              [selected]="true">
                                    <div
                                        chipText>{{'timesheet.day.holiday.legal' | translate}}</div>
                                </spurado-chip>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="index === days.length-1 && loggedAccount.organisation.configuration.enableWorkDuringWeekend">
                            <div class="toggle-weekend-icon">
                                <mat-icon
                                    style="height:100%;width:2.5rem;height:2.5rem;font-size:2.5rem; line-height:2.5rem;cursor:pointer;"
                                    (click)="toggleWeekend()">{{weekEndVisible ? "arrow_left"
                                    : "arrow_right"}}</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="spurado-action-header">{{'timesheet.line.actions.name' | translate}}</div>
        </div>
    </ng-container>
    <ng-container
        *ngIf="timesheet!==undefined && timesheet.timesheetLines && timesheet.timesheetLines.length !== 0">
        <ng-container *ngFor="let line of timesheet.timesheetLines">
            <div [ngClass]="getClassesForLine(line)" style="flex: 1;margin: 0 auto;width:90%;">
                <div class="first-column-value">
                    <div>
                        <span matTooltip="{{line.task.name}}"
                              style="cursor: context-menu;">{{line.task.name}}</span>
                        <ng-container
                            *ngIf="line.task.code && line.task.code.length > 0 && line.task.code !== '-'">
                            <div class="task-code">
                                <mat-icon [inline]="true">qr_code_2</mat-icon>
                                <span>{{line.task.code}}</span>
                            </div>
                        </ng-container>
                        <div class="client">
                            <mat-icon [inline]="true">work_outlined</mat-icon>
                            <ng-container
                                *ngIf="(timesheetService.actionButtonAvailable(line, lineAction.EDIT_CLIENT) | async) else showClient">
                                <span class="assign-client" (click)="openClientModal(line)">
                                    <ng-container
                                        *ngIf="(line.client && line.client.name) else noClient">{{line.client.name}}</ng-container>
                                    <ng-template
                                        #noClient>{{'tasks.clients.choose_client' | translate}}</ng-template>
                                </span>
                            </ng-container>
                            <ng-template #showClient>
                                <span>
                                    <ng-container
                                        *ngIf="line.client else noClient">{{line.client.name}}</ng-container>
                                    <ng-template
                                        #noClient>{{'tasks.clients.not_defined' | translate}}</ng-template>
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div>
                        <span *ngIf="isPendingApproval(line.status)" class="tooltip-with-approbator"
                              [matTooltip]="getLastApprobatorOfLine(line)">
                            <mat-icon color="primary">find_in_page</mat-icon>
                            <span
                                class="status-helper">{{'timesheet.line.statuses.pending_approval' | translate}}</span>
                        </span>
                        <span *ngIf="isValidated(line.status)">
                            <mat-icon color="primary">check_circle</mat-icon>
                            <span
                                class="status-helper">{{'timesheet.line.statuses.validated' | translate}}</span>
                        </span>
                    </div>
                </div>
                <div class="day-value" [ngClass]="'table-content-' + days.length">
                    <ng-container *ngFor="let day of line.timesheetDays, let index = index">
                        <div class="text" [ngClass]="getCellClassForDay(line, day)"
                             *ngIf="index < (weekEndVisible ? 7 : 5)">
                            <ng-container
                                *ngIf="!loggedAccount.organisation.configuration.dailyPointing.value else dailyPointing">
                                <mat-form-field
                                    class="full-width override-input-text-width transparent"
                                    *ngIf="timesheet.week[day.number].editable">
                                    <mat-label></mat-label>
                                    <input style="text-align: center;"
                                           (change)="saveActual(line, day, $event)"
                                           [disabled]="isActualCellDisabled(timesheet, day.number)"
                                           matInput
                                           value="{{day.actualDuration}}">
                                </mat-form-field>
                            </ng-container>
                            <ng-template #dailyPointing>
                                <spurado-timesheet-daily-pointing-btn
                                    iconStyleClass="daily-pointing-icon-desktop"
                                    [configuration]="loggedAccount.organisation.configuration"
                                    [actualDuration]="day.actualDuration"
                                    (saveActual)="saveActualFromDailyPointing(line, day, $event)"
                                    [disabled]="isActualCellDisabled(timesheet, day.number)">
                                </spurado-timesheet-daily-pointing-btn>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
                <div class="spurado-action-header">
                    <button mat-icon-button (click)="openCommentModal(line)"
                            *ngIf="(timesheetService.actionButtonAvailable(line, lineAction.VIEW_COMMENT) | async)
                                || (timesheetService.actionButtonAvailable(line, lineAction.EDIT_COMMENT) | async)">
                        <ng-container
                            *ngIf="(line.comment && line.comment.length > 0) else noComment">
                            <mat-icon color="primary">speaker_notes</mat-icon>
                        </ng-container>
                        <ng-template #noComment>
                            <mat-icon>speaker_notes_off</mat-icon>
                        </ng-template>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary"
                            aria-label="Action menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngIf="mode !== timesheetMode.APPROVAL">
                            <button mat-menu-item
                                    (click)="duplicateLine(line)"
                                    [disabled]="(timesheetService.actionButtonAvailable(line, lineAction.DUPLICATE) | async) === false">
                                <mat-icon color="primary">content_copy</mat-icon>
                                <span>{{'timesheet.line.actions.duplicate_line' | translate}}</span>
                            </button>
                            <button mat-menu-item
                                    (click)="deleteLineConfirmationModal(line)"
                                    [disabled]="(timesheetService.actionButtonAvailable(line, lineAction.DELETE) | async) === false">
                                <mat-icon color="warn">delete_forever</mat-icon>
                                <span>{{'timesheet.line.actions.delete_line' | translate}}</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="mode === timesheetMode.APPROVAL">
                            <button mat-menu-item
                                    (click)="approveOrRejectLine(timesheet.uuid, line, actionMode.APPROVE)"
                                    [disabled]="(timesheetService.actionButtonAvailable(line, lineAction.APPROVE) | async) === false">
                                <mat-icon color="primary">done_outline</mat-icon>
                                <span>{{'timesheet.line.actions.approve_line' | translate}}</span>
                            </button>
                            <button mat-menu-item
                                    (click)="approveOrRejectLine(timesheet.uuid, line, actionMode.REJECT)"
                                    [disabled]="(timesheetService.actionButtonAvailable(line, lineAction.REJECT) | async) === false">
                                <mat-icon color="warn">pan_tool</mat-icon>
                                <span>{{'timesheet.line.actions.reject_line' | translate}}</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="table-row" style="flex:1;margin:0 auto; width: 90%;">
                <div class="first-column-value"
                     *ngIf="mode === timesheetMode.USER && !loggedAccount.organisation.configuration.dailyPointing.value">
                    <div>{{'timesheet.total' | translate}}</div>
                </div>
                <div class="day-value" [ngClass]="'table-content-' + days.length"
                     *ngIf="mode === timesheetMode.USER && !loggedAccount.organisation.configuration.dailyPointing.value">
                    <ng-container
                        *ngFor="let duration of totalDurationPerDay | keyvalue: compareNumbers, let index = index">
                        <div class="text" *ngIf="index < (weekEndVisible ? 7 : 5)">
                            {{duration.value >= 0 ? duration.value : ''}}
                        </div>
                    </ng-container>
                </div>
                <div class="day-value"
                     *ngIf="mode !== timesheetMode.USER && !loggedAccount.organisation.configuration.dailyPointing">
                    &nbsp;
                </div>
                <div class="spurado-action-header"></div>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="showWarnings">
    <div class="action-buttons">
        <div style="display: inline-block; max-width: 40%;">
            <div *ngIf="forceSubmissionWithWarnings" style="margin-bottom: 10px;">
                <span>
                    {{'errors.submit.message' | translate}}<br/>
                    {{'errors.submit.question_ignore' | translate}}
                </span>
            </div>
        </div>
    </div>
</ng-container>
<ng-container
    *ngIf="timesheet !== undefined
        && (timesheet.timesheetLines && timesheet.timesheetLines.length !== 0)
        && (
        (mode === timesheetMode.ADMIN)
        ||
        (mode === timesheetMode.USER && timesheet.status === timesheetStatus.EDITABLE && totalDurationPerDay)
        )">
    <div class="action-buttons">
        <button mat-raised-button color="primary" (click)="openProjectsModal()"
                *ngIf="timesheet.enableAddMoreTask && !forceSubmissionWithWarnings">
            <mat-icon style="margin-right: 10px;">control_point</mat-icon>
            <span>{{'timesheet.actions.add_line' | translate}}</span>
        </button>
        <ng-container *ngIf="mode === timesheetMode.USER">
            <button mat-raised-button color="primary" [disabled]="isSubmitButtonDisabled()"
                    (click)="openSubmissionConfirmationModal(true)">
                <mat-icon style="margin-right: 10px;">send</mat-icon>
                <span
                    *ngIf="!forceSubmissionWithWarnings">{{'timesheet.actions.submit' | translate}}</span>
                <span
                    *ngIf="forceSubmissionWithWarnings">{{'timesheet.actions.submit_with_errors' | translate}}</span>
            </button>
        </ng-container>
    </div>
</ng-container>
<ng-container
    *ngIf="timesheet !== undefined
        && (!timesheet.timesheetLines || timesheet.timesheetLines.length === 0)
        && ((mode === timesheetMode.ADMIN) || (timesheet.status === timesheetStatus.EDITABLE && mode === timesheetMode.USER))">
    <div class="action-buttons">
        <h1 class="mat-h1" *ngIf="timesheetMode.USER === mode">
            {{'timesheet.no_data' | translate}}<br/>
            <button mat-mini-fab color="primary" (click)="addAllFavoriteTasksToTimesheet()">
                <mat-icon style="vertical-align: 0;"
                          matTooltip="{{'tasks.favorites.tooltip' | translate}}">star
                </mat-icon>
            </button>
        </h1>
        <div>
            <button mat-raised-button color="primary" (click)="openProjectsModal()"
                    *ngIf="timesheet.enableAddMoreTask">
                <mat-icon style="margin-right: 10px;">control_point</mat-icon>
                <span>{{'timesheet.actions.add_line' | translate}}</span>
            </button>
            <ng-container *ngIf="mode === timesheetMode.USER">
                <button mat-raised-button color="primary" [disabled]="isSubmitButtonDisabled()"
                        (click)="openSubmissionConfirmationModal(true)">
                    <mat-icon style="margin-right: 10px;">send</mat-icon>
                    <span>{{'timesheet.actions.submit' | translate}}</span>
                </button>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="timesheet !== undefined && mode === timesheetMode.APPROVAL">
    <div class="action-buttons">
        <div>
            <button *ngIf="loggedAccount.organisation.configuration.enableSubmitChecks.value"
                    mat-raised-button color="primary"
                    (click)="openSubmissionConfirmationModal(false)">
                <mat-icon style="margin-right: 10px;">read_more</mat-icon>
                <span>{{'timesheet.validation.title' | translate}}</span>
            </button>
        </div>
    </div>
</ng-container>
