import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'spurado-oops',
    templateUrl: './oops.component.html',
    styleUrls: ['./oops.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OopsComponent {

    constructor(private router: Router) {
    }

    home() {
        this.router.navigate(['/']);
    }

}
