import {ChangeDetectionStrategy, Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TimesheetLine, TimesheetLineAuthorization} from '../../../spurado';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'spurado-timesheet-comment',
    templateUrl: './timesheet-comment.component.html',
    styleUrls: ['./timesheet-comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetCommentComponent {
    readonly maxCommentLength = 250;
    readonly initialComment: string;
    readonly readonly: boolean;

    readonly commentFormControl: FormControl;

    @HostListener('keydown', ['$event'])
    onPureEnter(event) {
        let e = <KeyboardEvent>event;
        let t = <HTMLTextAreaElement>event.target;
        if (e && e.code === 'Enter') {
            e.preventDefault();
            if (t && (e.ctrlKey || e.altKey || e.metaKey || e.shiftKey)) {
                t.value += '\r\n';
            } else {
                this.dialogRef.close(t.value);
            }
        }
    }

    constructor(
        public dialogRef: MatDialogRef<TimesheetCommentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TimesheetLine,
    ) {
        this.initialComment = this.data.comment;
        this.readonly = this.data.allowedActions.indexOf(TimesheetLineAuthorization.EDIT_COMMENT) === -1;
        this.commentFormControl = new FormControl<String>({
            value: this.initialComment,
            disabled: this.readonly
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
