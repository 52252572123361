<ng-container *ngIf="tableDataSource && tableDataSource.data; else loading">
    <div class="spurado-table-wrapper">
        <h1 style="flex: 3;">
            {{title | translate}}
        </h1>
        <div class="spurado-table-header">
            <div class="spurado-action-header">
                <mat-form-field *ngIf="filterEnabled">
                    <mat-label>{{'smart_table.filter' | translate}}</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                </mat-form-field>
                <mat-slide-toggle
                    color="primary"
                    *ngIf="toggleEnabled"
                    [checked]="toggleChecked"
                    [disabled]="toggleDisabled"
                    [labelPosition]="'before'"
                    (toggleChange)="toggleChange.emit()"
                    [matTooltip]="toggleLabel | translate">
                </mat-slide-toggle>
                <button mat-mini-fab
                        *ngIf="addEnabled"
                        color="primary"
                        [disabled]="readonly"
                        (click)="add.emit()">
                    <mat-icon style="vertical-align: 0;">add</mat-icon>
                </button>
            </div>
        </div>

        <ng-container
            *ngIf="tableDataSource && tableDataSource.data && tableDataSource.data.length !== 0; else dataTable">
            <div class="spurado-table mat-elevation-z8">
                <table mat-table matSort #tableSort=matSort [dataSource]="tableDataSource">
                    <ng-container *ngFor="let columnProperty of columnsDefinition">
                        <ng-container *ngIf="!columnProperty.action && columnProperty.sortable; else nonSortableColumn"
                                      matColumnDef="{{columnProperty.datasourceAttribute}}">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header=""> {{columnProperty.displayName | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <ng-container *ngIf="columnProperty.interactionCallbackFn; else rawData">
                                    <button mat-button color="primary"
                                            [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                            (click)="columnProperty.interactionCallbackFn(row)"
                                            [innerHTML]="sanitizeContent(row[columnProperty.datasourceAttribute])">
                                    </button>
                                </ng-container>
                                <ng-template #rawData>
                                    <span [innerHTML]="sanitizeContent(row[columnProperty.datasourceAttribute])"></span>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-template #nonSortableColumn>
                            <ng-container matColumnDef="{{columnProperty.datasourceAttribute}}"
                                          *ngIf="columnProperty.action; else noAction">
                                <th mat-header-cell *matHeaderCellDef>{{columnProperty.displayName | translate}}</th>
                                <td mat-cell *matCellDef="let row">
                                    <button color="primary" mat-icon-button
                                            [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                            *ngIf="columnProperty.action === smartTableAction.EDIT"
                                            (click)="columnProperty.callbackFn(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button color="primary" mat-icon-button
                                            [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                            *ngIf="columnProperty.action === smartTableAction.DELETE"
                                            (click)="columnProperty.callbackFn(row)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button color="primary" mat-icon-button
                                            [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                            *ngIf="columnProperty.action === smartTableAction.APPROVE"
                                            (click)="columnProperty.callbackFn(row)">
                                        <mat-icon>thumb_up</mat-icon>
                                    </button>
                                    <button color="primary" mat-icon-button
                                            [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                            *ngIf="columnProperty.action === smartTableAction.OTHER"
                                            (click)="columnProperty.callbackFn(row)">
                                        <mat-icon>{{ columnProperty.actionIcon ?? 'question_mark' }}</mat-icon>
                                    </button>
                                    <mat-slide-toggle
                                        [disabled]="(columnProperty.actionDisabled && columnProperty.actionDisabled(row)) || readonly"
                                        color="primary"
                                        *ngIf="columnProperty.action === smartTableAction.TOGGLE"
                                        [checked]="row.active"
                                        (toggleChange)="columnProperty.callbackFn(row)">
                                    </mat-slide-toggle>
                                </td>
                            </ng-container>
                            <ng-template #noAction>
                                <ng-container matColumnDef="{{columnProperty.datasourceAttribute}}">
                                    <th mat-header-cell *matHeaderCellDef> {{columnProperty.displayName | translate}} </th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="columnProperty.interactionCallbackFn; else rawData">
                                            <button mat-button color="primary"
                                                    (click)="columnProperty.interactionCallbackFn(row)"
                                                    [innerHTML]="sanitizeContent(row[columnProperty.datasourceAttribute])">
                                            </button>
                                        </ng-container>
                                        <ng-template #rawData>
                                            <span [innerHTML]="sanitizeContent(row[columnProperty.datasourceAttribute])"></span>
                                        </ng-template>
                                    </td>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>

                <ng-container *ngIf="exportEnable else onlypaginator">
                    <table class="paginator-export-table">
                        <tr>
                            <td class="paginator-export">
                                <mat-paginator #tablePaginator=matPaginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
                            </td>
                            <td class="export">
                                <img src="assets/images/logo_microsoft_excel.svg" alt="Excel" (click)="exportToExcel()"/>
                            </td>
                        </tr>
                    </table>
                </ng-container>
                <ng-template #onlypaginator>
                    <mat-paginator #tablePaginator=matPaginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
                </ng-template>

            </div>
        </ng-container>
        <ng-template #dataTable>
            <div id="no-data">{{ 'smart_table.no_data' | translate }}</div>
        </ng-template>
    </div>
</ng-container>

<ng-template #loading>
    <div class="loader-container">
        <div class="loader-content">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-template>
