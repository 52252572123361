import {Component, OnDestroy} from '@angular/core';
import {Account, Approval, LineStatus, LineStatusUpdate, SearchApprovals, TimesheetMode} from '../../spurado';
import {ActivatedRoute, Router} from '@angular/router';
import {ApprovalService} from '../../service/approval/approval.service';
import {AccountService} from '../../service/account/account.service';
import {Subject} from 'rxjs';
import {ApprovalLayout, Criticity, FilterElement, FilterType,} from '../../spurado-extended';
import {SnackbarService} from '../../service/snackbar/snackbar.service';
import {ConfirmationComponent} from '../common/confirmation/confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';
import {takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {BreakpointService} from '../../service/breakpoint/breakpoint.service';

@Component({
    selector: 'spurado-approve',
    templateUrl: './approve.component.html',
    styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnDestroy {

    private localUnsubscribeSubject: Subject<void>;
    loading = true;

    loggedAccount: Account;

    approvalLayout = ApprovalLayout;
    layoutCardOrListMode: FormControl<ApprovalLayout>;

    approvalFilterElements: FilterElement[] = [];
    allApprovals: Approval[] = [];
    approvalsDuration = 0;
    
    filterElements: FilterElement[] = [];

    generatedFilter: FilterElement;

    constructor(private applicationService: ApplicationService,
                private accountService: AccountService,
                private approvalService: ApprovalService,
                private confirmationDialog: MatDialog,
                private router: Router,
                private snackbarService: SnackbarService,
                public route: ActivatedRoute,
                public breakPointService: BreakpointService) {


        this.localUnsubscribeSubject = new Subject<void>();

        this.layoutCardOrListMode = new FormControl(ApprovalLayout.CARD);

        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.localUnsubscribeSubject))
        .subscribe(account => {
            if (!account.organisation.configuration.enableApprob.value) {
                void this.router.navigate(['/errors/oops']);
            }
            this.loggedAccount = account;
        });

        this.breakPointService.minWidth1300Layout().subscribe(event => {
            if (!event) {
                this.layoutCardOrListMode.setValue(ApprovalLayout.CARD);
            }
        });
    }

    getFreshData(): void {
        this.allApprovals = [];
        this.loading = true;
        this.approvalService.searchPendingApprovals(this.loggedAccount.organisation.uuid, this.buildParamBasedOnCurrentFilter()).subscribe(
            (next) => {
                this.allApprovals = next;
                let totalDuration = 0;
                this.allApprovals.forEach(approval => {
                    totalDuration += approval.totalDuration;
                    if (!(approval.clientName) && !(approval.clientUuid)) {
                        approval.clientName = '-';
                    }
                    if (!(approval.programName) && !(approval.programUuid)) {
                        approval.programName = '-';
                    }
                });
                this.approvalsDuration = totalDuration;
                this.loading = false;
            },
            () => {
                console.error(`Une erreur s'est produite lors de la récupération de la liste des approvals`);
                void this.router.navigate(['/errors/oops']);
            });
    }

    goToTimesheet(approval: Approval) {
        this.router.navigate([this.loggedAccount.organisation.uuid + '/approval/timesheet'],
            {
                queryParams:
                    {
                        timesheetYear: approval.timesheetYear,
                        timesheetMonth: approval.timesheetMonth,
                        timesheetWeekNumber: approval.timesheetWeekNumber,
                        mode: TimesheetMode.APPROVAL,
                        accountUuid: approval.account.uuid,
                        highlightedLineSequence: approval.timesheetLineSequence
                    }
            });
    }

    directApproveLine(timesheetUuid: string, lineSequence: string, lineVersion: number) {
        const lineStatusUpdate = new LineStatusUpdate();
        lineStatusUpdate.version = lineVersion;
        lineStatusUpdate.status = LineStatus.VALIDATED;
        this.approvalService.approveOrRejectLine(this.loggedAccount.organisation.uuid, timesheetUuid, lineSequence, lineStatusUpdate).subscribe(
            () => {
                this.snackbarService.openGenericSnackBar('common.actions.success', Criticity.INFO);
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.PENDING_APPROVALS_COUNT,
                    argument: this.loggedAccount.organisation.uuid
                });
                this.getFreshData();
            },
            () => {
                this.snackbarService.openErrorSnackBar();
                this.loading = false;
            });
    }

    approveLine(approval: Approval) {
        this.loading = true;
        this.directApproveLine(approval.timesheetUuid, approval.timesheetLineSequence, approval.timesheetLineVersion);
    }

    groupedActionApproveLines() {
        const deletionConfirmationRef = this.confirmationDialog.open(ConfirmationComponent, {
            disableClose: true,
            autoFocus: true
        });
        deletionConfirmationRef.afterClosed().subscribe(result => {
                if (result) {
                    this.approvalService.updateStatusApproval(this.loggedAccount.organisation.uuid, LineStatus.VALIDATED, this.allApprovals).subscribe(
                        () => {
                            this.snackbarService.openGenericSnackBar('common.actions.success', Criticity.INFO);
                            this.applicationService.applicationStore.dispatch({
                                type: StoreActionType.PENDING_APPROVALS_COUNT,
                                argument: this.loggedAccount.organisation.uuid
                            });
                            this.getFreshData();
                        },
                        () => {
                            this.snackbarService.openErrorSnackBar();
                        });
                }
            }
        );
    }

    private buildParamBasedOnCurrentFilter(): SearchApprovals {
        const searchApprovals = new SearchApprovals();

        searchApprovals.accountUuids = [];
        searchApprovals.clientUuids = [];
        searchApprovals.programUuids = [];
        searchApprovals.taskUuids = [];

        this.filterElements.forEach(item => {
            switch (item.type) {
                case FilterType.ACCOUNT:
                    searchApprovals.accountUuids.push(item.uuid);
                    break;
                case FilterType.CUSTOMER:
                    searchApprovals.clientUuids.push(item.uuid);
                    break;
                case FilterType.PROGRAM:
                    searchApprovals.programUuids.push(item.uuid);
                    break;
                case FilterType.TASK:
                    searchApprovals.taskUuids.push(item.uuid);
                    break;
                case FilterType.DATE:
                    searchApprovals.dateFrom = item.from;
                    searchApprovals.dateTo = item.to;
            }
        });


        return searchApprovals;
    }


    ngOnDestroy() {
        this.localUnsubscribeSubject.next();
        this.localUnsubscribeSubject.unsubscribe();
    }

    searchBasedOn($event: FilterElement[]) {
        this.filterElements = $event;
        this.getFreshData();
    }

    generateFilter($event: FilterElement) {
        this.generatedFilter = $event;
    }
}
