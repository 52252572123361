import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@angular/common';
import {Account, Action, Timesheet, TimesheetLine, TimesheetLineAuthorization, TimesheetMode, WeekDay} from '../../../spurado';
import {Criticity} from '../../../spurado-extended';
import {TimesheetCommentComponent} from '../../timesheet/timesheet-comment/timesheet-comment.component';
import {MatDialog} from '@angular/material/dialog';
import {TimesheetService} from '../../../service/timesheet/timesheet.service';
import {SnackbarService} from '../../../service/snackbar/snackbar.service';
import {TimesheetCommonService} from '../../../service/timesheet/timesheet-common-service/timesheet-common.service';
import {Observable} from 'rxjs';
import {TimesheetSubmitConfirmComponent} from '../../timesheet/timesheet-submit-confirm/timesheet-submit-confirm.component';

@Component({
    selector: 'spurado-mobile-validation-timesheet-table',
    templateUrl: './mobile-validation-timesheet-table.component.html',
    styleUrls: ['./mobile-validation-timesheet-table.component.scss']
})
export class MobileValidationTimesheetTableComponent implements OnInit {

    @Input()
    timesheet: Timesheet;
    @Input()
    mode: TimesheetMode;
    @Input()
    loggedAccount: Account;
    @Input()
    highlightedLineSequence: string;

    @Output()
    approveOrRejectClick: EventEmitter<{ timesheetUuid: string, line: TimesheetLine, action: Action }> = new EventEmitter<{ timesheetUuid: string, line: TimesheetLine, action: Action }>();

    // Enums
    timesheetLineAuthorization = TimesheetLineAuthorization;
    actionMode = Action;

    mobileDataStructure: Map<string, Map<string, Map<WeekDay, number>>>;

    constructor(private timesheetService: TimesheetService,
                public timesheetCommonService: TimesheetCommonService,
                public commentDialog: MatDialog,
                private snackbarService: SnackbarService,
                public submissionConfirmationDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.mobileDataStructure = this.buildMobileDataStructure(this.timesheet);
    }

    // TODO-SHOULD : Gros duplicate avec BaseTimesheetTableComponent !!
    openCommentModal(timesheetLine: TimesheetLine): void {
        const dialogRef = this.commentDialog.open(TimesheetCommentComponent, {
            data: timesheetLine
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && timesheetLine.allowedActions.indexOf(TimesheetLineAuthorization.EDIT_COMMENT) !== -1) {
                this.timesheetService.updateComment(this.loggedAccount.organisation.uuid, this.timesheet.uuid, timesheetLine, this.mode, result)
                    .then(response => {
                            this.snackbarService.openGenericSnackBar('timesheet.validation.comment_saved', Criticity.INFO);
                        }, failure => {
                            this.snackbarService.openErrorSnackBar(failure);
                            //TODO : Il faut faire un refresh
                        }
                    );
            }
        });
    }

    buildMobileDataStructure(timesheet: Timesheet): Map<string, Map<string, Map<WeekDay, number>>> {
        const timesheetAndLinesMapping = new Map<string, Map<string, Map<WeekDay, number>>>();
        const linesMap = new Map<string, Map<WeekDay, number>>();

        for (const line of timesheet.timesheetLines) {
            linesMap.set(line.sequence, this.buildLineDataForValidation(timesheet, line));
        }
        timesheetAndLinesMapping.set(timesheet.uuid, linesMap);

        return timesheetAndLinesMapping;
    }

    buildLineDataForValidation(timesheet: Timesheet, timesheetLine: TimesheetLine): Map<WeekDay, number> {
        const weekDaysWithName = this.buildDaysList(timesheet);
        const weekDaysWithNameAndDuration: Map<WeekDay, number> = new Map<WeekDay, number>();

        for (const day of timesheetLine.timesheetDays) {
            if (day.number <= 5 || this.loggedAccount.organisation.configuration.enableWorkDuringWeekend.value) {
                weekDaysWithNameAndDuration.set(weekDaysWithName[day.number - 1], day.actualDuration ? day.actualDuration : 0);
            }
        }

        return weekDaysWithNameAndDuration;
    }

    buildDaysList(timesheet: Timesheet): Array<WeekDay> {
        const daysList = [];
        for (const dayNumber in timesheet.week) {
            if (+dayNumber <= 5 || this.loggedAccount.organisation.configuration.enableWorkDuringWeekend.value) {
                daysList.push(timesheet.week[dayNumber]);
            }
        }

        return daysList;
    }

    getMobileData(timesheetUuid: string, lineUuid: string): Map<WeekDay, number> {
        return this.mobileDataStructure.get(timesheetUuid).get(lineUuid);
    }

    compareKeys = (a: KeyValue<WeekDay, number>, b: KeyValue<WeekDay, number>): number => {
        return a.key.number < b.key.number ? -1 : a.key.number === b.key.number ? 0 : 1;
    }

    approveLine(timesheetUuid: string, line: TimesheetLine, action: Action): void {
        this.approveOrRejectClick.emit({timesheetUuid, line, action});
    }

    rejectLine(timesheetUuid: string, line: TimesheetLine, action: Action): void {
        this.approveOrRejectClick.emit({timesheetUuid, line, action});
    }

    actionButtonAvailable(timesheetline: TimesheetLine, authorization: TimesheetLineAuthorization): Observable<boolean> {
        return this.timesheetService.actionButtonAvailable(timesheetline, authorization);
    }

    openTimesheetValidationModal(): void {
        this.submissionConfirmationDialog.open(TimesheetSubmitConfirmComponent, {
            disableClose: true,
            autoFocus: true,
            data: {timesheet: this.timesheet, loggedAccount: this.loggedAccount, enableSubmission: false}
        });
    }

    getClassesForLine(line: TimesheetLine): string {
        if (this.highlightedLineSequence && this.highlightedLineSequence === line.sequence) {
                return 'highlightedTask';
        }
        return '';
    }
}
