import {Directive, EventEmitter, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[spuradoFocus]'
})
export class FocusDirective implements OnInit {

    @Input('spuradoFocus') focusEvent: EventEmitter<boolean>;

    constructor() {
    }

    ngOnInit() {
        this.focusEvent.subscribe(event => {
            const firstTimesheetCell = document.querySelector('#timesheet-desktop-table input:not([readonly="readonly"]):not([disabled="disabled"]):not([disabled=""]):not([disabled="true"])') as HTMLElement;
            if (firstTimesheetCell) {
                firstTimesheetCell.focus();
            }
        });
    }
}
