import {Component, OnInit} from '@angular/core';
import {Account, AccountDelegation} from '../../../spurado';
import {mergeMap, of, Subject} from 'rxjs';
import {ApplicationService} from '../../../service/application/application.service';
import {StoreActionType} from '../../../app.store';
import {catchError, filter, takeUntil, tap} from 'rxjs/operators';
import {AccountDelegationService} from '../../../service/account-delegation/account-delegation.service';
import {MatDialog} from '@angular/material/dialog';
import {DelegationDialogComponent} from './delegation-dialog/delegation-dialog.component';
import {ConfirmationComponent} from '../../common/confirmation/confirmation.component';
import {AccountDelegationData} from '../account-delegation-data';
import {SnackbarService} from '../../../service/snackbar/snackbar.service';
import {DateUtils} from "../../../utils/date-utils";

@Component({
    selector: 'spurado-delegation',
    templateUrl: './delegation.component.html',
    styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit {

    private loggedAccount: Account;
    filteredAccountDelegations: AccountDelegation[] = [];
    private accountDelegations: AccountDelegation[] = [];
    private showInactives = false;

    private unsubscribeSubject = new Subject<void>();

    constructor(
        private accountDelegationService: AccountDelegationService,
        private applicationService: ApplicationService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
    ) {
    }

    ngOnInit() {
        this.fetchData();
    }

    createDelegation() {
        this.openDialog();
    }

    toggleShowInactive() {
        this.showInactives = !this.showInactives;
        this.refreshData();
    }

    editDelegation(accountDelegationData: AccountDelegationData) {
        const accountDelegation = this.filteredAccountDelegations.find(delegation => delegation.uuid === accountDelegationData.uuid);
        this.openDialog(accountDelegation);
    }

    deleteDelegation(accountDelegationData: AccountDelegationData) {
        const accountDelegation = this.filteredAccountDelegations.find(delegation => delegation.uuid === accountDelegationData.uuid);

        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            autoFocus: true
        })
        .afterClosed()
        .pipe(
            filter(result => result),
            mergeMap(() => this.accountDelegationService.deleteAccountDelegation(this.loggedAccount.organisation.uuid, accountDelegation))
        ).subscribe(() => this.fetchData());
    }

    private fetchData() {
        this.applicationService.applicationStore.listen(StoreActionType.ACCOUNT).data
        .pipe(
            takeUntil(this.unsubscribeSubject),
            tap(account => this.loggedAccount = account),
            mergeMap((account: Account) => this.accountDelegationService.getDelegatees(account.organisation.uuid)),
            tap(accountDelegations => this.accountDelegations = accountDelegations),
            tap(() => this.refreshData())
        ).subscribe();
    }


    private refreshData() {
        this.filteredAccountDelegations = this.filter(this.accountDelegations)
    }

    private filter(accountDelegations: AccountDelegation[]): AccountDelegation[] {
        if (this.showInactives) {
            return accountDelegations;
        }

        const dateUtils = new DateUtils();
        return accountDelegations.filter(accountDelegation => {
            const validTo = new Date(accountDelegation.validTo);
            return dateUtils.isTodayOrAfter(validTo);
            }
        );
    }

    private openDialog(accountDelegation?: AccountDelegation) {
        this.dialog.open(DelegationDialogComponent, {
            data: {
                loggedAccount: this.loggedAccount,
                accountDelegation,
            }
        })
        .afterClosed()
        .pipe(
            filter(delegation => !!delegation),
            mergeMap(delegation => {
                if (!accountDelegation) {
                    return this.accountDelegationService.createAccountDelegation(this.loggedAccount.organisation.uuid, delegation);
                }
                return this.accountDelegationService.updateAccountDelegation(this.loggedAccount.organisation.uuid, delegation);
            }),
            catchError(error => {
                this.snackbarService.openErrorSnackBar(error);
                return of(null);
            }),
        )
        .subscribe(() => this.fetchData());
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
