import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PersonalHoliday} from '../../spurado';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HolidayService {

    private apiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    createHoliday(orgUuid: string, holiday: PersonalHoliday): Observable<PersonalHoliday> {
        return this.httpClient.post<any>(`${this.apiUrl}${orgUuid}/holidays`, holiday);
    }

    updateHoliday(orgUuid: string, holiday: PersonalHoliday) {
        return this.httpClient.put(`${this.apiUrl}${orgUuid}/holidays/${holiday.uuid}`, holiday);
    }

    deleteHoliday(orgUuid: string, holiday: PersonalHoliday) {
        return this.httpClient.delete(`${this.apiUrl}${orgUuid}/holidays/${holiday.uuid}`);
    }
}
