export class SmartTableColumnDef {
    displayName: string;
    datasourceAttribute?: string;
    sortable? = false;
    hiddable? = false;
    action?: SmartTableAction;
    actionIcon?: string;
    actionDisabled?: (row) => boolean;
    callbackFn?: (row) => void;
    interactionCallbackFn?: (row) => void;
}

export enum SmartTableAction {
    ADD = 'ADD',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    TOGGLE = 'TOGGLE',
    APPROVE = 'APPROVE',
    OTHER = 'OTHER'
}
