<h1 *ngIf="submitChecksEnabled" mat-dialog-title>{{'timesheet.validation.title' | translate}}</h1>
<mat-dialog-content>
    <spurado-timesheet-validation *ngIf="submitChecksEnabled" [loading]="loading" [timesheetValidation]="timesheetValidation"></spurado-timesheet-validation>
    <ng-container *ngIf="enableSubmission">
        <h1 mat-dialog-title>{{'common.dialog.question_continue' | translate}}</h1>
        <mat-checkbox *ngIf="!loading && atLeastOneIssue" [checked]="continue" (change)="updateContinueCheckbox($event)">{{'timesheet.validation.confirm_continue' | translate}}</mat-checkbox>
    </ng-container>
</mat-dialog-content>
<ng-container *ngIf="enableSubmission">
    <mat-dialog-actions>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" [disabled]="atLeastOneIssue && !continue">{{'common.yes' | translate}}</button>
        <button mat-raised-button color="warn" (click)="onNoClick()">{{'common.no' | translate}}</button>
    </mat-dialog-actions>
</ng-container>
<ng-container *ngIf="!enableSubmission">
    <mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="onNoClick()">{{'common.actions.close' | translate}}</button>
    </mat-dialog-actions>
</ng-container>
