<h1 mat-dialog-title>{{'timesheet.summary.title' | translate}}</h1>
<mat-dialog-content>
    <ng-container *ngIf="(data && data.summaries && data.summaries.length > 0) else noData">
        <h4>{{'timesheet.summary.per_client' | translate}} :</h4>
        <spurado-chip-list direction="vertical">
            <spurado-chip *ngFor="let summary of data.summaries" [selected]="false" color="primary">
                <div chipText>
                    <span *ngIf="summary.client">{{summary.client}}</span>
                    <span *ngIf="!summary.client">{{'timesheet.summary.no_client' | translate}}</span>
                    <span>&nbsp;&#8594;&nbsp;</span>
                    <span *ngIf="displayHours">{{summary.weekSummary}}</span>
                    <span *ngIf="displayDays">{{getDays(summary.weekSummary)}}</span>
                    <span *ngIf="summary.pourcentageOfWeekTotal > 0">&nbsp;(=&nbsp;{{summary.pourcentageOfWeekTotal}}%)</span>
                </div>
            </spurado-chip>
        </spurado-chip-list>
        <h4>{{'timesheet.summary.total' | translate}} :</h4>
        <spurado-chip-list direction="vertical">
            <spurado-chip color="primary">
                <div chipText>
                    <span *ngIf="displayHours">{{totalInHours}}&nbsp;{{'timesheet.summary.total_hours' | translate}}</span>
                    <span *ngIf="displayDays">{{totalInDays}}&nbsp;{{'timesheet.summary.total_days' | translate}}</span>
                </div>
            </spurado-chip>
        </spurado-chip-list>
    </ng-container>
    <ng-template #noData>
        <span>{{'timesheet.summary.no_data' | translate}}</span>
    </ng-template>
</mat-dialog-content>
