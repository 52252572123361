<div style="display: flex">
    <div style="flex: 1" class="week-sum-container-inversed">&nbsp;</div>
    <div style="flex: 10;" class="timesheet-status">
        <span *ngIf="timesheet.status === timesheetStatus.EDITABLE && !enableRecall" class="editable status-no-action">
            {{'timesheet.statuses.editable.value' | translate}}
        </span>
        <span *ngIf="timesheet.status === timesheetStatus.EDITABLE && enableRecall" class="editable status-clickable" (click)="proposeRecall()">
            {{'timesheet.statuses.editable.value' | translate}}
        </span>

        <span *ngIf="timesheet.status === timesheetStatus.PRE_FINAL && !enableRecall" class="pre-final status-no-action">
            {{'timesheet.statuses.pre_final.value' | translate}}
        </span>
        <span *ngIf="timesheet.status === timesheetStatus.PRE_FINAL && enableRecall" class="pre-final status-clickable" (click)="proposeRecall()">
            {{'timesheet.statuses.pre_final.value' | translate}}
        </span>

        <span *ngIf="timesheet.status === timesheetStatus.FINAL && !enableRecall" class="final status-no-action">
            {{'timesheet.statuses.final.value' | translate}}
        </span>
        <span *ngIf="timesheet.status === timesheetStatus.FINAL && enableRecall" class="final status-clickable" (click)="proposeRecall()">
            {{'timesheet.statuses.final.value' | translate}}
        </span>

        <span *ngIf="enableRecall && initiateRecall">=> {{'timesheet.statuses.pre_final.recall' | translate}}</span>
        <span *ngIf="enableRecall && initiateRecall" class="editable status-clickable" (click)="recall()">{{'timesheet.statuses.editable.value' | translate}}</span>
        <span *ngIf="enableRecall && initiateRecall">?</span>
    </div>
    <div style="flex: 1;" class="week-sum-container">
        <ng-container *ngIf="loggedAccount.organisation.uuid !== 'icity' && timesheetMode.USER === mode">
            <button mat-stroked-button color="primary" (click)="askSummary()">
                <mat-icon>functions</mat-icon>
            </button>
        </ng-container>
    </div>
</div>
