import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthInfoEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class ImpersonalOAuthService extends OAuthService {

    private isImpersonated = false;

    public impersonate(jwt: string) {
        this.isImpersonated = true;
        this._storage.setItem('access_token', jwt);
    }

    public finishImpersonation(): Promise<TokenResponse> {
        this.isImpersonated = false;
        return this.refreshToken();
    }

    protected refreshInternal(
        params,
        noPrompt
    ): Promise<TokenResponse | OAuthEvent> {
        if (this.isImpersonated) {
            return new Promise<OAuthEvent>(
                resolve => resolve(new OAuthInfoEvent('silent_refresh_timeout', 'skip refresh'))
            );
        } else {
            return super.refreshInternal(params, noPrompt);
        }
    }
}
