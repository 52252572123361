<ng-container *ngIf="(isUniqueOrganisation$ | async) === false">
    <div *ngIf="isValidUser()">
        <div class="organisation-panel" *ngIf="(organisations$ | async) as organisations">
            <mat-card class="organisation-container">
                <mat-card-header>
                    <mat-card-title>{{'common.welcome_name' | translate:{name: ((loggedUser$ | async)?.firstName)} }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="organisations?.length > 0; else noOrgTemplate">
                        <p>{{'organisation.choose' | translate}}</p>
                        <mat-action-list>
                            <ng-container
                                *ngFor="let organisation of organisations, index as index">
                                <button mat-list-item (click)="goToOrganisation(organisation.uuid)">
                                    {{organisation.name}}
                                </button>
                                <mat-divider *ngIf="index !== organisations.length - 1"
                                             [inset]="true"></mat-divider>
                            </ng-container>
                        </mat-action-list>
                    </div>
                    <ng-template #noOrgTemplate>
                        <p>{{'organisation.no_choice' | translate}}</p>
                    </ng-template>
                </mat-card-content>
            </mat-card>
            <mat-accordion>
                <mat-expansion-panel [expanded]="!organisations || organisations.length === 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{'organisation.create.title' | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="organisation-form">
                        <form [formGroup]="organisationFormGroup"
                              (keyup.enter)="organisationFormGroup.valid && createOrganisation()">
                            <mat-form-field>
                                <mat-label>{{'organisation.create.name' | translate}}</mat-label>
                                <input matInput formControlName="organisationName">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>{{'organisation.create.url' | translate}}</mat-label>
                                <input matInput formControlName="organisationUrl">
                            </mat-form-field>
                        </form>
                    </div>
                    <div id="add-button">
                        <button mat-flat-button color="accent" (click)="createOrganisation()"
                                [disabled]="!organisationFormGroup.valid">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</ng-container>
