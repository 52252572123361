import {Component} from '@angular/core';
import {TimesheetMode} from '../../spurado';

@Component({
    selector: 'spurado-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {

    timesheetMode = TimesheetMode;
}
