import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationDetails, User} from '../../spurado';
import {Observable, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {UserService} from '../../service/user/user.service';

@Component({
    selector: 'spurado-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {

    private unsubscribeSubject = new Subject<void>();
    applicationDetails$: Observable<ApplicationDetails> = this.applicationService.applicationStore
    .listen<ApplicationDetails>(StoreActionType.APPLICATION_DETAILS)
    .data
    .pipe(catchError(() => of({version: '?'})));

    get availableLangs(): string[] {
        return this.translateService.getLangs();
    }

    get currentLang(): string {
        return this.translateService.currentLang;
    }

    constructor(
        private applicationService: ApplicationService,
        private cdRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<Date>,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.applicationService.applicationStore.listen<User>(StoreActionType.USER).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(user => {
            if (user?.locale) {
                this.useLang(user.locale.toLowerCase());
            }
        });
    }

    useLang(lang): void {
        this.translateService.use(lang);
        this.dateAdapter.setLocale(lang + '-BE');
        this.userService.updateMyLocale(lang).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
