import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {Account, SupportedLocale, User, UserLocaleUpdate} from '../../spurado';
import {tap} from 'rxjs/operators';
import {ImpersonalOAuthService} from '../impersonal-oauth/impersonal-oauth.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {ApplicationService} from '../application/application.service';
import {StoreActionType} from '../../app.store';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private apiUrl: string = environment.apiUrl;

    constructor(private applicationService: ApplicationService,
                private httpClient: HttpClient,
                private oauthService: OAuthService) {
    }

    async logout(connectedUser?: User, orgUuid?: string, account?: Account): Promise<void> {
        if (!connectedUser || !account.impersonated) {
            return this.oauthService.logOut();
        }

        await (this.oauthService as ImpersonalOAuthService).finishImpersonation();

        this.applicationService.applicationStore.dispatch({type: StoreActionType.USER});
        this.applicationService.applicationStore.dispatch({
            type: StoreActionType.ACCOUNT,
            argument: orgUuid
        });
        this.applicationService.applicationStore.dispatch({
            type: StoreActionType.PENDING_APPROVALS_COUNT,
            argument: orgUuid
        });
        this.applicationService.applicationStore.dispatch({
            type: StoreActionType.PENDING_TASKS_COUNT,
            argument: orgUuid
        });
    }

    impersonateAccount(accountUuid: string, orgUuid: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/accounts/${accountUuid}/impersonation`,
            {responseType: 'text'}, {responseType: 'text'})
        .pipe(tap(
            jwt => {
                (this.oauthService as ImpersonalOAuthService).impersonate(jwt);
            })
        );
    }

    public isValidUser(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    updateMyLocale(locale: string): Observable<any> {
        const newLocale: SupportedLocale | undefined = (SupportedLocale as any)[locale.toUpperCase()];
        if (newLocale !== undefined) {
            const body = new UserLocaleUpdate();
            body.locale = newLocale;
            return this.httpClient.put(this.apiUrl + `users/locale`, body);
        } else {
            console.error('Argh ! Wrong input for locale \'' + locale + '\'');
            return EMPTY;
        }
    }

    refreshToken() {
        this.oauthService.silentRefresh().then(

        );
    }
}
