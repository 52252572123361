import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AccountDelegation} from "../../../../spurado";
import {FormControl} from "@angular/forms";
import {DateUtils} from "../../../../utils/date-utils";

@Component({
    selector: 'spurado-delegation-tiles',
    templateUrl: './delegation-tiles.component.html',
    styleUrls: ['./delegation-tiles.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationTilesComponent {

    @Input() accountDelegations: AccountDelegation[];
    @Output() add = new EventEmitter<boolean>(true);
    @Output() toggleChange = new EventEmitter<boolean>(true);
    @Output() delete = new EventEmitter<AccountDelegation>();
    @Output() update = new EventEmitter<AccountDelegation>();

    private dateUtils = new DateUtils();

    filter = new FormControl<string>('');

    isActive(accountDelegation: AccountDelegation): boolean {
        return this.dateUtils.isTodayOrAfter(new Date(accountDelegation.validTo));
    }
}
