import {Pipe, PipeTransform} from '@angular/core';
import {AccountAttribute} from '../../../spurado';

@Pipe({
    name: 'sortAttributesByKey'
})
export class SortAttributesByKeyPipe implements PipeTransform {

    transform(value: Array<AccountAttribute>, ...args: any[]): Array<AccountAttribute> {
        value.sort((a: AccountAttribute, b: AccountAttribute) => {
            if (a.key < b.key) {
                return -1;
            } else if (a.key > b.key) {
                return 1;
            } else {
                return 0;
            }
        });
        return value;
    }

}
