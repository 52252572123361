<section id="mobile-timesheet-table-container" *ngIf="timesheet">
    <mat-card>
        <mat-card-title>
            <div id="task-header">
                <div>{{'tasks.name_full' | translate}}</div>
                <button mat-mini-fab
                        color="primary"
                        *ngIf="timesheet.enableAddMoreTask && !forceSubmissionWithWarnings && ((mode === timesheetMode.ADMIN)
                               ||(mode === timesheetMode.USER && timesheet.status === timesheetStatus.EDITABLE))"
                        (click)="openProjectsModal()">
                    <mat-icon style="vertical-align: 0;">control_point</mat-icon>
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <mat-chip-listbox aria-label="Task selection">
                <ng-container *ngFor="let line of selectedLines">
                    <mat-chip-option
                        color="primary"
                        [matMenuTriggerFor]="menu"
                        [highlighted]="line.comment?.length > 0"
                        [selectable]="false"
                    >
                        {{ line.task.name }}
                    </mat-chip-option>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="openCommentBoxAllowed(line)"
                                (click)="openCommentBoxAllowed(line) && openCommentModal(line)">
                            <ng-container *ngIf="(line.comment && line.comment.length > 0) else noComment">
                                <mat-icon color="primary">speaker_notes</mat-icon>
                            </ng-container>
                            <ng-template #noComment>
                                <mat-icon>speaker_notes_off</mat-icon>
                            </ng-template>
                            <span>{{'timesheet.line.actions.comment_line' | translate}}</span>
                        </button>
                        <button mat-menu-item *ngIf="line.task.code && line.task.code.length > 0 && line.task.code !== '-'" [disabled]="true">
                            <mat-icon color="primary">qr_code_2</mat-icon>
                            <span>{{'tasks.code' | translate}}: {{line.task.code}}</span>
                        </button>
                        <button mat-menu-item *ngIf="openClientModalAllowed(line)"
                                (click)="openClientModalAllowed(line) && openClientModal(line)">
                            <mat-icon color="primary">work_outline</mat-icon>
                            <ng-container *ngIf="(line.client && line.client.name) else noClient">
                                <span>{{'tasks.projects.client' | translate}}: {{line.client.name}}</span>
                            </ng-container>
                            <ng-template #noClient><span>{{'tasks.clients.choose_client' | translate}}</span></ng-template>
                        </button>
                        <button mat-menu-item *ngIf="!openClientModalAllowed(line) && line.client && line.client.name"
                                style="cursor: not-allowed" [disabled]="true">
                            <mat-icon color="primary">work_outline</mat-icon>
                            <span>{{'tasks.projects.client' | translate}}: {{line.client.name}}</span>
                        </button>
                        <button mat-menu-item (click)="isLineDuplicable(line) && duplicateLine(line)"
                                *ngIf="isLineDuplicable(line)">
                            <mat-icon color="primary">content_copy</mat-icon>
                            <span>{{'timesheet.line.actions.duplicate_line' | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="isLineRemovable(line) && removeLine(line)"
                                *ngIf="isLineRemovable(line)">
                            <mat-icon color="warn">delete_forever</mat-icon>
                            <span>{{'timesheet.line.actions.delete_line' | translate}}</span>
                        </button>
                    </mat-menu>
                </ng-container>
            </mat-chip-listbox>
        </mat-card-content>
    </mat-card>

    <mat-accordion id="week-accordion" *ngIf="totalDurationPerDay && totalDurationPerDay.size !== 0">
        <mat-expansion-panel *ngFor="let day of days" [expanded]="step === day.number"
                             (opened)="setStep(day.number)" hideToggle
                             [ngClass]="{'mobile-personal-holiday': day.personalHoliday, 'mobile-legal-holiday': day.legalHoliday}">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <span class="day-name">{{ day.date | date: 'EEEE':'':translateService.currentLang}}</span>
                        {{day.date | date: 'dd/MM'}}
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <mat-progress-bar id="mobile-progress-indicator"
                                      color="accent"
                                      mode="determinate"
                                      matTooltip="{{'timesheet.encoding.completion' | translate:{n: totalDurationPerDay.get(day.number)} }}"
                                      [value]="100 / loggedAccount.organisation.configuration.workingHoursPerDay.value * totalDurationPerDay.get(day.number)">
                    </mat-progress-bar>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div id="holiday-container">
                <ng-container *ngIf="!day.personalHoliday && !day.legalHoliday; else holidayChipList">
                    <button mat-mini-fab
                            color="accent"
                            (click)="openHolidayModal(day, action.ADD)">
                        <mat-icon style="vertical-align: 0;">event_busy</mat-icon>
                    </button>
                </ng-container>
                <ng-template #holidayChipList>
                    <spurado-chip-list [direction]="'vertical'"
                                       aria-label="Holiday type">
                        <!-- Personal -->
                        <spurado-chip *ngIf="day.personalHoliday && day.personalHoliday.uuid"
                                      [selected]="true"
                                      color="accent"
                                      (click)="timesheet.status === timesheetStatus.EDITABLE && isWorkableDay(day) && openHolidayModal(day, action.EDIT)"
                                      [removable]="timesheet.status === timesheetStatus.EDITABLE"
                                      (remove)="timesheet.status === timesheetStatus.EDITABLE && remove(day.personalHoliday)"
                                      [progress]="day.personalHoliday.duration === 'FD' ? '100' : day.personalHoliday.duration === 'AM' ? '50-start' : '50-end'">
                            <div chipText id="personal-holiday-chip">
                                {{'timesheet.day.holiday.personal' | translate}}
                            </div>
                        </spurado-chip>

                        <!-- Legal -->
                        <spurado-chip *ngIf="day.legalHoliday"
                                      [ripples]="false"
                                      [selected]="true"
                                      color="accent">
                            <div chipText>{{'timesheet.day.holiday.legal' | translate}}</div>
                        </spurado-chip>
                    </spurado-chip-list>
                </ng-template>
            </div>

            <div style="display: flex; flex-direction: column;" *ngFor="let data of dataPerDay.get(day.number)">
                <ng-container *ngIf="!loggedAccount.organisation.configuration.dailyPointing.value else dailyPointing">
                    <mat-form-field appearance="fill">
                        <mat-label>{{data.task.name}}<span *ngIf="data.line.client">&nbsp;({{data.line.client.name}})</span></mat-label>
                        <input type="number" min="0" matInput value="{{data.day.actualDuration}}"
                               (focusout)="saveActual(data.line, data.day, $event)"
                               [disabled]="isActualCellDisabled(timesheet, day.number)">
                    </mat-form-field>
                </ng-container>
                <ng-template #dailyPointing>
                    <div style="display: flex; flex-direction: row; padding: 5px;">
                        <mat-label>{{data.task.name}}<span *ngIf="data.line.client">&nbsp;({{data.line.client.name}})</span> :</mat-label>
                        <spurado-timesheet-daily-pointing-btn
                            iconStyleClass="daily-pointing-icon-mobile"
                            [configuration]="loggedAccount.organisation.configuration"
                            [actualDuration]="data.day.actualDuration"
                            (saveActual)="saveActualFromDailyPointing(data.line, data.day, $event)"
                            [disabled]="isActualCellDisabled(timesheet, day.number)">
                        </spurado-timesheet-daily-pointing-btn>
                    </div>
                </ng-template>
            </div>
            <mat-action-row>
                <button [disabled]="!(day.number > 1)" mat-button color="warn"
                        (click)="prevStep()">{{'common.previous' | translate}}</button>
                <button [disabled]="!(day.number < days.length)" mat-button color="primary"
                        (click)="nextStep()">{{'common.next' | translate}}</button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mobile-week-selector-container" *ngIf="totalDurationPerDay && totalDurationPerDay.size !== 0">
        <button mat-icon-button
                color="primary"
                [disableRipple]="true"
                (click)="toggleWeekend()">
            <mat-icon *ngIf="weekEndVisible">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!weekEndVisible">keyboard_arrow_down</mat-icon>
        </button>
    </div>

    <ng-container
        *ngIf="timesheet!==undefined && timesheet.status === timesheetStatus.EDITABLE && mode === timesheetMode.USER">
        <div class="action-buttons">
            <ng-container *ngIf="!timesheet.timesheetLines || timesheet.timesheetLines.length === 0">
                <h1 class="mat-h1">
                    {{'timesheet.no_data' | translate}}<br/>
                    <button mat-mini-fab color="primary" (click)="addAllFavoriteTasksToTimesheet()">
                        <mat-icon style="vertical-align: 0;" matTooltip="{{'tasks.favorites.tooltip' | translate}}">
                            star
                        </mat-icon>
                    </button>
                </h1>
            </ng-container>
            <button mat-raised-button color="primary" [disabled]="isSubmitButtonDisabled()"
                    (click)="openSubmissionConfirmationModal(true)">
                <mat-icon style="margin-right: 10px;">send</mat-icon>
                <span *ngIf="!forceSubmissionWithWarnings">{{'timesheet.actions.submit' | translate}}</span>
                <span *ngIf="forceSubmissionWithWarnings">{{'timesheet.actions.submit_with_errors' | translate}}</span>
            </button>
        </div>
    </ng-container>
</section>
