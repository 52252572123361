<div id="container-header" class="primary-100">
    <span>{{'timesheet.approving.sub_title' | translate: {
        year: year,
        weekNumber: week,
        month: month
    } }}</span>
</div>
<ng-container *ngIf="!isLoading">
    <div *ngIf="timesheet; else noPendingValidation">
        <ng-container *ngIf="(breakpointService.shouldDisplayDesktopLayout() | async)">
            <div class="account-title">
                <mat-icon color="primary" [inline]="true">account_circle
                </mat-icon>&nbsp;{{timesheet.account.user.displayName}}
            </div>
            <spurado-timesheet-table [mode]="mode"
                                     [timesheet]="timesheet"
                                     [loggedAccount]="loggedAccount"
                                     [highlightedLineSequence]="highlightedLineSequence"
                                     (lineActionChange)="approveOrRejectLine($event)"
                                     [displayWeekend]="displayWeekend"
                                     (displayWeekendChange)="updateDisplayWeekend($event)">
            </spurado-timesheet-table>
        </ng-container>
        <ng-container *ngIf="(breakpointService.shouldDisplayDesktopLayout() | async) === false">
            <spurado-mobile-validation-timesheet-table [mode]="mode"
                                                       [timesheet]="timesheet"
                                                       [loggedAccount]="loggedAccount"
                                                       [highlightedLineSequence]="highlightedLineSequence"
                                                       (approveOrRejectClick)="approveOrRejectLine($event)">
            </spurado-mobile-validation-timesheet-table>
        </ng-container>
    </div>
    <ng-template #noPendingValidation>
        <div class="spurado-no-action-panel">
            <h1>{{'timesheet.approving.nothing_to_do' | translate}}</h1>
            <mat-icon color="primary" class="material-icons-outlined">thumb_up_alt</mat-icon>
        </div>
    </ng-template>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>

