import {Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {Account, ApplicationDetails, Theme, User} from '../../spurado';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../account/account.service';
import {HttpClient} from '@angular/common/http';
import {CommonService} from '../common/common.service';
import {ConfigurationService} from '../configuration/configuration.service';
import {environment} from '../../../environments/environment';
import {AppStore, StoreActionType} from '../../app.store';
import {ApprovalService} from '../approval/approval.service';
import {TaskService} from '../task/task.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    private apiUrl: string = environment.apiUrl;

    public applicationStore: AppStore;

    private unsubscribeSubject: Subject<void>;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private commonService: CommonService,
                private configurationService: ConfigurationService,
                private approvalService: ApprovalService,
                public accountService: AccountService,
                private taskService: TaskService) {
        this.applicationStore = new AppStore()
        .addStoreElement<ApplicationDetails>(
            {type: StoreActionType.APPLICATION_DETAILS},
            undefined,
            () => this.commonService.getApplicationDetails()
        )
        .addStoreElement<Account>(
            {type: StoreActionType.ACCOUNT},
            undefined,
            (orgUuid: string) => this.accountService.getAccount(orgUuid)
        )
        .addStoreElement<User>(
            {type: StoreActionType.USER},
            undefined,
            () => this.httpClient.get<User>(this.apiUrl + `users/me`)
        )
        .addStoreElement<Theme>(
            {type: StoreActionType.THEME},
            Theme.NEUTRAL,
            (theme: Theme) => of(theme)
        )
        .addStoreElement<number>(
            {type: StoreActionType.PENDING_APPROVALS_COUNT},
            undefined,
            (orgUuid: string) => this.approvalService.getNumberOfApprovals(orgUuid)
        )
        .addStoreElement<number>(
            {type: StoreActionType.PENDING_TASKS_COUNT},
            undefined,
            (orgUuid: string) => this.taskService.getNumberOfPendingTasks(orgUuid)
        );
        this.unsubscribeSubject = new Subject<void>();
    }
}
