<section class="header-panel flex-1">
    <ng-container *ngIf="loggedAccount$ | async as loggedAccount; else logoutPanel">
        <div class="logo-container">
            <a class="application-logo" (click)="selectOrganisation()"></a>
        </div>
        <div class="app-header">
            <a [routerLink]="['/' + ((loggedAccount.organisation.uuid) ? (loggedAccount.organisation.uuid) : '')]"
               routerLinkActive="active-menu">
                <ng-container *ngIf="loggedAccount.organisation.logoUrl else noLogo">
                    <img class="client-logo" src="{{loggedAccount.organisation.logoUrl}}"
                         alt="{{loggedAccount.organisation.name}}">
                </ng-container>
                <ng-template #noLogo>
                    <div class="app-title">{{loggedAccount.organisation.name}}</div>
                </ng-template>
            </a>
        </div>
        <div class="matMenu">
            <div class="notification-admin">
                <a class="menu-icon" *ngIf="numberOfPendingTasks$ | async as pendingTasks"
                   [routerLink]="[this.loggedAccount.organisation.uuid + '/admin/pending-tasks']">
                    <mat-icon matBadge="{{pendingTasks}}"
                              matBadgeColor="accent"
                              matTooltip="{{'admin.pending_tasks.tooltip' | translate}}">
                        add_task
                    </mat-icon>
                </a>
            </div>
            <div class="notification-admin">
                <a class="menu-icon" *ngIf="numberOfPendingApprovals$ | async as pendingApprovals"
                   [routerLink]="[this.loggedAccount.organisation.uuid + '/approval']">
                    <mat-icon matBadge="{{pendingApprovals}}"
                              matBadgeColor="accent"
                              matTooltip="{{'common.tooltips.you_are_an_approbator' | translate}}">
                        notification_important
                    </mat-icon>
                </a>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="appMenu" (click)="onOpenMenu()">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #appMenu="matMenu" (closed)="expanded=false">
                <div class="logged-user logged-user-impersonate"
                     *ngIf="loggedAccount?.impersonated">
                    <div
                        class="spurado-light-text">{{'impersonate.on_behalf_of' | translate}} {{loggedAccount.user.email}}</div>
                </div>
                <div class="logged-user mat-elevation-z2" (click)="$event.stopPropagation()"
                     *ngIf="!loggedAccount?.impersonated">
                    <div id="firstname" class="spurado-light-text">
                        {{identity$ | async }}
                    </div>
                </div>
                <div>
                    <a mat-menu-item
                       [routerLink]="['/' + (this.loggedAccount ? this.loggedAccount.organisation.uuid : '')]"
                       [routerLinkActiveOptions]="{exact: true}"
                       routerLinkActive="active-menu">
                        <mat-icon class="material-icons-outlined">
                            date_range
                        </mat-icon>
                        <span>{{'menu.encoding' | translate}}</span>
                    </a>
                    <a mat-menu-item *ngIf="pdfTimesheet$ | async"
                       [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/timesheet2pdf']"
                       routerLinkActive="active-menu">
                        <mat-icon class="material-icons-outlined">
                            schedule
                        </mat-icon>
                        <span>{{'menu.timesheet2pdf' | translate}}</span>
                    </a>
                    <a mat-menu-item *ngIf="reporter$ | async"
                       [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/reporting']"
                       routerLinkActive="active-menu">
                        <mat-icon class="material-icons-outlined">
                            article
                        </mat-icon>
                        <span>{{'menu.reporting' | translate}}</span>
                    </a>
                    <mat-divider></mat-divider>

                    <a mat-menu-item
                       [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/admin']"
                       routerLinkActive="active-menu"
                       [matMenuTriggerFor]="adminMenu"
                       *ngIf="loggedAccount.isAdmin">
                        <mat-icon class="material-icons-outlined">
                            settings
                        </mat-icon>
                        <span>{{'menu.administration' | translate}}</span>
                    </a>
                    <mat-menu #adminMenu="matMenu">
                        <a mat-menu-item
                           [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/admin/organisation']"
                           routerLinkActive="active-menu">
                            <mat-icon
                                matTooltip="{{'organisation.admin_title' | translate:{orgName: loggedAccount.organisation.name} }}">
                                business
                            </mat-icon>
                            <span class="admin-menu-txt"
                            >{{'organisation.admin_title' | translate:{orgName: loggedAccount.organisation.name} }}</span>
                        </a>
                        <a mat-menu-item
                           [routerLink]="['/' + loggedAccount.organisation.uuid + '/admin/users']"
                           [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-menu">
                            <mat-icon
                                matTooltip="{{'users.title' | translate}}">group
                            </mat-icon>
                            <span class="admin-menu-txt">{{'users.title' | translate}}</span>
                        </a>
                        <a mat-menu-item
                           [routerLink]="['/' + loggedAccount.organisation.uuid + '/admin/tasks']"
                           [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-menu">
                            <mat-icon
                                matTooltip="{{'tasks.title' | translate}}">work_outlined
                            </mat-icon>
                            <span
                                class="admin-menu-txt">{{'tasks.title' | translate}}</span>
                        </a>
                        <a mat-menu-item
                           [routerLink]="['/' + loggedAccount.organisation.uuid + '/admin/statistics']"
                           [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-menu"
                           *ngIf="canEditStatistics$ | async">
                            <mat-icon
                                matTooltip="{{'statistics.title' | translate}}">
                                insert_chart_outlined
                            </mat-icon>
                            <span
                                class="admin-menu-txt">{{'statistics.title' | translate}}</span>
                        </a>
                        <a mat-menu-item
                           [routerLink]="['/' + loggedAccount.organisation.uuid + '/admin/timesheet']"
                           [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-menu"
                           *ngIf="canEditTimesheets$ | async">
                            <mat-icon
                                matTooltip="{{'edit_timesheet.title' | translate}}">
                                blur_linear
                            </mat-icon>
                            <span
                                class="admin-menu-txt">{{'timesheet.admin.title' | translate}}</span>
                        </a>
                    </mat-menu>

                    <a mat-menu-item
                       *ngIf="false && loggedAccount.isAdmin"
                       [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/approval/dashboard']"
                       routerLinkActive="active-menu">
                        <mat-icon class="material-icons-outlined">
                            fact_check
                        </mat-icon>
                        <span>{{'menu.approve_dashboard' | translate}}</span>
                    </a>
                    <mat-divider *ngIf="loggedAccount.isAdmin"></mat-divider>
                    <a mat-menu-item
                       [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/account']"
                       [routerLinkActiveOptions]="{exact: true}"
                       routerLinkActive="active-menu">
                        <mat-icon class="material-icons-outlined">
                            perm_identity
                        </mat-icon>
                        <span>{{'account.menu' | translate}}</span>
                    </a>
                    <button *ngIf="canImpersonate()" mat-menu-item [mat-menu-trigger-for]="impersonateMenu">
                        <mat-icon class="material-icons-outlined">supervisor_account</mat-icon>
                        <span>{{'impersonate.target' | translate}}</span>
                    </button>

                    <mat-menu #impersonateMenu>
                        <button mat-menu-item *ngFor="let accountDelegation of accountDelegations.slice(0,3)" (click)="impersonate(loggedAccount, accountDelegation)">
                            <div class="delegation">
                                <span class="pending-approvals">{{accountDelegation.pendingApprovals}}</span>
                                <span>{{accountDelegation.delegatorDisplayName}}</span>
                            </div>
                        </button>
                        <ng-container *ngIf="impersonator || accountDelegations.length > 3">
                            <mat-divider></mat-divider>
                            <a mat-menu-item
                               routerLinkActive="active-menu"
                               [routerLink]="['/' + this.loggedAccount.organisation.uuid + '/account/impersonate']">
                                <span>{{ 'impersonate.other_person' | translate }}</span>
                            </a>
                        </ng-container>
                    </mat-menu>

                    <button *ngIf="loggedUser$ | async as loggedUser" mat-menu-item (click)="logout(loggedUser, loggedAccount.organisation.uuid)">
                        <mat-icon class="material-icons-outlined">power_settings_new</mat-icon>
                        <span>{{'common.actions.logout' | translate}}</span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-container>

    <ng-template #logoutPanel>
        <div class="matMenu">
            <button mat-icon-button *ngIf="loggedAccount$ | async as loggedAccount"
                    (click)="logout(loggedAccount)">
                <mat-icon class="material-icons-outlined" style="margin-right: 1rem;">
                    power_settings_new
                </mat-icon>
            </button>
        </div>
    </ng-template>
</section>

<ng-container *ngIf="loggedAccount$ | async as loggedAccount">
    <div class="impersonate-warn" *ngIf="loggedAccount.impersonated">
        <p>{{'warning.message' | translate}} {{'impersonate.on_behalf_of' | translate}} {{loggedAccount.user.email}}</p>
    </div>
</ng-container>
