import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Account, AccountRef} from '../../spurado';
import {mergeMap, Observable, of, Subject} from 'rxjs';
import {AccountService} from '../../service/account/account.service';
import {FormControl, Validators} from '@angular/forms';
import {RequireMatch} from '../common/requireMatch';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-impersonate',
    templateUrl: './impersonate.component.html',
    styleUrls: ['./impersonate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpersonateComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;

    accounts$: Observable<AccountRef[]>;
    impersonateFormControl: FormControl;
    loggedAccount: Account;

    constructor(private applicationService: ApplicationService,
                private accountService: AccountService,
                public dialogRef: MatDialogRef<ImpersonateComponent>) {
        this.unsubscribeSubject = new Subject<void>();
        this.impersonateFormControl = new FormControl<string>('', [Validators.required, RequireMatch, Validators.minLength(3)]);
    }

    ngOnInit(): void {
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
            }
        });

        this.accounts$ = this.impersonateFormControl.valueChanges
        .pipe(
            debounceTime(1000),
            mergeMap((search) => this.getFilteredAccounts(search))
        );
    }

    private getFilteredAccounts(search: string): Observable<AccountRef[]> {
        if (search?.length < 3) {
            return of([]);
        }

        return this.accountService.getAllAccounts(this.loggedAccount.organisation.uuid, undefined, undefined, this.impersonateFormControl.value)
        .pipe(
            map((accounts) => accounts.filter(a => a.uuid !== this.loggedAccount.uuid))
        );
    }

    accountToString(account?: AccountRef): string | undefined {
        if (account) {
            let name = '';
            if (account.user.displayName) {
                name += account.user.displayName + ' ';
            }
            if (account.user.email != '-') {
                return name + '(' + account.user.email + ')';
            }
            return name;
        }
        return undefined;
    }

    onNoClick(): void {
        this.impersonateFormControl.reset('');
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
