import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'spurado-error-snackbar',
    templateUrl: './error-snackbar.component.html',
    styleUrls: ['./error-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorSnackbarComponent {

    message: string;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
        if (data.message === data.message.toUpperCase()) {
            this.message = 'errors.code.' + data.message;
            console.log(this.message = 'errors.code.' + data.message);
        } else {
            // This is a 'normal' text that can't be translated.  Let's use the text... (might be a spring layer error)
            this.message = data.message;
        }
    }
}
