import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {ApplicationService} from '../service/application/application.service';
import {StoreActionType} from '../app.store';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    orgUuid$: Observable<string>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private applicationService: ApplicationService
    ) {
        this.orgUuid$ = this.router.events.pipe(
            filter(event => (event instanceof NavigationStart)),
            switchMap((event: NavigationStart) => {
                // A little bit hacky but as the service runs independently of the components, the route is not mapped
                // yet in the ActivatedRoute object on a reload event.
                return of(event.url.split('/')[1]);
            })
        );

        // subscribe to the params$ observable - can be done for
        // multiple observers
        this.orgUuid$.subscribe((orgUuid) => {
            if (orgUuid) {
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.ACCOUNT,
                    argument: orgUuid
                });
            }
        });
    }


    init() {
        return Promise.resolve(undefined);
    }
}
