import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Account, Timesheet, TimesheetMode, TimesheetStatus} from '../../../spurado';
import {AccountService} from '../../../service/account/account.service';
import {TimesheetService} from '../../../service/timesheet/timesheet.service';
import {BreakpointService} from '../../../service/breakpoint/breakpoint.service';
import {MatDialog} from '@angular/material/dialog';
import {TimesheetSummaryDialogComponent} from '../timesheet-summary-dialog/timesheet-summary-dialog.component';

@Component({
    selector: 'spurado-timesheet-status',
    templateUrl: './timesheet-status.component.html',
    styleUrls: ['./timesheet-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetStatusComponent {

    @Input()
    timesheet: Timesheet;

    @Input()
    loggedAccount: Account;

    @Input()
    mode: TimesheetMode;

    @Output()
    refresh: EventEmitter<any> = new EventEmitter<any>(true);

    timesheetStatus: typeof TimesheetStatus = TimesheetStatus;
    timesheetMode: typeof TimesheetMode = TimesheetMode;
    initiateRecall: boolean;

    constructor(
        private accountService: AccountService,
        public breakpointService: BreakpointService,
        private timesheetService: TimesheetService,
        private timesheetWeekSummary: MatDialog
    ) {
    }

    get enableRecall(): boolean {
        return TimesheetMode.ADMIN === this.mode || this.timesheet.enableRecall;
    }

    proposeRecall() {
        if (this.enableRecall) {
            this.initiateRecall = !this.initiateRecall;
        }
    }

    recall() {
        if (this.enableRecall) {
            this.timesheetService.recallTimesheet(this.loggedAccount.organisation.uuid, this.timesheet, TimesheetMode.ADMIN === this.mode).then(() => {
                if (TimesheetMode.ADMIN === this.mode) {
                    this.refresh.emit();
                }
            });
        }
    }

    askSummary() {
        this.timesheetService.summary(this.loggedAccount.organisation.uuid, this.timesheet.uuid).subscribe(response => {
            this.timesheetWeekSummary.open(TimesheetSummaryDialogComponent, {
                autoFocus: true,
                data: {
                    summaries: response,
                    configuration: this.loggedAccount.organisation.configuration
                }
            });
        });
    }

}
