import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Account} from '../spurado';
import {ApplicationService} from '../service/application/application.service';
import {StoreActionType} from '../app.store';
import {filter, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SecurityGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

    constructor(
        private router: Router,
        private applicationService: ApplicationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT)
        .data
        .pipe(
            filter((a: Account) => !!a),
            map((a: Account) => a.isAdmin || this.router.createUrlTree(['..']))
        );
    }
}
