import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from '../../service/account/account.service';
import {ApplicationService} from '../../service/application/application.service';
import {Account} from '../../spurado';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
    loggedAccount$: Observable<Account> = this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data;

    constructor(private accountService: AccountService,
                private applicationService: ApplicationService,
    ) {
    }
}
