import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _rollupMoment from 'moment';
import * as _moment from 'moment';
import {Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {Subject} from 'rxjs';
import {Account, AccountRef} from '../../spurado';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ReportingService} from '../../service/reporting/reporting.service';
import {ApplicationService} from '../../service/application/application.service';
import {MatDialog} from '@angular/material/dialog';
import {StoreActionType} from '../../app.store';
import {takeUntil} from 'rxjs/operators';
import {MatDatepicker} from '@angular/material/datepicker';
import {DownloadDialogComponent} from '../download-dialog/download-dialog.component';
import {AccountService} from '../../service/account/account.service';
import {SortAccountsByUserNamePipe} from '../../service/account/pipe/sort-accounts-by-user-name.pipe';
import {TranslateService} from '@ngx-translate/core';
import {OrganisationValidatorDirective} from '../organisation/organisation-validator';
import {DATE_MONTH_FORMAT} from '../../spurado-extended';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'spurado-timesheet-pdf-generation',
    templateUrl: './timesheet-pdf-generation.component.html',
    styleUrls: ['./timesheet-pdf-generation.component.scss'],
    providers: [
        SortAccountsByUserNamePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_MONTH_FORMAT},
    ]
})
export class TimesheetPdfGenerationComponent implements OnInit, OnDestroy {

    loading = false;
    private subject: Subject<void>;
    loggedAccount: Account;

    @ViewChild('primary') primary: ElementRef;
    @ViewChild('secondary') secondary: ElementRef;

    date: FormControl;
    maxDate: Moment;
    otherAccount = false;
    otherAvailableAccounts: AccountRef[];
    accountFilter = '';
    filteredOtherAvailableAccounts: AccountRef[];
    selectedAccount: AccountRef;
    readableMonth = '?';
    availableLocales: string[];
    locale: string;

    constructor(private accountService: AccountService,
                private applicationService: ApplicationService,
                private datePipe: DatePipe,
                private matDialog: MatDialog,
                private reportingService: ReportingService,
                private sortAccountsByUserNamePipe: SortAccountsByUserNamePipe,
                public translateService: TranslateService) {
        this.subject = new Subject<void>();
        this.otherAvailableAccounts = [];
    }

    ngOnInit(): void {
        this.loading = true;
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.subject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
                this.locale = this.loggedAccount.user.locale.toLowerCase();

                const delayBeforeAvailabilityInDays = this.loggedAccount.organisation.configuration.pdfTimesheetWaitDays.value;
                if (delayBeforeAvailabilityInDays > 0) {
                    this.maxDate = moment().subtract((delayBeforeAvailabilityInDays - 1), 'day').subtract(1, 'month').endOf('month');
                } else {
                    this.maxDate = moment();
                }
                this.date = new FormControl<Moment>(this.maxDate);
                this.refreshReadableMonth();

                this.accountService.getManagedAccounts(this.loggedAccount.organisation.uuid).subscribe(managedAccounts => {
                    this.otherAvailableAccounts = this.sortAccountsByUserNamePipe.transform(managedAccounts);
                    this.accountFilter = '';
                    this.filteredOtherAvailableAccounts = managedAccounts;
                    this.loading = false;
                }, error => {
                    console.error(error);
                    this.loading = false;
                });
            }
        });
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        ctrlValue.startOf('month');
        this.date.setValue(ctrlValue);
        datepicker.close();
        this.refreshReadableMonth();
    }

    get isWrongTypeParam() {
        return this.otherAccount && !this.selectedAccount;
    }

    generatePDFTimesheet() {
        let account = this.loggedAccount;
        if (this.otherAccount && this.selectedAccount) {
            account = this.selectedAccount;
        }
        const month = this.date.value;
        const url = this.reportingService.buildPDFTimesheetURL(this.loggedAccount.organisation.uuid, account.uuid, month, this.locale, this.cleanupRichText(getComputedStyle(this.primary.nativeElement).color));
        const filename = this.generateFilename(account, month);
        this.matDialog.open<DownloadDialogComponent>(DownloadDialogComponent, {
            data: {url: url, filename: filename},
            disableClose: true
        });
    }

    applyAccountFilter() {
        this.filteredOtherAvailableAccounts = this.otherAvailableAccounts.filter(a => a.user.displayName.toLowerCase().includes(this.accountFilter.toLowerCase()));
    }

    refreshReadableMonth() {
        if (this.date && this.date.value) {
            this.readableMonth = this.datePipe.transform(this.date.value, 'MM/YYYY');
        }
    }

    changePDFLocale() {
        this.availableLocales = this.translateService.getLangs();
        const currentPosition = this.availableLocales.indexOf(this.locale.toLowerCase());
        let nextPosition = currentPosition + 1;
        if (nextPosition > this.availableLocales.length - 1) {
            nextPosition = 0;
        }
        this.locale = this.availableLocales[nextPosition];
    }

    ngOnDestroy() {
        this.subject.next();
        this.subject.unsubscribe();
    }

    private cleanupRichText(richText): string {
        return richText.replace(/rgb\((.+?)\)/ig, (_, rgb) => {
            return '#' + rgb.split(',')
            .map(str => parseInt(str, 10).toString(16).padStart(2, '0'))
            .join('');
        });
    }

    private generateFilename(account: Account, month): string {
        let normalizedAccountName = OrganisationValidatorDirective.stripAccents(account.user.displayName).toLowerCase().replace(/[^a-z0-9]+/g, '-');
        if (!normalizedAccountName || normalizedAccountName.length === 0) {
            normalizedAccountName = '';
        }
        return `${this.datePipe.transform(month, 'yyyy-MM')}_timesheet-${normalizedAccountName}.pdf`;
    }


}
