<ng-container *ngIf="download$ | async as download">
    <mat-dialog-content>
        <p>{{'reporting.download.pending' | translate}}</p>
        <mat-progress-bar color="primary"
                          [mode]="download.state === 'PENDING' ? 'buffer' : 'determinate'"
                          [value]="download.progress">
        </mat-progress-bar>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" [disabled]="download.state !== 'DONE'" (click)="close()">{{'common.actions.close' | translate}}</button>
    </mat-dialog-actions>
</ng-container>
