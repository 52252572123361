import {Component, OnDestroy, OnInit} from '@angular/core';
import {Account, Action, LineStatus, LineStatusUpdate, Timesheet, TimesheetLine, TimesheetMode} from '../../spurado';
import {TimesheetService} from '../../service/timesheet/timesheet.service';
import {ActivatedRoute} from '@angular/router';
import {SnackbarService} from '../../service/snackbar/snackbar.service';
import {Criticity} from '../../spurado-extended';
import {ApprovalService} from '../../service/approval/approval.service';
import {AccountService} from '../../service/account/account.service';
import {UserService} from '../../service/user/user.service';
import {CommonService} from '../../service/common/common.service';
import {BreakpointService} from '../../service/breakpoint/breakpoint.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {RejectionDialogComponent} from './dialog/rejection-dialog/rejection-dialog.component';
import {ApplicationService} from '../../service/application/application.service';
import {StoreActionType} from '../../app.store';

@Component({
    selector: 'spurado-timesheet-approbation',
    templateUrl: './timesheet-approbation.component.html',
    styleUrls: ['./timesheet-approbation.component.scss']
})
export class TimesheetApprobationComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;

    isLoading: boolean;
    timesheet: Timesheet;
    mode: TimesheetMode;
    year: number;
    month: number;
    week: number;
    accountUuid: string;
    highlightedLineSequence: string;
    loggedAccount: Account;
    displayWeekend: boolean;

    constructor(private applicationService: ApplicationService,
                private commonService: CommonService,
                private accountService: AccountService,
                private timesheetService: TimesheetService,
                private approvalService: ApprovalService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private userService: UserService,
                private dialog: MatDialog,
                public breakpointService: BreakpointService) {
        this.unsubscribeSubject = new Subject<void>();
        this.displayWeekend = CommonService.getBooleanValueFromStorage('displayWeekEnd');
    }

    ngOnInit() {
        this.isLoading = true;
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
                this.route.queryParamMap.subscribe(paramMap => {
                    this.mode = TimesheetMode[paramMap.get('mode')];
                    this.year = +paramMap.get('timesheetYear');
                    this.month = +paramMap.get('timesheetMonth');
                    this.week = +paramMap.get('timesheetWeekNumber');
                    this.accountUuid = paramMap.get('accountUuid');
                    this.highlightedLineSequence = paramMap.get('highlightedLineSequence');

                    this.getFreshTimesheetData();
                });
            }
        });
    }

    private getFreshTimesheetData() {
        this.timesheetService.getTimesheets(this.loggedAccount.organisation.uuid, this.year, this.month, this.week, this.mode, this.accountUuid).subscribe(timesheets => {
                this.timesheet = (timesheets && timesheets.length >= 1) ? timesheets[0] : undefined;
                this.isLoading = false;
            },
            () => {
                this.snackbarService.openErrorSnackBar();
                this.isLoading = false;
            });
    }

    approveOrRejectLine(event) {
        const body: LineStatusUpdate = new LineStatusUpdate();
        body.version = event.line.version;
        switch (event.action) {
            case Action.APPROVE:
                body.status = LineStatus.VALIDATED;
                this.approveOrReject(event.timesheetUuid, event.line, body);
                break;
            case Action.REJECT:
                body.status = LineStatus.OPEN;
                const rejectionDialog = this.dialog.open<RejectionDialogComponent>(RejectionDialogComponent, {
                    width: '50vw',
                    data: {}
                });

                const subscription = rejectionDialog.afterClosed().subscribe(comment => {
                    if (comment) {
                        body.comment = comment;
                        this.approveOrReject(event.timesheetUuid, event.line, body);
                    }
                    subscription.unsubscribe();
                });
                break;
            default:
                console.error('I don\'t know what to do with action : ' + event.action.toString);
                break;
        }
    }

    private approveOrReject(timesheetUuid: string, line: TimesheetLine, lineStatusUpdate: LineStatusUpdate) {
        this.approvalService.approveOrRejectLine(this.loggedAccount.organisation.uuid, timesheetUuid, line.sequence, lineStatusUpdate).subscribe(
            () => {
                this.snackbarService.openGenericSnackBar('common.actions.success', Criticity.INFO);
                this.getFreshTimesheetData();
                this.applicationService.applicationStore.dispatch({
                    type: StoreActionType.PENDING_APPROVALS_COUNT,
                    argument: this.loggedAccount.organisation.uuid
                });
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    updateDisplayWeekend(displayWeekend: boolean) {
        localStorage.setItem('displayWeekEnd', displayWeekend.toString());
        this.displayWeekend = displayWeekend;
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
