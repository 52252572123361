import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {AccountRef, UserGroup} from '../../spurado';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService {

    apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    getAllGroups(orgUuid: string): Observable<Array<UserGroup>> {
        return this.httpClient.get<Array<UserGroup>>(this.apiUrl + `${orgUuid}/usergroups`);
    }

    getAvailableGroups(orgUuid: string, accountUuid: string) {
        return this.httpClient.get<Array<UserGroup>>(this.apiUrl + `${orgUuid}/usergroups/account/${accountUuid}`)
    }

    getAvailableGroupsForProject(orgUuid: string, projectUuid: string): Observable<Array<UserGroup>> {
        const requestParams: HttpParams = new HttpParams().set('notParticipantOfTaskUuid', projectUuid);
        return this.httpClient.get<Array<UserGroup>>(this.apiUrl + `${orgUuid}/usergroups`, {params: requestParams});
    }

    createGroup(orgUuid: string, userGroupName: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/usergroups`, userGroupName);
    }

    deleteGroup(orgUuid: string, userGroupUuid: string): Observable<any>{
        return this.httpClient.delete(this.apiUrl + `${orgUuid}/usergroups/${userGroupUuid}`);
    }

    addMemberToGroup(orgUuid: string, userGroup: UserGroup, account: AccountRef): Observable<any> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/usergroups/${userGroup.uuid}/${account.uuid}`, null);
    }

    addMemberToGroups(orgUuid: string, userGroups: UserGroup[], account: AccountRef): Observable<any> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/usergroups/account/${account.uuid}`, userGroups);
    }

    removeMemberFromGroup(orgUuid: string, userGroup: UserGroup, account: AccountRef): Observable<any> {
        return this.httpClient.delete(this.apiUrl + `${orgUuid}/usergroups/${userGroup.uuid}/${account.uuid}`);
    }
}
