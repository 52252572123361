import {Component, Inject} from '@angular/core';
import {Account, AccountDelegation, AccountRef} from '../../../../spurado';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {mergeMap, Observable, of} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {AccountService} from '../../../../service/account/account.service';
import * as moment from 'moment';
import {utc} from 'moment';

type AccountData = {
    uuid: string;
    displayName: string;
    email: string;
}

@Component({
    selector: 'spurado-delegation-dialog',
    templateUrl: './delegation-dialog.component.html',
    styleUrls: ['./delegation-dialog.component.scss']
})
export class DelegationDialogComponent {
    formGroup: FormGroup;
    accounts$: Observable<AccountData[]>;
    today: Date = new Date();

    private readonly delegationUuid: string;
    private loggedAccount: Account;

    constructor(
        private accountService: AccountService,
        private dialogRef: MatDialogRef<DelegationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: {
            accountDelegation: AccountDelegation,
            loggedAccount: Account,
        }
    ) {
        this.delegationUuid = data.accountDelegation?.uuid;
        const delegateeData: AccountData = {
            uuid: data.accountDelegation && data.accountDelegation.delegateeUuid,
            displayName: data.accountDelegation && data.accountDelegation.delegateeDisplayName,
            email: data.accountDelegation && data.accountDelegation.delegateeEmail
        };
        this.formGroup = new FormGroup<any>({
            delegatee: new FormControl<AccountData>(delegateeData, Validators.required),
            validFrom: new FormControl<Date>(data.accountDelegation?.validFrom, Validators.required),
            validTo: new FormControl<Date>(data.accountDelegation?.validTo, Validators.required),
        });
        this.loggedAccount = data.loggedAccount;

        this.accounts$ = this.formGroup.controls.delegatee.valueChanges
        .pipe(
            debounceTime(1000),
            mergeMap((search: string) => search?.length >= 3 ? this.getFilteredAccounts(search) : of([])),
            map((accounts: Account[]) => accounts.map(account => ({
                uuid: account.uuid,
                displayName: account.user.displayName,
                email: account.user.email
            } as AccountData)))
        );
    }

    get formValue(): AccountDelegation {
        const accountDelegation = new AccountDelegation();
        accountDelegation.uuid = this.delegationUuid;
        accountDelegation.delegateeUuid = this.formGroup.value.delegatee.uuid;
        const offset = moment(this.formGroup.value.validFrom).utcOffset();
        accountDelegation.validFrom = utc(this.formGroup.value.validFrom).add(offset, 'm').toDate();
        accountDelegation.validTo = utc(this.formGroup.value.validTo).add(offset, 'm').toDate();
        return accountDelegation;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    isFormEmptyOrInvalid(): boolean{
        return this.formGroup.pristine || this.formGroup.invalid || !this.formGroup.value.delegatee.uuid
    }

    accountToString(account: AccountData): string {
        if (!account) {
            return null;
        }
        let name = '';
        if (account.displayName) {
            name = account.displayName;
        }
        if (account.email) {
            return `${name} (${account.email})`;
        }
        return name;
    }

    private getFilteredAccounts(search: string): Observable<AccountRef[]> {
        return this.accountService.getAllAccounts(
            this.loggedAccount.organisation.uuid,
            undefined,
            undefined,
            search
        ).pipe(
            map(accounts => {
                if (accounts.length <= 0) {
                    this.formGroup.controls.delegatee.setErrors({'invalid': true});
                    return [];
                }

                return accounts.filter(a => a.uuid !== this.loggedAccount.uuid);
            })
        );
    }
}
