import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'spurado-rejection-dialog',
    templateUrl: './rejection-dialog.component.html',
    styleUrls: ['./rejection-dialog.component.scss']
})
export class RejectionDialogComponent {

    commentFormControl: FormControl;

    constructor(public dialogRef: MatDialogRef<RejectionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.commentFormControl = new FormControl<string>('', [Validators.required]);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
