import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Account, Approval} from '../../../spurado';
import {ApprovalLayout, FilterElement, FilterType} from '../../../spurado-extended';
import {MatTableDataSource} from '@angular/material/table';
import {SmartTableAction, SmartTableColumnDef} from '../../common/smart-table/smart-table-column-def';
import {Subject} from 'rxjs';
import {ApplicationService} from '../../../service/application/application.service';
import {StoreActionType} from '../../../app.store';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'spurado-approval-card',
    templateUrl: './approval-card.component.html',
    styleUrls: ['./approval-card.component.scss']
})
export class ApprovalCardComponent implements OnInit, OnChanges {

    @Input() approvals: Approval[];
    @Input() cardOrList: ApprovalLayout;
    @Output() approveLine = new EventEmitter<Approval>();
    @Output() goToDetails = new EventEmitter<Approval>();
    @Output() generateFilter = new EventEmitter<FilterElement>;

    private unsubscribeSubject: Subject<void>;
    filterType: typeof FilterType = FilterType;
    smartTableApproveColumnDef: SmartTableColumnDef[];
    dataSource: MatTableDataSource<Approval>;
    loggedAccount: Account;

    constructor(private applicationService: ApplicationService) {
        this.unsubscribeSubject = new Subject<void>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.createdTable();
    }

    ngOnInit(): void {
        this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(account => {
            if (account) {
                this.loggedAccount = account;
            }
        });
    }

    approve($event: Approval) {
        this.approveLine.emit($event);
    }

    goToTimesheet($event: Approval) {
        this.goToDetails.emit($event);
    }

    createdTable(): void {
        if (ApprovalLayout.LIST) {
            this.dataSource = new MatTableDataSource();
            this.smartTableApproveColumnDef = [
                {displayName: 'users.name', datasourceAttribute: 'userDisplayName', sortable: true},
                {
                    displayName: 'timesheet.week.title',
                    datasourceAttribute: 'timesheetWeekAndMonth',
                    sortable: true
                },
                {displayName: 'tasks.name', datasourceAttribute: 'taskName', sortable: true},
                {
                    displayName: 'tasks.clients.name',
                    datasourceAttribute: 'clientName',
                    sortable: true
                },
                {
                    displayName: 'tasks.projects.programs.name',
                    datasourceAttribute: 'programName',
                    sortable: true
                },
                {
                    displayName: 'timesheet.approving.number_of_hours',
                    datasourceAttribute: 'totalDuration',
                    sortable: true,
                    interactionCallbackFn: row => this.goToTimesheet(row)
                },
                {
                    displayName: '',
                    datasourceAttribute: 'approve',
                    action: SmartTableAction.APPROVE,
                    callbackFn: row => this.approve(row)
                }
            ];

            this.dataSource.data = this.approvals;
        }
    }

    createdFilterTile(approval: Approval, filterType: FilterType) {
        const filterElement = new FilterElement();
        filterElement.type = filterType;

        switch (filterType) {
            case FilterType.ACCOUNT:
                filterElement.name = approval.account.user.displayName;
                filterElement.uuid = approval.account.uuid;
                this.generateFilter.emit(filterElement);
                break;
            case FilterType.TASK:
                filterElement.name = approval.taskName;
                filterElement.uuid = approval.taskUuid;
                this.generateFilter.emit(filterElement);
                break;
            case FilterType.CUSTOMER:
                if (approval.clientName != null && approval.clientName !== '-') {
                    filterElement.name = approval.clientName;
                    filterElement.uuid = approval.clientUuid;
                    this.generateFilter.emit(filterElement);
                }
                break;
            case FilterType.PROGRAM:
                if (approval.programName != null && approval.programName !== '-') {
                    filterElement.name = approval.programName;
                    filterElement.uuid = approval.programUuid;
                    this.generateFilter.emit(filterElement);
                }
        }
    }
}

