import {NgModule} from '@angular/core';
import {ChipComponent} from './chip/chip.component';
import {ChipListComponent} from './chip-list/chip-list.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {FlipSmartphoneComponent} from './flip-smartphone/flip-smartphone.component';
import {MessageInfoComponent} from './message-info/message-info.component';
import {SmartTableComponent} from './smart-table/smart-table.component';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TimesheetTableComponent} from '../timesheet/timesheet-table/desktop-timesheet-table/timesheet-table.component';
import {MobileTimesheetTableComponent} from '../timesheet/timesheet-table/mobile-timesheet-table/mobile-timesheet-table.component';
import {FocusDirective} from '../../directive/focus/focus.directive';
import {TimesheetDailyPointingBtnComponent} from '../timesheet/timesheet-table/timesheet-daily-pointing-btn/timesheet-daily-pointing-btn.component';
import {SortAccountsByUserNamePipe} from '../../service/account/pipe/sort-accounts-by-user-name.pipe';
import {TimesheetComponent} from '../timesheet/timesheet.component';
import {TimesheetCommentComponent} from '../timesheet/timesheet-comment/timesheet-comment.component';
import {TimesheetProjectListComponent} from '../timesheet/timesheet-project-list/timesheet-project-list.component';
import {TimesheetApprobationComponent} from '../timesheet-approbation/timesheet-approbation.component';
import {TimesheetHolidayComponent} from '../timesheet/timesheet-holiday/timesheet-holiday.component';
import {TimesheetStatusComponent} from '../timesheet/timesheet-status/timesheet-status.component';
import {WeekNavigatorComponent} from '../navigator/week-navigator/week-navigator.component';
import {MobileValidationTimesheetTableComponent} from '../timesheet-approbation/mobile-validation-timesheet-table/mobile-validation-timesheet-table.component';
import {ApproveComponent} from '../approve/approve.component';
import {OrganisationComponent} from '../organisation/organisation.component';
import {BaseTimesheetTableComponent} from '../timesheet/timesheet-table/base-timesheet-table.component';
import {BottomSheetMessageComponent} from './message-info/bottom-sheet-message/bottom-sheet-message.component';
import {TimesheetSubmitConfirmComponent} from '../timesheet/timesheet-submit-confirm/timesheet-submit-confirm.component';
import {TimesheetValidationComponent} from '../timesheet/timesheet-validation/timesheet-validation.component';
import {AccountComponent} from '../account/account.component';
import {TimesheetClientComponent} from '../timesheet/timesheet-client/timesheet-client.component';
import {ReportComponent} from '../report/report.component';
import {SortAttributesByKeyPipe} from '../../service/account/pipe/sort-attributes-by-key.pipe';
import {SortGroupByNamePipe} from '../../service/user-group/pipe/sort-group-by-name.pipe';
import {DownloadDialogComponent} from '../download-dialog/download-dialog.component';
import {TimesheetPdfGenerationComponent} from '../timesheet-pdf-generation/timesheet-pdf-generation.component';
import {TimesheetSummaryDialogComponent} from '../timesheet/timesheet-summary-dialog/timesheet-summary-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserDialogComponent} from '../admin/admin-users-roles/users-tab/user-dialog/user-dialog.component';
import {UserMembershipComponent} from '../admin/admin-users-roles/users-tab/user-dialog/user-membership/user-membership.component';
import {GroupDialogComponent} from '../admin/admin-users-roles/groups-tab/group-dialog/group-dialog.component';
import {FilterComponent} from './filter/filter.component';
import {ApprovalCardComponent} from '../approve/approval-card/approval-card.component';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import {DelegationComponent} from '../account/delegation/delegation.component';
import {ImpersonateComponent} from '../account/impersonate/impersonate.component';
import {DelegationTilesComponent} from "../account/delegation/delegation-tiles/delegation-tiles.component";
import {DelegationDialogComponent} from "../account/delegation/delegation-dialog/delegation-dialog.component";
import {DelegationPipe} from "../account/delegation/delegation-tiles/delegation.pipe";

@NgModule({
    declarations: [
        ChipComponent,
        ChipListComponent,
        ConfirmationComponent,
        FlipSmartphoneComponent,
        MessageInfoComponent,
        SmartTableComponent,
        TimesheetTableComponent,
        MobileTimesheetTableComponent,
        FocusDirective,
        TimesheetDailyPointingBtnComponent,
        UserDialogComponent,
        UserMembershipComponent,
        SortAccountsByUserNamePipe,
        TimesheetComponent,
        TimesheetCommentComponent,
        TimesheetProjectListComponent,
        TimesheetApprobationComponent,
        TimesheetHolidayComponent,
        TimesheetStatusComponent,
        GroupDialogComponent,
        WeekNavigatorComponent,
        MobileValidationTimesheetTableComponent,
        ApproveComponent,
        BaseTimesheetTableComponent,
        BottomSheetMessageComponent,
        TimesheetSubmitConfirmComponent,
        TimesheetValidationComponent,
        AccountComponent,
        TimesheetClientComponent,
        ReportComponent,
        SortAttributesByKeyPipe,
        SortGroupByNamePipe,
        DownloadDialogComponent,
        TimesheetPdfGenerationComponent,
        TimesheetSummaryDialogComponent,
        ApproveComponent,
        BaseTimesheetTableComponent,
        BottomSheetMessageComponent,
        TimesheetSubmitConfirmComponent,
        TimesheetValidationComponent,
        AccountComponent,
        TimesheetClientComponent,
        ReportComponent,
        SortAttributesByKeyPipe,
        SortGroupByNamePipe,
        DownloadDialogComponent,
        TimesheetPdfGenerationComponent,
        TimesheetSummaryDialogComponent,
        OrganisationComponent,
        FilterComponent,
        ApprovalCardComponent,
        DelegationComponent,
        ImpersonateComponent,
        DelegationTilesComponent,
        DelegationDialogComponent,
        DelegationPipe
    ],
    exports: [
        ChipComponent,
        ChipListComponent,
        ConfirmationComponent,
        FlipSmartphoneComponent,
        MessageInfoComponent,
        SmartTableComponent,
        TimesheetTableComponent,
        MobileTimesheetTableComponent,
        FocusDirective,
        TimesheetDailyPointingBtnComponent,
        UserDialogComponent,
        UserMembershipComponent,
        SortAccountsByUserNamePipe,
        TimesheetComponent,
        TimesheetCommentComponent,
        TimesheetProjectListComponent,
        TimesheetApprobationComponent,
        TimesheetHolidayComponent,
        TimesheetStatusComponent,
        GroupDialogComponent,
        WeekNavigatorComponent,
        MobileValidationTimesheetTableComponent,
        ApproveComponent,
        OrganisationComponent,
        BaseTimesheetTableComponent,
        BottomSheetMessageComponent,
        TimesheetSubmitConfirmComponent,
        TimesheetValidationComponent,
        AccountComponent,
        TimesheetClientComponent,
        ReportComponent,
        SortAttributesByKeyPipe,
        SortGroupByNamePipe,
        DownloadDialogComponent,
        TimesheetPdfGenerationComponent,
        TimesheetSummaryDialogComponent,
        ApproveComponent,
        OrganisationComponent,
        BaseTimesheetTableComponent,
        BottomSheetMessageComponent,
        TimesheetSubmitConfirmComponent,
        TimesheetValidationComponent,
        AccountComponent,
        TimesheetClientComponent,
        ReportComponent,
        SortAttributesByKeyPipe,
        SortGroupByNamePipe,
        DownloadDialogComponent,
        TimesheetPdfGenerationComponent,
        TimesheetSummaryDialogComponent,
        FilterComponent
    ],
    imports: [
        MatRippleModule,
        MatIconModule,
        MatDialogModule,
        TranslateModule,
        MatButtonModule,
        CommonModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatListModule,
        MatCheckboxModule,
        FormsModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatChipsModule,
        MatMenuModule,
        MatBadgeModule,
        MatTooltipModule,
        MatIconModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatGridListModule,
    ]
})
export class SpuradoCommonModule {
}
