import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';
import {SnackbarService} from '../../service/snackbar/snackbar.service';
import {UserService} from '../../service/user/user.service';
import {AccountService} from '../../service/account/account.service';
import {Account, AccountDelegation, ApplicationDetails, SystemGroup, User} from '../../spurado';
import {ApprovalService} from '../../service/approval/approval.service';
import {Observable, shareReplay} from 'rxjs';
import {StoreActionType} from '../../app.store';
import {MatDialog} from '@angular/material/dialog';
import {filter, map, tap} from 'rxjs/operators';
import {BreakpointService} from '../../service/breakpoint/breakpoint.service';
import {AccountDelegationService} from '../../service/account-delegation/account-delegation.service';
import {ApplicationService} from '../../service/application/application.service';


@Component({
    selector: 'spurado-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

    loggedAccount$: Observable<Account>;
    loggedAccount: Account;
    accountDelegations: AccountDelegation[] = [];
    loggedUser$: Observable<User>;
    applicationDetails$: Observable<ApplicationDetails>;
    numberOfPendingTasks$: Observable<number>;
    numberOfPendingApprovals$: Observable<number>;
    pdfTimesheet$: Observable<boolean>;
    reporter$: Observable<boolean>;
    impersonator$: Observable<boolean>;
    impersonator: boolean;
    expanded: boolean;

    identity$: Observable<string>;
    canEditTimesheets$: Observable<boolean>;
    canEditStatistics$: Observable<boolean>;

    constructor(
        public breakpointService: BreakpointService,
        private accountService: AccountService,
        private approvalService: ApprovalService,
        private router: Router,
        private snackbarService: SnackbarService,
        private userService: UserService,
        private impersonateDialog: MatDialog,
        public applicationService: ApplicationService,
        private accountDelegationService: AccountDelegationService,
    ) {
        this.loggedAccount$ = this.applicationService.applicationStore.listen<Account>(StoreActionType.ACCOUNT).data
        .pipe(
            shareReplay(),
            tap(account => this.loggedAccount = account),
            filter(account => !!account),
        );
        this.loggedUser$ = this.applicationService.applicationStore.listen<User>(StoreActionType.USER).data;
        this.applicationDetails$ = this.applicationService.applicationStore.listen<ApplicationDetails>(StoreActionType.APPLICATION_DETAILS).data;
        this.numberOfPendingApprovals$ = this.applicationService.applicationStore.listen<number>(StoreActionType.PENDING_APPROVALS_COUNT).data;
        this.numberOfPendingTasks$ = this.applicationService.applicationStore.listen<number>(StoreActionType.PENDING_TASKS_COUNT).data;
        this.reporter$ = this.loggedAccount$.pipe(
            map((account) => account.organisation.configuration?.enableReports?.value)
        );
        this.canEditTimesheets$ = this.loggedAccount$.pipe(
            map((account) => account.organisation.configuration?.enableAdminTimesheets?.value)
        );
        this.canEditStatistics$ = this.loggedAccount$.pipe(
            map((account) => account.organisation.configuration?.enableAdminStatistics?.value)
        );
        this.pdfTimesheet$ = this.loggedAccount$.pipe(
            map((account) => account.organisation.configuration?.enablePdfTimesheet?.value))
        ;
        this.identity$ = this.loggedAccount$.pipe(
            map((account) => this.accountService.accountToString(account))
        );
        this.impersonator$ = this.loggedAccount$.pipe(
            map(account => !!account.groups.find(g => g.name === SystemGroup.SUPER_IMPERSONATOR)),
            tap(isImpersonator => this.impersonator = isImpersonator)
        );
    }

    onOpenMenu() {
        this.accountDelegationService.getDelegatorsSummaries(this.loggedAccount.organisation.uuid).subscribe((accountDelegations: AccountDelegation[]) => {
            this.accountDelegations = accountDelegations;
        });
    }

    canImpersonate(): boolean {
        if (!this.loggedAccount || this.loggedAccount.impersonated) {
            return false;
        }

        return this.impersonator || (this.loggedAccount.organisation.configuration.enableDelegation.value && this.accountDelegations.length > 0);
    }

    impersonate(account: Account, delegation: AccountDelegation) {
        const orgUuid = account.organisation.uuid;
        const newRoute = '/' + orgUuid;
        this.router.navigate([newRoute]).then(() => {
            this.userService.impersonateAccount(delegation.delegatorUuid, account.organisation.uuid).subscribe(
                () => {
                    this.applicationService.applicationStore.dispatch({type: StoreActionType.USER});
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.ACCOUNT,
                        argument: orgUuid
                    });
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.PENDING_APPROVALS_COUNT,
                        argument: orgUuid
                    });
                    this.applicationService.applicationStore.dispatch({
                        type: StoreActionType.PENDING_TASKS_COUNT,
                        argument: orgUuid
                    });
                });
        });
    }

    async logout(loggedUser?: User, orgUuid?: string) {
        await this.userService.logout(loggedUser, orgUuid, this.loggedAccount);
        await this.router.navigate(['/']);
    }

    selectOrganisation() {
        window.localStorage.removeItem('spuradoLastOrgUuidUsed');
        this.router.navigate(['/' + 'index.html']);
    }
}
