import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../spurado';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    getConfiguration(orgUuid: string): Observable<Configuration> {
        return orgUuid ? this.httpClient.get<Configuration>(`${this.apiUrl}${orgUuid}/configurations`) : of(undefined);
    }

    updateConfiguration(orgUuid: string, updatedConfig: Configuration) {
        return this.httpClient.put<Configuration>(`${this.apiUrl}${orgUuid}/configurations`, updatedConfig);
    }

}
